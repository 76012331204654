import React from "react";
import styled from "styled-components";
import {theme} from "assets/themes/bbl";

interface IProps {
	width?: number;
	height?: number;
	color?: string;
}

const SVG = styled.svg``;

export const IconCheck: React.FC<IProps> = ({color, ...rest}) => (
	<SVG viewBox="0 0 15 15" fill="none" {...rest} width="15" height="15">
		<g
			fill="none"
			fillRule="evenodd"
			stroke="none"
			strokeWidth="1"
			transform="translate(-15 -23)">
			<path
				stroke={theme.results.correct}
				strokeWidth="3"
				d="M20.143 35.709a.258.258 0 01-.109-.025.23.23 0 01-.092-.08l-2.858-3.57a.39.39 0 01-.084-.25.39.39 0 01.084-.251.25.25 0 01.2-.105.25.25 0 01.202.105l2.657 3.318 7.804-9.746a.25.25 0 01.201-.105.25.25 0 01.201.105c.056.07.084.154.084.254s-.028.185-.084.255l-8.005 9.99a.23.23 0 01-.092.08.258.258 0 01-.11.025z"
			/>
		</g>
	</SVG>
);
IconCheck.defaultProps = {
	width: 14,
	height: 14,
	color: "currentColor",
};
