import React from "react";
import styled from "styled-components";

interface IProps {
	width?: number;
	height?: number;
	color?: string;
}

const SVG = styled.svg``;

export const IconFilledDown: React.FC<IProps> = ({color, ...rest}) => (
	<SVG viewBox="0 0 10 10" fill="none" {...rest} width="10" height="10">
		<circle cx="5" cy="5" r="5" fill="#4B4B4B"></circle>
		<path
			fill="#fff"
			fillRule="evenodd"
			d="M4.878 6.717L2.083 3.922l.59-.589L4.878 5.54l2.205-2.206.59.59-2.795 2.794z"
			clipRule="evenodd"
		/>
		<mask
			style={{maskType: "alpha"}}
			width="6"
			height="4"
			x="2"
			y="3"
			maskUnits="userSpaceOnUse">
			<path
				fill="#fff"
				fillRule="evenodd"
				d="M4.878 6.717L2.083 3.922l.59-.589L4.878 5.54l2.205-2.206.59.59-2.795 2.794z"
				clipRule="evenodd"
			/>
		</mask>
	</SVG>
);
IconFilledDown.defaultProps = {
	width: 10,
	height: 10,
	color: "currentColor",
};
