import * as React from "react";
import styled from "styled-components";
import {uniqueId} from "lodash";
import checkBlack from "assets/img/icons/checkBlack.svg";

interface ICheckbox extends React.AllHTMLAttributes<HTMLInputElement> {
	onChange: React.ChangeEventHandler<HTMLInputElement>;
	readonly children?: React.ReactNode;
	readonly name: string;
	readonly id?: string;
	readonly tick_position?: string;
	readonly type?: string;
	readonly is_radio?: boolean;
	readonly width?: string;
}

const StyledCheckbox = styled.div`
	overflow: hidden;
	position: relative;
	display: flex;
	align-items: flex-start;
	color: #fff;
	margin-bottom: 20px;

	&.ladder {
		margin-bottom: 0;
	}

	a {
		color: #000;
		text-decoration: underline;
	}
`;

interface ICheckboxStyled {
	readonly tick_position?: string;
}

const CheckboxStyled = styled.input`
	position: absolute;
	${({tick_position}: ICheckboxStyled) => tick_position}: 0;
	top: 0;
	z-index: -1;
	opacity: 0;
	margin: 0;
`;

const StyledTick = styled.div`
	width: 24px;
	height: 24px;
	background: rgba(0, 0, 0, 0);
	border: 1px solid #000;
	border-radius: 3px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
	flex: 0 0 auto;

	${StyledCheckbox}.ladder & {
		border-color: red;
	}

	img {
		display: none;
	}
`;

interface ILabel extends ICheckboxStyled {
	readonly is_radio?: boolean;
	readonly width?: string;
}

const Label = styled.label`
	transition: 0.4s;
	position: relative;
	text-align: left;
	max-width: ${({width}: ILabel) => width || "100%"};
	box-sizing: border-box;
	width: 100%;
	display: flex;
	align-items: center;
	cursor: pointer;
	font-size: 12px;
	line-height: 18px;
	color: #000;
	font-family: Arial, sans-serif;

	${CheckboxStyled}:disabled + & {
		cursor: default;
	}
	${CheckboxStyled}:checked + & {
		${StyledTick} {
			background: #fff;
		}
		img {
			display: block;
		}
	}

	a {
		margin-left: 4px;
	}
`;

export const Checkbox = ({
	onChange,
	children,
	tick_position = "left",
	type = "checkbox",
	id = uniqueId("checkbox"),
	width,
	value,
	checked,
	className,
	name,
}: ICheckbox) => (
	<StyledCheckbox className={className}>
		<CheckboxStyled
			onChange={onChange}
			id={id}
			type={type}
			tick_position={tick_position}
			defaultChecked={checked}
			value={value}
			name={name}
		/>
		<Label htmlFor={id} is_radio={type === "radio"} tick_position={tick_position} width={width}>
			<StyledTick>
				<img src={checkBlack} alt="Checked" />
			</StyledTick>
			{children}
		</Label>
	</StyledCheckbox>
);
