import React from "react";
import styled from "styled-components";
import bblLogo from "assets/img/BBL_logo.png";
import wbblLogo from "assets/img/WBBL_Logo.png";

const LogoWrapper = styled.div`
	position: absolute;
	top: -60px;
	left: calc(50% - 172px);
	display: flex;
	width: 344px;
	justify-content: space-between;
	img {
		width: 180px;
		height: 180px;
		position: unset !important;
		left: unset !important;
		margin-bottom: 0 !important;
		@media (max-width: 640px) {
			width: 90px;
			height: 90px;
		}
	}
	.bbl-logo {
		width: 155px;
		height: 155px;
		margin-top: 15px;
		@media (max-width: 640px) {
			width: 75px;
			height: 75px;
			margin-top: 9px;
		}
	}
	@media (max-width: 640px) {
		width: 144px;
		left: calc(50% - 72px);
	}
`;

export const TopIcons: React.FC = () => {
	return (
		<LogoWrapper>
			<img src={wbblLogo} alt="WBBL Logo" />
			<img src={bblLogo} alt="BBL Logo" className="bbl-logo" />
		</LogoWrapper>
	);
};
