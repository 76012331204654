import * as React from "react";
import {IIconProps} from "modules/types";

export const IconExternalLink: React.FC<IIconProps> = ({title, width, height, color}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" fill="none" viewBox="0 0 15 16">
		<path
			fill="#fff"
			fillRule="evenodd"
			d="M7.5 2.5V.667h7.333V8H13V3.796l-6.685 6.685-1.297-1.296L11.704 2.5H7.5zm1.833 11v-2.75h1.833v4.583H.167v-11h5.5v1.834H2V13.5h7.333z"
			clipRule="evenodd"
		/>
	</svg>
);

IconExternalLink.defaultProps = {
	title: "External Link",
	width: 15,
	height: 16,
	color: "#797979",
};
