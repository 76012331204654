import React from "react";
import styled from "styled-components";
import {useSelector, useDispatch} from "react-redux";
import {HOCModal, Exist} from "components";
import {hideBonusPointModal} from "modules/actions";
import {isBonusPointModalVisible} from "modules/selectors";
import {IconBlackInfo} from "components/Icons/IconBlackInfo";
import {theme} from "assets/themes/bbl";

const IconWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	svg {
		height: 40px;
		width: 40px;
	}
`;

const ModalContentWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	height: fit-content;
	justify-content: space-evenly;
	padding: 45px 80px;
`;

const CenterCol = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 20px;
`;

const Heading = styled.h1`
	font-size: 20px;
	font-weight: 700;
	font-family: ${theme.font.montserrat};
	color: ${theme.colors.primary};
	letter-spacing: -0.1px;
	margin-bottom: 10px;
`;

const SubHeading = styled.p`
	font-size: 14px;
	font-weight: 400;
	font-family: ${theme.font.montserrat};
	color: ${theme.colors.darkGrey};
	text-align: center;
	margin-bottom: 10px;
`;

const MakePickButton = styled.button`
	outline: none;
	border: none;
	color: #000000;
	font-size: 14px;
	font-family: ${theme.font.montserrat};
	font-weight: 600;
	background-color: ${theme.colors.tertiary};
	border-radius: 2px;
	width: 200px;
	height: 40px;
	margin-top: 20px;
	@media screen and (max-width: 639px) {
		margin-top: 30px;
	}
`;

export const BonusPointModal: React.FC = () => {
	const dispatch = useDispatch();
	const isVisible = useSelector(isBonusPointModalVisible);

	const onClose = () => {
		dispatch(hideBonusPointModal());
	};
	return (
		<Exist when={isVisible}>
			<HOCModal onClose={onClose} isVisible={isVisible}>
				<ModalContentWrapper>
					<IconWrapper>
						<IconBlackInfo />
					</IconWrapper>
					<CenterCol>
						<Heading>Clean Sweep Bonus</Heading>
						<SubHeading>
							If you select every tip correctly for the week you will receive 2 bonus
							points (Clean sweep Bonus).
						</SubHeading>
						<SubHeading>
							Note: You will not receive the bonus points if you didn’t submit one or
							more tips and were awarded the away team autotip for any matches in the
							week.
						</SubHeading>
					</CenterCol>
					<MakePickButton onClick={onClose}>Got it!</MakePickButton>
				</ModalContentWrapper>
			</HOCModal>
		</Exist>
	);
};
