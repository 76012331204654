import styled from "styled-components";

export const Sidebar = styled.aside`
	width: 300px;
	min-width: 300px;
	margin-left: 30px;

	@media (max-width: 1000px) {
		width: 100%;
		max-width: 100%;
		margin: 0;
	}

	@media print {
		display: none;
	}
`;
