import React, {Fragment} from "react";
import styled from "styled-components";
import {Header, AdvertRectangle, Exist} from "components";
import {useMediaQuery} from "modules/utils";
import Landing_bg from "assets/img/Landing_bg2024black.jpg";

/**
 * need to check correct bgColor
 * f1f1f1 or fff
 * or both on different screens?
 */

const BackgroundWrapper = styled.main`
	position: relative;
	width: 100%;
	min-height: 100vh;
	background-image: url(${Landing_bg});
	background-size: cover;
	background-attachment: fixed;
	background-position: center;
`;

const Main = styled.div`
	width: 100%;
	// height: 200vh;
	min-height: 100vh;
	overflow-y: auto;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	padding-bottom: 55px;
	&::-webkit-scrollbar {
		display: none;
	}
	@media (min-width: 640px) {
		padding-bottom: 0;
	}
`;

const InnerMain = styled.div`
	flex-grow: 1;
	line-height: 150%;

	@media screen and (max-width: 1220px) {
		line-height: 180%;
		padding: 0 20px;
	}

	@media (max-width: 639px) {
		position: relative;
		padding: 0 0px;
	}
`;

// const WhiteBackgroundWrapper = styled.div`
// 	max-width: 75%;
// 	background-color: #fff;
// 	margin: 0 auto;
// 	border-radius: 5px;
// 	padding-top: 15px;

// 	@media (max-width: 639px) {
// 		max-width: 100%;
// 		border-radius: 0px;
// 	}
// `;

export const HOCMain: React.FC = ({children}) => {
	const isMobile = useMediaQuery("(max-width: 639px)");
	return (
		<Fragment>
			<BackgroundWrapper>
				<Main>
					<Header />
					<AdvertRectangle id={"top"} />
					<InnerMain>
						{/* <WhiteBackgroundWrapper>{children}</WhiteBackgroundWrapper> */}
						{children}
					</InnerMain>
					<Exist when={isMobile}>
						<AdvertRectangle id={"top"} />
					</Exist>
				</Main>
			</BackgroundWrapper>
		</Fragment>
	);
};
