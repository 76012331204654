import {PrimaryButton} from "./PrimaryButton";
import styled from "styled-components";

export const PrimaryButtonWithArrow = styled(PrimaryButton)`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	> svg {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 15px;
	}
`;
