import React from "react";
import styled from "styled-components";

interface IProps {
	width?: number;
	height?: number;
	color?: string;
}

const SVG = styled.svg``;

export const IconGoldWeekInfo: React.FC<IProps> = ({color, ...rest}) => (
	<SVG viewBox="0 0 26 24" fill="none" {...rest} width="26" height="24">
		<path
			fill="#D4AF37"
			fillRule="evenodd"
			d="M25.5 8.25v4.062a7.5 7.5 0 01-7.5 7.5H3.813L.5 23.25v-15A7.5 7.5 0 018 .75h10a7.5 7.5 0 017.5 7.5zM14.25 9.5h-2.5v6.25h2.5V9.5zm0-5h-2.5V7h2.5V4.5z"
			clipRule="evenodd"
		/>
	</SVG>
);
IconGoldWeekInfo.defaultProps = {
	width: 26,
	height: 24,
	color: "currentColor",
};
