import {createAction} from "redux-act";
import {IActionGetRankings, IActionSuccessRankings} from "modules/reducers/rankings";

export const getRankingsData = createAction<IActionGetRankings>();
export const getRankingsSuccess = createAction<IActionSuccessRankings>();
export const getRankingsFailed = createAction();
export const getRankingsMore = createAction();
export const clearRankings = createAction();
export const setRankingsQuery = createAction<string>();
export const setShowByOverall = createAction();
export const clearShowByOverall = createAction();
