import React, {useCallback} from "react";
import styled from "styled-components";
import {ReactComponent as Cross} from "assets/img/icons/cross.svg";
import {useDispatch, useSelector} from "react-redux";
import {getUserAlert} from "modules/selectors";
import {hideUserAlert} from "modules/actions";

const Wrapper = styled.div`
	background: #00a826;
	position: absolute;
	top: 60px;
	left: 0;
	z-index: 50;
	font-size: 14px;
	line-height: 22px;
	font-family: Arial, sans-serif;
	color: #fff;
	width: 100%;
	padding: 19px 20px;
	transform: translateY(-120px);
	transition: transform 0.2s ease-in-out;

	@media (min-width: 640px) {
		top: 0;
		transform: translateY(-60px);
	}

	&.active {
		transform: translateY(0);
	}
`;

const CloseButton = styled.button`
	position: absolute;
	width: 60px;
	height: 60px;
	right: 0;
	top: 50%;
	border: 0;
	padding: 0;
	margin: 0;
	background: none;
	color: #fff;
	transform: translateY(-50%);

	svg {
		width: 35px;
		height: 35px;
	}
`;

export const Alert: React.FC = () => {
	const dispatch = useDispatch();
	const userAlert = useSelector(getUserAlert);

	const onClose = useCallback(() => {
		dispatch(hideUserAlert());
	}, [dispatch]);

	if (!userAlert.isShow) {
		return null;
	}

	return (
		<Wrapper className={"active"}>
			{userAlert.text}
			<CloseButton onClick={onClose}>
				<Cross />
			</CloseButton>
		</Wrapper>
	);
};
