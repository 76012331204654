import {SagaIterator} from "redux-saga";
import {call, put, select, take} from "typed-redux-saga/macro";
import * as actions from "modules/actions";
import {IRankingsItem, ILeagueActionGetLadder} from "modules/reducers";
import {getLeague, getLeaguesLadderStore, getIsShowByOverall} from "modules/selectors";
import {ILadderRequest, ISagaAction} from "modules/types";
import {Api, ApiError} from "modules/utils";
import {get, eq, concat} from "lodash";
import {fetchLeagueSaga} from "modules/sagas";

export const apiGetLadder = function* (options: ILadderRequest): SagaIterator {
	const response = yield* call(Api.tipping_league.ladder, options);

	ApiError.CHECK(response);

	return response.success;
};

export const getLadderDataSaga = function* (
	action: ISagaAction<ILeagueActionGetLadder>
): SagaIterator {
	const league = yield* select(getLeague);
	const league_id = league.id;
	const action_league_id = +get(action, "payload.id");

	if (!eq(league_id, action_league_id)) {
		yield* call(fetchLeagueSaga, {
			...action,
			payload: {id: action_league_id},
		});
	}

	yield* call(getLeagueLadderDataSaga, action);
};

export const getLeagueLadderDataSaga = function* (
	action: ISagaAction<ILeagueActionGetLadder>,
	offset: number = 0
): SagaIterator {
	try {
		const LIMIT = 20;

		const {id, q, round, offset} = action.payload;

		const options: ILadderRequest = {
			id,
			page: offset,
			limit: LIMIT,
		};

		const isShowOvr = yield* select(getIsShowByOverall);

		if (!isShowOvr) {
			options.round = round;
		}

		const result = yield* call(apiGetLadder, options);

		const preLadder = yield* select(getLeaguesLadderStore);

		const isNewRankings = offset === 1;

		const newItems: IRankingsItem[] = isNewRankings
			? result.rankings
			: concat(preLadder.items, result.rankings);

		const next = result.next;

		yield* put(
			actions.getLeagueLadderDataSuccess({
				items: newItems,
				nextPage: result.nextPage,
				q,
			})
		);

		if (next) {
			yield* take(actions.getLeagueLadderDataMore);
			yield* call(getLeagueLadderDataSaga, action, offset + LIMIT);
		}
	} catch (err) {
		yield* put(actions.getLeagueLadderDataFailed());
		yield* put(actions.globalError(err));
	}
};
