import React, {Fragment} from "react";
import styled from "styled-components";
import {theme} from "assets/themes/bbl";

interface IProps {
	size: number;
	progress: number;
	strokeWidth: number;
	strokeColorAway: string;
	strokeColorHome: string;
}

const SVGStyled = styled.svg`
	display: block;
	margin: 20px auto;
	max-width: 100%;
	transform: rotate(270deg);
`;

const BackgroundCircle = styled.circle`
	fill: none;
`;

const Circle = styled.circle`
	fill: none;
`;

const StyledText = styled.text`
	font-family: ${theme.font.montserrat};
	font-size: 8px;
	color: ${theme.colors.primary};
	font-weight: 600;
	text-anchor: middle;
	color: ${theme.colors.primary};
	transform: rotate(90deg);
	@media (max-width: 640px) {
		display: none;
	}
`;

export const ProgressCircle: React.FC<IProps> = ({
	size,
	progress,
	strokeWidth,
	strokeColorAway,
	strokeColorHome,
}) => {
	const center = size / 2;
	const radius = size / 2 - strokeWidth / 2;
	const circumference = 2 * Math.PI * radius;
	const offset = ((100 - progress) / 100) * circumference;

	return (
		<Fragment>
			<SVGStyled width={size} height={size}>
				<BackgroundCircle
					stroke={strokeColorHome}
					cx={center}
					cy={center}
					r={radius}
					strokeWidth={strokeWidth}
				/>
				<Circle
					stroke={strokeColorAway}
					cx={center}
					cy={center}
					r={radius}
					strokeDasharray={circumference}
					strokeDashoffset={offset}
					strokeWidth={strokeWidth}
				/>
				<StyledText x={30} y={-32}>
					FAN'S
				</StyledText>
				<StyledText x={30} y={-22}>
					CHOICE
				</StyledText>
			</SVGStyled>
		</Fragment>
	);
};
