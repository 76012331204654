import styled from "styled-components";

export const FullWidth = styled.div`
	padding: 0;
	box-sizing: border-box;
	background: #fff;

	@media (min-width: 640px) {
		margin: 40px auto 40px;
		border-radius: 4px;
		overflow: hidden;
		max-width: 1200px;
	}
`;
