import {createAction} from "redux-act";
import {IDictionary, IPicksPayload} from "modules/types";
import {MatchStatus, RoundStatus} from "modules/utils";

export type TState = string;

export const fetchStates = createAction();
export const fetchStatesSuccess = createAction<IDictionary<TState>>();
export const fetchStatesFailed = createAction<Error | unknown>();

export interface ISquad {
	id: number;
	shortName: string;
	name: string;
	first_name: string;
	custom_logo: string;
	colour: string;
	nickname: string;
}

export const fetchSquads = createAction();
export const fetchSquadsSuccess = createAction<ISquad[]>();
export const fetchSquadsFailed = createAction<Error | unknown>();

export interface IMatch {
	id: number;
	homeSquadId: number;
	awaySquadId: number;
	status: MatchStatus;
	date: string;
	venueId: number;
	homeRuns: null | number;
	homeWicketsFallen: number | null;
	awayRuns: null | number;
	awayWicketsFallen: number | null;
	isMarginGame: boolean;
}

export interface IRound {
	id: number;
	status: RoundStatus;
	endDate: Date;
	matches: IMatch[];
	number: number;
	startDate: Date;
}

export const fetchRounds = createAction();
export const fetchRoundsSuccess = createAction<IRound[]>();
export const fetchRoundsFailed = createAction<Error | unknown>();

export interface ILadder {
	played: number;
	points: number;
	position: number;
	squadId: number;
	won: number;
}

export const fetchLadder = createAction();
export const fetchLadderSuccess = createAction<ILadder[]>();
export const fetchLadderFailed = createAction<Error | unknown>();

export const clearLadder = createAction();

export interface IRoundStats {
	match_id: number;
	draw_percent: number;
	count_draw: number;
	home_squad_id: number;
	home_squad_name: string;
	home_percent: number;
	count_home: number;
	away_squad_id: number;
	away_squad_name: string;
	away_percent: number;
	count_away: number;
}

export const fetchRoundStats = createAction<IPicksPayload>();
export const fetchRoundStatsSuccess = createAction<IRoundStats[]>();
export const fetchRoundStatsFailed = createAction<Error | unknown>();

export interface IMRECSuccess {
	sport: string;
	type: string;
	desktopImage: string;
	mobileImage: string;
	url: string;
}

export const fetchMREC = createAction();
export const fetchMRECSuccess = createAction<IMRECSuccess[]>();
export const fetchMRECFailed = createAction<Error | unknown>();

export interface INotificationBar {
	sport: string;
	isEnabled: boolean;
	message: string;
}

export const fetchNotificationBar = createAction();
export const fetchNotificationBarSuccess = createAction<INotificationBar[]>();
export const fetchNotificationBarFailed = createAction<Error | unknown>();

export interface IVenueElement {
	id: number;
	sport: string;
	name: string;
}

export const fetchVenues = createAction();
export const fetchVenuesSuccess = createAction<IVenueElement[]>();
export const fetchVenuesFailed = createAction<Error | unknown>();

export interface IFormGuide {
	[teamId: number]: {
		[matchId: string]: {
			result: string;
			opponent: number;
			round: number;
		};
	};
}

export const fetchFormGuide = createAction();
export const fetchFormGuideSuccess = createAction<IDictionary<IFormGuide>>();
export const fetchFormGuideFailed = createAction<Error | unknown>();
