import {createReducer} from "redux-act";
import {without, isEmpty} from "lodash";
import {
	fetchTips,
	fetchTipsSuccess,
	postAutoPick,
	postClearPicks,
	tipsSaved,
	showPicksHelp,
	hidePicksHelp,
	updateMargin,
	saveTip,
	// saveTipSuccess,
	updatePick,
	viewUserPicks,
	removeViewUserPicks,
	clearViewUserPicks,
} from "modules/actions";

export interface IPick {
	match: number;
	squad: number;
	readonly margin: number;
	isCorrect?: boolean;
	isAutoTip?: boolean;
}

export interface IPicksReducer {
	tips: IPick[];
	is_loading: boolean;
	can_save: boolean;
	saved: boolean;
	saving: boolean;
	showErrorForEmpty: boolean;
	viewUserPicks: number[];
	help: {
		show: boolean;
		pickId: number;
	};
}
const initialState: IPicksReducer = {
	tips: [],
	is_loading: false,
	can_save: true,
	saved: false,
	saving: false,
	showErrorForEmpty: false,
	viewUserPicks: [],
	help: {
		show: false,
		pickId: 0,
	},
};

const setSaving = (state: IPicksReducer) => ({
	...state,
	saving: true,
	can_save: false,
});

export const picks = createReducer<IPicksReducer>({}, initialState)
	.on(fetchTips, (state) => ({
		...state,
		is_loading: true,
		saving: false,
		saved: false,
		can_save: true,
		showErrorForEmpty: false,
	}))
	.on(fetchTipsSuccess, (state, payload: IPick[]) => ({
		...state,
		tips: payload,
		is_loading: false,
	}))
	.on(postAutoPick, setSaving)
	.on(postClearPicks, setSaving)
	.on(saveTip, (state) => ({
		...state,
		saving: true,
		saved: false,
		can_save: false,
	}))
	// .on(saveTipSuccess, (state) => ({
	// 	...state,
	// 	saving: false,
	// 	saved: false,
	// 	can_save: true,
	// }))
	.on(tipsSaved, (state, payload: boolean) => ({
		...state,
		saving: !payload,
		saved: payload,
		can_save: !payload,
		showErrorForEmpty: true,
	}))
	.on(showPicksHelp, (state, payload) => ({
		...state,
		help: {
			...state.help,
			show: true,
			pickId: payload,
		},
	}))
	.on(hidePicksHelp, (state) => ({
		...state,
		help: {
			...state.help,
			show: false,
			pickId: 0,
		},
	}))
	.on(updateMargin, (state, {margin, match}) => {
		const isMatchTipped = !isEmpty(state.tips.find((tip) => tip.match === match));
		const updatedTipState = state.tips.map((tip) => {
			if (tip.match === match) {
				return {
					...tip,
					margin: margin,
				};
			}
			return tip;
		});
		if (state.tips.length === 0 || !isMatchTipped) {
			updatedTipState.push({
				match,
				squad: 0,
				margin: margin,
				isCorrect: false,
			});
		}
		return {
			...state,
			tips: updatedTipState,
		};
	})
	.on(updatePick, (state, {match, squad, margin, isCorrect}) => {
		return {
			...state,
			tips: [...state.tips, {match, squad, margin, isCorrect}],
		};
	})
	.on(viewUserPicks, (state, payload) => {
		return {
			...state,
			viewUserPicks: [...state.viewUserPicks, payload.team_id],
		};
	})
	.on(removeViewUserPicks, (state, payload) => {
		const currentViewing = state.viewUserPicks;
		const removedViewing = without(currentViewing, payload.team_id);
		return {
			...state,
			viewUserPicks: removedViewing,
		};
	})
	.on(clearViewUserPicks, (state) => ({
		...state,
		viewUserPicks: [],
	}));
