import styled from "styled-components";

export const TwoColumnContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 20px;
	box-sizing: border-box;
	width: 100%;
	max-width: 1140px;
	margin: 0 auto;

	> div {
		border-radius: 4px;
		background: #fff;
		flex: 1;
		padding: 20px 15px;
		margin: 10px;
		box-sizing: border-box;

		@media (min-width: 640px) {
			margin: 20px;
			padding: 20px;
			background-color: #fff;
			border: 1px solid #eaeaea;
		}
	}

	@media (max-width: 640px) {
		padding: 0;
	}
`;
