import {createAction} from "redux-act";

interface ISelectedRoundSuccess {
	round: number;
}

interface IChangeSelectedRound {
	round: number;
}

export const fetchSelectedRound = createAction();
export const fetchSelectedRoundSuccess = createAction<ISelectedRoundSuccess>();
export const fetchSelectedRoundFail = createAction();

export const changeSelectedRound = createAction<IChangeSelectedRound>();
