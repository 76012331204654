import React, {Fragment, useEffect} from "react";
import {setSportURL, SportType} from "modules/utils";

interface IProps {
	sport?: SportType;
}

export const HOCSportSelection: React.FC<IProps> = ({children, sport}) => {
	useEffect(() => {
		setSportURL();
	}, []);

	return <Fragment>{children}</Fragment>;
};
