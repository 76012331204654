import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchSquads, fetchTips, fetchRoundStats} from "modules/actions";
import {
	getMergedTipsWithMatchesSelector,
	getSelectedRoundID,
	getRoundByIdSelector,
} from "modules/selectors";
import {Pick, PrimaryButton} from "components";
import styled from "styled-components";

const PicksContainer = styled.div`
	width: 100%;
	${PrimaryButton} {
		margin: 10px auto;
		display: block;
		width: 320px;
		@media (max-width: 640px) {
			margin: 30px auto;
		}
	}
`;

export const Picks: React.FC = () => {
	const dispatch = useDispatch();
	const selectedRoundID = useSelector(getSelectedRoundID);
	const selectedRound = useSelector(getRoundByIdSelector)(selectedRoundID);
	const picks = useSelector(getMergedTipsWithMatchesSelector)(selectedRound);

	useEffect(() => {
		dispatch(fetchSquads());
		// dispatch(fetchSnapshot());
	}, [dispatch]);

	useEffect(() => {
		if (selectedRound) {
			dispatch(fetchTips({round: selectedRound.id}));
			dispatch(fetchRoundStats({round: selectedRound.id}));
		}
	}, [dispatch, selectedRound]);

	return (
		<PicksContainer>
			{picks.map((pick) => (
				<Pick key={pick.id} pick={pick} />
			))}
		</PicksContainer>
	);
};

export default Picks;
