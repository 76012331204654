import {createReducer} from "redux-act";
import * as actions from "modules/actions";
import {ILeague, INextAction, IRequestData} from "modules/types";

export type TLeagueCode = string;

export interface IJoinState extends IRequestData {
	readonly is_joined: boolean;
}

export interface IJoinToLeagueSuccess {
	readonly code: string;
	readonly id: number;
}

export interface IJoinLeagueState extends IRequestData {
	readonly joined_codes: {
		readonly [code: string]: IJoinState | undefined;
	};
	readonly id_joined_league: number;
	items: ILeague[];
}

export type TJoinLeagues = INextAction<ILeague> & IJoinLeagueState;

const defaultState: TJoinLeagues = {
	items: [],
	nextPage: false,
	joined_codes: {},
	id_joined_league: 0,
};

export const leagues_for_join = createReducer<TJoinLeagues>({}, defaultState)
	.on(actions.clearLeaguesForJoin, (state) => ({
		...defaultState,
		joined_codes: state.joined_codes,
		id_joined_league: 0,
	}))
	.on(actions.fetchLeaguesForJoin, (state) => ({
		...state,
		is_data_requested: true,
		id_joined_league: 0,
	}))
	.on(actions.fetchLeaguesForJoinSuccess, (state, payload) => ({
		...state,
		items: payload.items,
		nextPage: payload.nextPage,
		is_data_requested: false,
		id_joined_league: 0,
	}))
	.on(actions.fetchLeaguesForJoinFailed, (state) => ({
		...state,
		is_data_requested: false,
		id_joined_league: 0,
	}))
	.on(actions.joinToLeague, (state, payload: {code: TLeagueCode}) => ({
		...state,
		joined_codes: {
			...state.joined_codes,
			[payload.code]: {
				is_data_requested: true,
				is_joined: false,
			},
		},
		id_joined_league: 0,
		is_data_requested: !payload.code,
	}))

	.on(actions.joinToLeagueSuccess, (state, payload) => ({
		...state,
		joined_codes: {
			...state.joined_codes,
			[payload.code]: {
				is_data_requested: false,
				is_joined: true,
			},
		},
		id_joined_league: payload.id,
		is_data_requested: false,
	}))
	.on(actions.joinToLeagueFailed, (state, payload) => ({
		...state,
		joined_codes: {
			...state.joined_codes,
			[payload]: {
				is_data_requested: false,
				is_joined: false,
			},
		},
		id_joined_league: 0,
		is_data_requested: false,
	}))
	.on(actions.clearLeaguesForJoin, () => ({...defaultState}));
