import {createReducer} from "redux-act";
import * as actions from "modules/actions";
import {IUserPrediction} from "modules/types";

export interface ISnapshot {
	round_points: number;
	overall_points: number;
	overall_rank: number;
	round: number;
	max_round_points: number;
	max_total_points: number;
	total_users: number;
}

export interface IUserTips {
	[teamID: number]: {
		[round: number]: IUserPrediction[];
	};
}

export interface ITippingStats {
	snapshot: ISnapshot;
	is_loaded: boolean;
	userTipsByID: IUserTips;
}

const initialState: ITippingStats = {
	snapshot: {
		round_points: 0,
		overall_points: 0,
		overall_rank: 0,
		round: 0,
		max_round_points: 0,
		max_total_points: 0,
		total_users: 0,
	},
	is_loaded: false,
	userTipsByID: {},
};

export const tipping_stats = createReducer<ITippingStats>({}, initialState)
	.on(actions.fetchSnapshotSuccess, (state, payload: ISnapshot) => ({
		...state,
		snapshot: payload,
		is_loaded: true,
	}))
	.on(actions.fetchOtherUserTips, (state) => ({
		...state,
		is_loaded: false,
	}))
	.on(actions.fetchOtherUserTipsSuccess, (state, payload) => {
		return {
			...state,
			is_loaded: true,
			userTipsByID: {
				...state.userTipsByID,
				[payload.user]: {
					...state.userTipsByID[payload.user],
					[payload.round]: payload.predictions,
				},
			},
		};
	});
