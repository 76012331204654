import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {theme} from "assets/themes/bbl";
import {userPreregister} from "modules/actions";
import {getPreregisterMessage} from "modules/selectors";
import {TopIcons} from "./TopIcons";
import ios from "assets/img/ios.png";
import android from "assets/img/android.png";

const ComponentWrapper = styled.div`
	position: relative;
	left: 133px;
	top: 50px;
	width: 600px;
	height: 535px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 18px 27px;
	background: white;
	border-radius: 4px;
	padding-top: 131px;
	padding-bottom: 20px;
	@media (max-width: 640px) {
		width: 100%;
		height: 525px;
		left: 0;
		padding: 25px;
	}
`;

const SectionHeading = styled.h1`
	font-family: "Halisa", sans-serif;
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
	text-align: center;
	color: #000;
	margin-bottom: 10px;
	@media (max-width: 640px) {
		font-size: 16px;
		margin-top: 10px;
	}
`;

const SectionDescription = styled.p`
	font-family: ${theme.font.montserrat};
	font-size: 16px;
	font-weight: 400;
	color: #000;
	line-height: 19px;
	text-align: center;
	@media (max-width: 640px) {
		font-size: 14px;
	}
`;

const BackNextSeasonText = styled(SectionDescription)`
	font-size: 14px;
	margin-top: 20px;
	font-weight: 700;
	@media (max-width: 640px) {
		margin-top: 10px;
	}
`;

const StyledInput = styled.input<{
	noMarginTop?: boolean;
}>`
	width: 300px;
	height: 45px;
	margin: 10px 0;
	border: 1px solid #b8b8b8;
	border-radius: 2px;
	font-family: ${theme.font.montserrat};
	font-size: 14px;
	color: #000;
	padding: 10px;
	${({noMarginTop}) =>
		noMarginTop &&
		`
        margin-top: 0px;
    `}
`;

const ControlButton = styled.button`
	width: 300px;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: ${theme.font.montserrat};
	font-size: 14px;
	line-height: 16px;
	font-weight: 600;
	border: 1px solid ${theme.colors.primary};
	outline: none;
	border-radius: 2px;
	background: ${theme.colors.tertiary};
	color: #000;
	margin-left: 0;
	margin-bottom: 10px;
	&:disabled {
		background-color: #f1f1f1;
		cursor: not-allowed;
		border: none;
	}
`;

const PreregisterLink = styled.a`
	font-weight: 700;
	text-decoration: underline;
`;

const BText = styled.span`
	font-weight: 600;
`;

const BottomIcons = styled.div`
	width: 100%;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 40px;

	img {
		height: 35px;
		width: 110px;
		left: 0 !important;
		margin: 20px 0 0 !important;
	}
`;

const validateEmail = (email: string) => {
	return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
};

export const Preregister: React.FC = () => {
	const [email, setEmail] = useState("");
	const [name, setName] = useState("");
	const dispatch = useDispatch();

	const successMessage = useSelector(getPreregisterMessage);

	const handleSubmit = () => {
		dispatch(userPreregister({email, name}));
	};

	const isButtonDisabled = name.length < 2 || !validateEmail(email) || successMessage !== "";

	const buttonText = successMessage !== "" ? successMessage : " Register your interest";

	return (
		<ComponentWrapper>
			<TopIcons />
			<SectionHeading>PRE-REGISTER FOR THE 2024-25 SEASON NOW!</SectionHeading>
			<StyledInput
				type="input"
				placeholder="Enter your name"
				value={name}
				onChange={(e) => {
					setName(e.target.value);
				}}
			/>
			<StyledInput
				type="input"
				placeholder="Enter your email address"
				noMarginTop={true}
				value={email}
				onChange={(e) => {
					setEmail(e.target.value);
				}}
			/>
			<ControlButton onClick={() => handleSubmit()} disabled={isButtonDisabled}>
				{buttonText}
			</ControlButton>
			<SectionDescription>
				Check out the{" "}
				<PreregisterLink
					href="https://www.cricket.com.au/big-bash"
					target="_blank"
					rel="noreferrer">
					WBBL10 & BBL14 fixtures
				</PreregisterLink>{" "}
				and start planning for a chance to win some awesome prizes.
				<br />A big congratulations to all our winners from last season, especially Shayne
				Drummond (WBBL) and Cooper Mason (BBL) who each took home the top prize of{" "}
				<BText>$10,000</BText>!
			</SectionDescription>
			<BackNextSeasonText>
				NEW! Play via the official Big Bash App this season, and get closer to your
				favourite teams and players.
			</BackNextSeasonText>
			<BottomIcons>
				<a href="https://apps.apple.com/au/app/big-bash/id6467102836">
					<img src={ios} alt="IOS" />
				</a>

				<a href="https://play.google.com/store/apps/details?id=com.cricketaustralia.cabigbash">
					<img src={android} alt="ANDROID" />
				</a>
			</BottomIcons>
		</ComponentWrapper>
	);
};
