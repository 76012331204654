import React from "react";
import {useSelector} from "react-redux";
import {SportType} from "modules/utils";
import {getSquadsById, getFormGuideFromID, getSquadsKeyedByID, getRounds} from "modules/selectors";
import {getTeamLogoUrl} from "modules/utils/constants";
import {get} from "lodash";
import styled from "styled-components";
import {theme} from "assets/themes/bbl";
import {IFormGuide} from "modules/types";

const StatHomeAwayWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 10px;
	> div {
		flex: 1;
		text-align: center;
		padding: 12px 0;
		margin: 0 20px;
		color: #333333;
		font-size: 16px;
	}
	@media (max-width: 640px) {
		> div {
			margin: 0 8px;
		}
	}
`;

const FormWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const FormResultWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 40px;
	margin-right: 5px;
	@media (max-width: 640px) {
		width: 30px;
		margin-right: 2px;
	}
`;

const FormResultIcon = styled.div<{
	teamColor: string;
	isWin: boolean;
}>`
	width: 40px;
	height: 40px;
	border-radius: 4px;
	border: 2px solid;
	border-color: ${({teamColor, isWin}) => (isWin ? teamColor : theme.colors.darkGrey)};
	padding: 2px;
	color: ${({isWin}) => (isWin ? "#ffffff" : "#4b4b4b")};
	font-family: "Montserrat";
	font-size: 14px;
	font-weight: 400;
	display: flex;
	justify-content: center;
	align-items: center;
	img {
		width: 100%;
		height: 100%;
	}
	@media (max-width: 640px) {
		width: 30px;
		height: 30px;
	}
`;

const FormResultText = styled.p<{
	teamColor: string;
	isWin: boolean;
}>`
	font-family: ${theme.font.montserrat};
	font-size: 14px;
	font-weight: 600;
	color: ${({isWin, teamColor}) => (isWin ? teamColor : "#4b4b4b")};
	text-align: center;
	margin-top: 10px;
	@media (max-width: 640px) {
		font-size: 10px;
	}
`;

// const FormRoundText = styled.p`
// 	font-family: ${theme.font.montserrat};
// 	font-weight: 400;
// 	color: ${theme.colors.darkGrey};
// 	font-size: 9px;
// 	text-align: center;
// 	margin-top: 4px;
// `;

interface IRecentForm {
	form: {opponent: number; result: string; round: number; matchId: number}[];
	squadID: number;
}

const RecentFormRow: React.FC<IRecentForm> = ({form, squadID}) => {
	const squad = useSelector(getSquadsById)(squadID);
	const squadsByID = useSelector(getSquadsKeyedByID);
	const squadColor = get(squad, "colour") || "green";
	const roundsArr = useSelector(getRounds);
	return (
		<FormWrapper>
			{form.map((obj, index) => {
				const matchId = obj.matchId;
				const roundId = obj.round;
				const selectedRound = roundsArr.find((round) => round.id === roundId);
				const selectedMatch = selectedRound?.matches.find((match) => match.id === matchId);
				const isWin = obj.result === "win";
				const isDraw = obj.result === "draw";
				let resultText = isWin ? "Win" : isDraw ? "Draw" : "Loss";
				if (selectedMatch?.awayRuns === 0 || selectedMatch?.homeRuns === 0) {
					resultText = "Draw";
				}
				const opponentShortName = get(squadsByID[obj.opponent], "shortName", "");
				const opponentLogo = getTeamLogoUrl(opponentShortName);
				return (
					<FormResultWrapper key={index}>
						<FormResultIcon teamColor={squadColor} isWin={isWin}>
							<img src={opponentLogo} alt="Opponent Logo" />
						</FormResultIcon>
						<FormResultText teamColor={squadColor} isWin={isWin}>
							{resultText}
						</FormResultText>
						{/* <FormRoundText>W{obj.round}</FormRoundText> */}
					</FormResultWrapper>
				);
			})}
		</FormWrapper>
	);
};

interface IForm {
	opponent: number;
	result: string;
	round: number;
	matchId: number;
}

const formDataHandled = (form: IFormGuide): IForm[] => {
	return Object.keys(form).map((match: string) => {
		const data = get(form, `${match}`);
		const matchId = Number(match.split("_")[1]);
		return {
			opponent: get(data, "opponent") as number,
			result: get(data, "result") as string,
			round: get(data, "round") as number,
			matchId: matchId,
		};
	});
};

interface IProps {
	sportName: SportType;
	homeSquadId: number;
	awaySquadId: number;
}

export const FormGuide: React.FC<IProps> = ({sportName, homeSquadId, awaySquadId}) => {
	const homeTeamData = useSelector(getFormGuideFromID)(homeSquadId);
	const awayTeamData = useSelector(getFormGuideFromID)(awaySquadId);
	const homeTeamForm = formDataHandled(homeTeamData);
	const awayTeamForm = formDataHandled(awayTeamData);
	if (!homeTeamData || !awayTeamData) {
		return null;
	}

	return (
		<StatHomeAwayWrapper>
			<RecentFormRow form={homeTeamForm} squadID={homeSquadId} />
			<RecentFormRow form={awayTeamForm} squadID={awaySquadId} />
		</StatHomeAwayWrapper>
	);
};
