import styled from "styled-components";

export const FormSubTitle = styled.h4`
	color: #000000;
	font-family: Arial;
	font-size: 14px;
	letter-spacing: -0.1px;
	line-height: 22px;
	font-weight: normal;

	strong {
		font-weight: bold;
	}

	@media (max-width: 640px) {
		font-size: 12px;
	}
`;
