import {createReducer} from "redux-act";
import * as actions from "modules/actions";
import {INextAction, IRequestData} from "modules/types";

export interface IActionGetRankings {
	round?: number;
	q?: string;
	order?: string;
	offset: number;
}

export interface IActionSuccessRankings extends INextAction<IRankingsItem> {
	q?: string;
}

export interface IRankingsItem {
	userId: number;
	username: string;
	totalPoints: number;
	rank: number;
	points: number;
}

interface IRankNum {
	readonly rank: number;
}

interface IFilterRankings {
	readonly q: string;
	order?: string;
}

interface IShowOverall {
	showByOverall: boolean;
}

export type Rankings = INextAction<IRankingsItem> &
	IRequestData &
	IRankNum &
	IFilterRankings &
	IShowOverall;

const defaultState: Rankings = {
	items: [],
	nextPage: false,
	rank: 0,
	is_data_requested: false,
	q: "",
	order: "overall_rank",
	showByOverall: false,
};

export const rankings = createReducer<Rankings>({}, defaultState)
	.on(actions.getRankingsData, (state) => ({
		...state,
		is_data_requested: true,
	}))
	.on(actions.clearRankings, (state) => ({
		...state,
		items: [],
		next: false,
		is_data_requested: false,
	}))
	.on(actions.setRankingsQuery, (state, payload) => ({
		...state,
		q: payload,
	}))
	.on(actions.getRankingsSuccess, (state, payload) => ({
		...state,
		...payload,
		is_data_requested: false,
	}))
	.on(actions.setShowByOverall, (state) => ({
		...state,
		showByOverall: true,
	}))
	.on(actions.clearShowByOverall, (state) => ({
		...state,
		showByOverall: false,
	}))
	.on(actions.getRankingsFailed, (state) => ({
		...state,
		is_data_requested: false,
	}));
