import {IStore} from "modules/types";
import {createSelector} from "reselect";
import {chain} from "lodash";

export const getRankingsStore = (state: IStore) => state.rankings;
export const getRankings = (state: IStore) => state.rankings.items;

export const getRankingWinners = createSelector(getRankings, (items) =>
	chain(items)
		.filter(({rank}) => rank >= 1 && rank <= 3)
		.sortBy("rank")
		.take(1)
		.value()
);

export const getIsRankingsLoading = createSelector(
	getRankingsStore,
	(rankings) => rankings.is_data_requested
);

export const getIsShowByOverall = createSelector(
	getRankingsStore,
	(rankings) => rankings.showByOverall
);
