import React from "react";
import {Navigate} from "react-router-dom";

export const RedirectToHome: React.FC = () => {
	if (window.location.pathname.includes("terms")) {
		return null;
	}
	return <Navigate to={`/`} />;
};

export default RedirectToHome;
