import React from "react";
import {uniqueId} from "lodash";
import styled from "styled-components";

const SwitcherWrapper = styled.div`
	border-bottom: 1px solid #f6f6f6;
	font-size: 14px;
	line-height: 18px;
	font-family: Arial, sans-serif;
	position: relative;
`;

const SwitcherInput = styled.input`
	position: absolute;
	right: 0;
	z-index: -1;
	width: 1em;
	height: 1em;
	opacity: 0;
`;

const SwitcherLabel = styled.label`
	padding: 20px 90px 20px 0;
	display: block;
	cursor: pointer;
	position: relative;

	&:before,
	&:after {
		content: "";
	}

	&:before {
		position: absolute;
		top: 20px;
		right: 20px;
		width: 50px;
		height: 20px;
		border-radius: 20px;
		background: #e8e8e8;
		transition: background 0.15s ease-in-out;

		${SwitcherInput}:checked~& {
			background: linear-gradient(
				225deg,
				#1c43ff 0%,
				#8321e1 29.38%,
				#e4002b 69.55%,
				#fe5722 100%
			);
		}
	}

	&:after {
		top: 15px;
		width: 30px;
		height: 30px;
		background: #fff;
		box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
		position: absolute;
		border-radius: 50%;
		right: 45px;
		transition: transform 0.15s ease-in-out;

		${SwitcherInput}:checked~& {
			transform: translateX(30px);
		}
	}
`;

interface ISwitcherProps {
	onChange: React.ChangeEventHandler<HTMLInputElement>;
	label: string;
	id?: string;
	value: boolean | number;
	name: string;
}

export const Switcher: React.FC<ISwitcherProps> = ({
	onChange,
	label,
	id = uniqueId("checkbox"),
	value,
	name,
}) => {
	const defaultChecked = Boolean(value);

	return (
		<SwitcherWrapper>
			<SwitcherInput
				onChange={onChange}
				type="checkbox"
				id={id}
				defaultChecked={defaultChecked}
				name={name}
			/>
			<SwitcherLabel htmlFor={id}>{label}</SwitcherLabel>
		</SwitcherWrapper>
	);
};
