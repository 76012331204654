import {isString, get} from "lodash";
import {SagaIterator} from "redux-saga";
import {put, call} from "typed-redux-saga/macro";
import {ISagaAction} from "modules/types";
import {User, ApiError} from "modules/utils";
import {showGlobalError, userLogoutSuccess} from "modules/actions";

type TPayload = Error | string;

// eslint-disable-next-line @typescript-eslint/require-await
const defaultErrorHandler = async (payload: TPayload) => {
	if (isString(payload)) {
		throw new Error(payload);
	}

	if (isString(payload.message)) {
		throw new Error(payload.message);
	}
};

const onError = {
	*[ApiError.AUTH_REQUIRED](): SagaIterator {
		User.CLEAR();
		yield* put(userLogoutSuccess());
		yield* call(defaultErrorHandler, "Session expired. Please log in again");
	},
};

export const errorsManagerSaga = function* ({payload}: ISagaAction<TPayload>): SagaIterator {
	const error_code = get(payload, "code", 0);

	try {
		const onErrorHandler = get(onError, error_code, defaultErrorHandler);
		yield* call(onErrorHandler, payload);
	} catch (err) {
		const message = get(err, "message", "");
		yield* put(
			showGlobalError({
				message: message,
				code: error_code || 0,
			})
		);
	}
};
