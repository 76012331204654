import {createAction} from "redux-act";
import {IPick, IPicksPayload} from "modules/types";

export const fetchTips = createAction<IPicksPayload>();
export const fetchTipsSuccess = createAction<IPick[]>();

export interface ISelectPickPayload {
	squad: number;
	match: number;
	margin: number;
	isCorrect?: boolean;
}

export interface ISelectMarginPayload {
	margin: number;
	match: number;
}

export const selectTip = createAction();
export const saveTip = createAction();
export const saveTipSuccess = createAction();
export const updateMargin = createAction<ISelectMarginPayload>();
export const updatePick = createAction<ISelectPickPayload>();

export const postAutoPick = createAction();
export const postClearPicks = createAction();
export const tipsSaved = createAction<boolean>();

export const showPicksHelp = createAction<number>();
export const hidePicksHelp = createAction();

export interface IViewTeamPicksPayload {
	team_id: number;
}

export const viewUserPicks = createAction<IViewTeamPicksPayload>();
export const removeViewUserPicks = createAction<IViewTeamPicksPayload>();
export const clearViewUserPicks = createAction();
