interface IMediaPoints {
	desktop: string;
	mobile: string;
	small_mobile: string;
}
export const media: IMediaPoints = {
	desktop: "960px",
	mobile: "700px",
	small_mobile: "330px",
};

export default media;
