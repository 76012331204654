import React, {useEffect} from "react";
import styled from "styled-components";
import {isEmpty, isArray, get} from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {fetchLadder, ILadder} from "modules/actions";
import {getLadder, getSquadsKeyedByID} from "modules/selectors";
import {getTeamLogoUrl} from "modules/utils/constants";

const LadderWrapper = styled.div`
	display: flex;
	background: white;
	flex-direction: column;
	width: 100%;
	box-shadow: 0px 0px 20px -15px #333333;
	border-radius: 4px;
`;

const LadderHeader = styled.div`
	font-family: "Halisa";
	font-weight: 700;
	font-size: 14px;
	line-height: 17px;
	color: black;
	padding: 16px 58px;
	width: 100%;
	text-align: center;
`;

interface ILadderWidget {
	sport: string;
}

const HeaderRow = styled.div`
	height: 30px;
	background: black;
	display: flex;
	width: 100%;
	padding-right: 5px;
`;

const SmallHead = styled.div`
	width: 10%;
	font-family: "Montserrat", sans-serif;
	font-size: 12px;
	line-height: 14px;
	color: white;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const LargeHead = styled(SmallHead)`
	width: 55%;
	text-align: left;
	justify-content: flex-start;
	padding-left: 10px;
`;

const StartSmallHead = styled(SmallHead)`
	width: 15%;
`;

const LadderHeaderRow = () => {
	return (
		<HeaderRow>
			<StartSmallHead>Pos</StartSmallHead>
			<LargeHead>Team</LargeHead>
			<SmallHead>Pld.</SmallHead>
			<SmallHead>Won</SmallHead>
			<SmallHead>Pts.</SmallHead>
		</HeaderRow>
	);
};

const StyledTeamRow = styled.div`
	height: 40px;
	display: flex;
	background: white;
	width: 100%;
	border-bottom: 2px solid #f5f5f5;
	padding-right: 5px;
	&:last-of-type {
		border-bottom: none;
		border-radius: 0px 0px 4px 4px;
	}
`;

const SmallCell = styled(SmallHead)`
	color: black;
`;

const StartSmallCell = styled(StartSmallHead)`
	color: black;
`;

const LargeCell = styled(LargeHead)`
	color: black;
	padding-left: 0px;
	p {
		max-width: 125px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	img {
		height: 25px;
		width: 25px;
		margin-right: 5px;
	}
`;

interface ITeamRow {
	team: ILadder;
}

const TeamRow: React.FC<ITeamRow> = ({team}) => {
	const {played, won, position, squadId, points} = team;
	const squad = useSelector(getSquadsKeyedByID)[squadId];
	const name = get(squad, "nickname", "");
	const shortName = get(squad, "shortName", "");
	const imageURL = getTeamLogoUrl(shortName);

	return (
		<StyledTeamRow key={`${squadId}-team-ladder`}>
			<StartSmallCell>{position}</StartSmallCell>
			<LargeCell>
				<img src={imageURL} alt="Team Logo" />
				<p>{name}</p>
			</LargeCell>
			<SmallCell>{played}</SmallCell>
			<SmallCell>{won}</SmallCell>
			<SmallCell>{points}</SmallCell>
		</StyledTeamRow>
	);
};

export const LadderWidget: React.FC<ILadderWidget> = ({sport}) => {
	const dispatch = useDispatch();

	let title;

	if (sport.toUpperCase() === "WBBL") {
		title = `Weber ${sport.toUpperCase()} 10 Ladder`;
	} else {
		title = `KFC ${sport.toUpperCase()} 14 Ladder`;
	}

	const ladder = useSelector(getLadder);

	useEffect(() => {
		dispatch(fetchLadder());
	}, [dispatch]);

	if (isEmpty(ladder) || !isArray(ladder)) {
		return null;
	}
	return (
		<LadderWrapper>
			<LadderHeader>{title}</LadderHeader>
			<LadderHeaderRow />
			{ladder.map((team) => (
				<TeamRow key={team.squadId} team={team} />
			))}
		</LadderWrapper>
	);
};
