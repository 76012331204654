import React, {useEffect, useRef} from "react";
import styled from "styled-components";
import cross from "assets/img/icons/cross.svg";
import {useOverflowHidden} from "modules/utils";

export const ModalWrapper = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.5);
	z-index: 999;
	display: flex;
	justify-content: center;
	align-items: center;

	@media (min-width: 640px) {
		padding: 30px;
	}
`;

export const ModalInner = styled.div`
	width: 100%;
	height: 100%;
	max-width: 100%;
	border-radius: 5px;
	background: #fff;
	position: relative;
	box-sizing: border-box;
	max-height: 100%;
	display: flex;
	z-index: 1000;
	flex-direction: column;

	@media (min-width: 640px) {
		width: 780px;
		height: auto;
		border-radius: 5px;
	}
`;

export const CloseButton = styled.button`
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;
	background: none;
	border-radius: 0;
	border: none;
	width: 42px;
	height: 42px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	z-index: 10;
	&:focus {
		border: 1px solid black;
	}

	@media (min-width: 640px) {
		height: 65px;
		width: 65px;

		> img {
			width: 32px;
			height: 32px;
		}
	}
`;

interface IProps {
	children?: React.ReactNode;
	onClose: () => null | void;
	isVisible: boolean;
	closeDataAttr?: {[key: string]: string};
}

export const HOCModal: React.FC<IProps> = ({
	children = null,
	onClose,
	isVisible,
	closeDataAttr,
	...rest
}: IProps) => {
	const modalRef = useRef<HTMLDivElement>(null);
	const closeButtonRef = useRef<HTMLButtonElement>(null);
	useOverflowHidden(isVisible);

	useEffect(() => {
		const handleEscClose = (event: KeyboardEvent) => {
			const eventValue = event || window.event;
			if (eventValue.key === "Escape" 
				|| eventValue.key === "Esc" 
				|| event.keyCode === 27
			) {
				onClose();
			}
		};

		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				onClose();
			}
		};

		if (isVisible) {
			window.addEventListener("keydown", handleEscClose);
			document.addEventListener("mousedown", handleClickOutside);
			closeButtonRef.current?.focus();
		}

		return () => {
			window.removeEventListener("keydown", handleEscClose);
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [isVisible, onClose]);

	return (
		<ModalWrapper {...rest}>
			<ModalInner ref={modalRef}>
				<CloseButton onClick={onClose} tabIndex={0} ref={closeButtonRef}>
					<img src={cross} alt="Close" {...closeDataAttr} />
				</CloseButton>
				{children}
			</ModalInner>
		</ModalWrapper>
	);
};

export * from "./Steps";
export * from "./ErrorModal";
export * from "components/Modal/HOCConfirmModal";
export * from "./ShareModal";
export * from "./HOCPromptModal";
export * from "./BonusPointModal";
