import {SPORTS, BBL_GOLDEN_WEEK, WBBL_GOLDEN_WEEK} from "modules/utils/constants";
import {keys, isEmpty} from "lodash";
import {SportType} from "modules/utils/enums";
import {Storage} from "modules/utils";
import {IMergedPick} from "modules/types";

export const allTrue = (items: boolean[]) => items.every((val) => val === true);

export const getSportName = () => {
	const storedSport = Storage.GET("sport_select") || "";
	const currentSport = window.location.pathname.split("/")[1];
	const sportNameInPath = keys(SPORTS).find((sport) => currentSport === sport);
	if (allTrue([sportNameInPath !== storedSport, !isEmpty(sportNameInPath)])) {
		const sportSelect = sportNameInPath || "";
		Storage.SET("sport_select", sportSelect);
	}
	if (storedSport.length > 0 && isEmpty(sportNameInPath)) {
		return storedSport;
	}
	return sportNameInPath || SportType.WBBL;
};

export const getFirstPageLink = () => {
	return Storage.GET("FirstPageLink");
};

export const removeFirstPageLink = () => {
	return Storage.REMOVE("FirstPageLink");
};

export const ordinal = (value: number) => {
	const j = value % 10;
	const k = value % 100;
	if (j === 1 && k !== 11) {
		return `${value}st`;
	}
	if (j === 2 && k !== 12) {
		return `${value}nd`;
	}
	if (j === 3 && k !== 13) {
		return `${value}rd`;
	}
	return `${value}th`;
};

export const CURR_SPORT_GOLDEN_WEEK = getSportName() === "bbl" ? BBL_GOLDEN_WEEK : WBBL_GOLDEN_WEEK;

export const isAutoPickNotCounted = (picks: IMergedPick[], pickID: number) => {
	const LIMIT_AUTO_PICKS = 4;
	const index = picks.map((pick) => pick.match).indexOf(pickID);
	const pick = picks[index];
	if (!pick.isAutoTip) {
		return false;
	}
	if (!pick.isCorrect) {
		return false;
	}

	const numberCorrectNotAutoInRound = picks.reduce((num, pick) => {
		if (pick.isCorrect && !pick.isAutoTip) {
			return (num += 1);
		}
		return num;
	}, 0);
	if (numberCorrectNotAutoInRound > 4 && pick.isAutoTip) {
		return true;
	}

	const picksUpToIncluding = picks.slice(0, index);
	const numberCorrectSoFar = picksUpToIncluding.reduce((num, pick) => {
		if (pick.isCorrect) {
			return (num += 1);
		}
		return num;
	}, 0);
	return numberCorrectSoFar >= LIMIT_AUTO_PICKS && pick.isCorrect;
};
