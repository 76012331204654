import React, {useCallback, useEffect, useState} from "react";
import {Exist} from "components";
import styled from "styled-components";
import {toInteger, debounce, get} from "lodash";
import {useDispatch} from "react-redux";
import {updateMargin, updatePick, saveTip} from "modules/actions";
// import {getSquadsKeyedByID} from "modules/selectors";
import {IMergedPick} from "modules/types";
import {ReactComponent as Minus} from "assets/img/icons/minus.svg";
import {ReactComponent as Plus} from "assets/img/icons/plus.svg";
import {MatchStatus, usePrevious} from "modules/utils";
import {theme} from "assets/themes/bbl";

export const MarginWrapper = styled.div`
	padding: 10px 0;
	height: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	p {
		font-family: "InterFace Corp", sans-serif;
		font-size: 14px;
		line-height: 17px;
		text-align: center;
		margin-bottom: 5px;
	}
	div {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 180px;
	}
	@media (max-width: 640px) {
		div {
			width: 300px;
		}
	}
`;

const MarginTitle = styled.h1`
	font-family: "Montserrat";
	font-size: 14px;
	line-height: 18px;
	color: #000;
	font-weight: 600;
	text-align: center;
	margin-bottom: 15px;
	@media (max-width: 640px) {
		font-size: 11px;
		line-height: 14px;
		margin-bottom: 10px;
	}
`;

export const MarginInputWrapper = styled.div`
	position: relative;
	width: 300px;
	margin: 0 auto;
	@media (max-width: 639px) {
		width: 80%;
		position: static;
	}
`;

export const MarginButton = styled.button`
	min-width: 40px;
	height: 40px;
	border-radius: 2px;
	color: white;
	display: flex;
	align-items: center;
	background: linear-gradient(135deg, rgba(231, 9, 42, 1) 0%, rgba(103, 42, 233, 1) 100%);
	border: none;
	outline: none;
	justify-content: center;

	svg {
		color: #fff;
	}

	&:disabled {
		opacity: 0.7;
		color: #757575;
		svg {
			color: #757575;
		}
	}

	span {
		text-transform: uppercase;
	}

	@media (max-width: 639px) {
		span {
			display: none;
		}
		svg {
			padding: 0;
		}
	}
`;
export const MarginInput = styled.input`
	height: 40px;
	width: 100px;
	background-color: #fff0f0;
	text-align: center;
	font-size: 22px;
	color: #000;
	font-family: "BTBrik", sans-serif;
	border: none;
	/* Chrome, Safari, Edge, Opera */
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	&[type="number"] {
		-moz-appearance: textfield;
	}
	@media (max-width: 640px) {
		width: 220px;
		margin-right: 2px;
	}
`;

const ActiveMarginWrapper = styled.div`
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

const ActiveMarginContent = styled.div<{
	isComplete: boolean;
}>`
	width: 190px;
	height: 40px;
	background-color: #fff0f0;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: ${theme.font.montserrat};
	font-weight: 500;
	color: #000;
	font-size: 14px;
	line-height: 18px;
	span {
		font-family: ${theme.font.btBrik};
		font-weight: 400;
		font-size: 22px;
		line-height: 21px;
		margin-left: 5px;
	}
	${({isComplete}) =>
		isComplete &&
		`
		width: 300px;
		padding: 0 5px;
		.gap{
			min-width: 15px;
		}
	`}
`;

const MAX_MARGIN = 100;
const MIN_MARGIN = 0;

interface IProps {
	predictMargin: number;
	pick: IMergedPick;
	squadId: number;
	isScheduled: boolean;
}

// const getWinnerSquadName = (pick: IMergedPick, homeSquad: ISquad, awaySquad: ISquad) => {
// 	const {homeScore, awayScore} = pick;

// 	if (!homeScore || !awayScore) {
// 		return "";
// 	}
// 	return homeScore > awayScore ? homeSquad.shortName : awaySquad.shortName;
// };

// const getSelectedSquadName = (pick: IMergedPick, homeSquad: ISquad, awaySquad: ISquad) => {
// 	const {squad, homeSquadId} = pick;
// 	const homeShortName = get(homeSquad, "shortName", "");
// 	const awayShortName = get(awaySquad, "shortName", "");

// 	if (!squad) {
// 		return "";
// 	}

// 	return squad === homeSquadId ? homeShortName : awayShortName;
// };

// eslint-disable-next-line complexity
export const Margin: React.FC<IProps> = ({predictMargin, squadId, pick, isScheduled}) => {
	// get state for post game margin
	const [margin, setMargin] = useState(predictMargin);
	const dispatch = useDispatch();
	// const squadsById = useSelector(getSquadsKeyedByID);
	// const homeSquad = squadsById[pick.homeSquadId];
	// const awaySquad = squadsById[pick.awaySquadId];
	const isPickMade = Boolean(pick.squad);
	const isDisabled = !isScheduled && !isPickMade;
	const pickId = pick.id;
	const finalMargin = get(pick, "marginResult", 0);
	const isActive = pick.status === MatchStatus.Active;
	const isComplete = pick.status === MatchStatus.Complete;
	// const winnerSquadName = getWinnerSquadName(pick, homeSquad, awaySquad);
	// const selectedSquadName = getSelectedSquadName(pick, homeSquad, awaySquad);
	const prevProps = usePrevious({predictMargin});
	const prevMargin: number = get(prevProps, "predictMargin", 0);

	const sendUserMargin = () => {
		if (margin === predictMargin) {
			return;
		}
		if (squadId) {
			dispatch(
				updatePick({
					squad: squadId,
					match: pickId,
					margin: margin,
				})
			);
			dispatch(saveTip());
		} else {
			dispatch(updateMargin({margin, match: pickId}));
			dispatch(saveTip());
		}
	};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const delayedQuery = useCallback(debounce(sendUserMargin, 500), [margin, predictMargin]);

	useEffect(() => {
		if (prevMargin !== predictMargin) {
			setMargin(predictMargin);
		}

		delayedQuery();

		// Cancel the debounce on useEffect cleanup.
		// eslint-disable-next-line @typescript-eslint/unbound-method
		return delayedQuery.cancel;
	}, [delayedQuery, margin, predictMargin, prevMargin]);

	const increaseMargin = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		e.preventDefault();
		setMargin(margin + 1);
	};

	const decreaseMargin = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		e.preventDefault();

		const newMargin = margin - 1;

		if (newMargin >= 0) {
			setMargin(newMargin);
		}
	};

	const changeMargin = (e: React.SyntheticEvent<HTMLInputElement>) => {
		const {value} = e.currentTarget;
		let newMargin = toInteger(value);
		if (isNaN(newMargin) || !isPickMade) {
			return;
		}

		if (newMargin < MIN_MARGIN) {
			newMargin = MIN_MARGIN;
		}

		if (newMargin > MAX_MARGIN) {
			newMargin = MAX_MARGIN;
		}

		setMargin(newMargin);
	};

	const decreaseDisabled = margin === MIN_MARGIN || !isPickMade;
	const increaseDisabled = margin === MAX_MARGIN || !isPickMade;
	// const completeMargin = t("picks.margin.actual_result", {
	// 	predictedId: selectedSquadName,
	// 	predictedMargin: margin,
	// 	winnerId: winnerSquadName,
	// 	winnerMargin: finalMargin,
	// });

	if (isActive) {
		return (
			<ActiveMarginWrapper>
				<MarginTitle>How many sixes will be hit in this match?</MarginTitle>
				<ActiveMarginContent isComplete={false}>
					Prediction - <span>{margin}</span>
				</ActiveMarginContent>
			</ActiveMarginWrapper>
		);
	}
	if (isComplete) {
		return (
			<ActiveMarginWrapper>
				<MarginTitle>How many sixes will be hit in this match?</MarginTitle>
				<ActiveMarginContent isComplete={isComplete}>
					Prediction - <span>{margin}</span>
					<span className="gap"> </span>
					Result - <span>{finalMargin}</span>
				</ActiveMarginContent>
			</ActiveMarginWrapper>
		);
	}

	return (
		<MarginWrapper>
			<MarginTitle>How many sixes will be hit in this match?</MarginTitle>
			<div>
				<Exist when={isScheduled}>
					<MarginButton
						aria-label="Add Less Sixes"
						onClick={decreaseMargin}
						disabled={decreaseDisabled}>
						<Minus />
					</MarginButton>
				</Exist>

				<MarginInputWrapper>
					<MarginInput
						aria-label={"selected_margin"}
						name="selectedMargin"
						type="number"
						value={margin}
						onChange={changeMargin}
						disabled={isDisabled}
					/>
				</MarginInputWrapper>
				<Exist when={isScheduled}>
					<MarginButton
						aria-label="Add More Sixes"
						onClick={increaseMargin}
						disabled={increaseDisabled}>
						<Plus />
					</MarginButton>
				</Exist>
			</div>
		</MarginWrapper>
	);
};
