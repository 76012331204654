import React from "react";
import styled from "styled-components";
import {NavLink} from "react-router-dom";
import wbbl_bbl_logo from "assets/img/WBBL_BBL_Logos.png";
import big_bash_app from "assets/img/Big_Bash_App_Icon.png";
import genius_logo from "assets/img/genius_logo.png";
import {media} from "assets/css/media";
import {getSportName} from "modules/utils/helpers";
import {theme} from "assets/themes/bbl";
import {IS_OFF_SEASON} from "modules/utils";

const Wrapper = styled.footer`
	height: 340px;
	width: 100%;
	background: #000000;
	padding: 80px 120px 45px 120px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media screen and (max-width: ${media.mobile}) {
		padding: 40px 15px 40px 30px;
		flex-direction: column;
		align-items: flex-start;
		height: 393px;
	}
`;

const FooterContent = styled.div`
	max-width: 1280px;
	display: flex;
	justify-content: space-between;
	margin: auto;
	width: 100%;
	@media screen and (max-width: ${media.mobile}) {
		flex-direction: column;
	}
`;

const ContentBlocks = styled.div<{
	isSecondBlock: boolean;
}>`
	height: ${({isSecondBlock}) => (isSecondBlock ? "220px" : "100%")};
	width: 40%;
	display: flex;
	flex-direction: column;
	align-items: ${({isSecondBlock}) => (isSecondBlock ? "flex-end" : "flex-start")};
	${({isSecondBlock}) =>
		isSecondBlock &&
		`
		justify-content: flex-end;
	`}
	@media screen and (max-width: ${media.mobile}) {
		width: 100%;
		${({isSecondBlock}) =>
			isSecondBlock &&
			`
			justify-content: flex-start;
			align-items: flex-start;
			margin-top: 30px;
			height: 100%;
		`}
	}
`;

const LinksWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 30px;
`;

const StyledNavLink = styled(NavLink)`
	font-family: "Montserrat", sans-serif;
	font-size: 14px;
	font-weight: 400px;
	margin-right: 20px;
	line-height: 22px;
	color: white;
	padding-bottom: 5px;
	border-bottom: 1px solid white;
	cursor: pointer;
`;

const ExternalLink = styled.a`
	font-family: "Montserrat", sans-serif;
	font-size: 14px;
	font-weight: 400px;
	margin-right: 20px;
	line-height: 22px;
	color: white;
	padding-bottom: 5px;
	border-bottom: 1px solid white;
	cursor: pointer;
	text-decoration: none;
`;

const CopyrightText = styled.div`
	font-size: 10px;
	line-height: 18px;
	color: white;
	font-family: "Montserrat", sans-serif;
	margin-bottom: 30px;
	@media screen and (max-width: ${media.mobile}) {
		font-size: 12px;
	}
`;

const LogosWrapper = styled.div`
	display: flex;
	align-items: center;
	img {
		max-height: 73px;
		margin-right: 10px;
	}
`;

const LogoLink = styled.a`
	height: 73px;
	margin-right: 10px;
	position: relative;
	img {
		height: 73px;
	}
	.wbbl-logo {
		position: relative;
		top: -10px;
		min-height: 88px;
	}
	@media screen and (max-width: ${media.mobile}) {
		margin-right: 0;
		img {
			height: 70px;
			margin: 0;
		}
		.wbbl-logo {
			min-height: 80px;
		}
	}
`;

const FanhubLink = styled(LogoLink)`
	display: flex;
	align-items: flex-end;
	p {
		font-family: ${theme.font.montserrat};
		font-size: 10px;
		text-transform: uppercase;
		color: #fff;
		margin-right: 10px;
		margin-bottom: 16px;
		&:hover {
			border-bottom: 1px solid #fff;
		}
	}
	img {
		height: 40px;
	}
	@media screen and (max-width: ${media.mobile}) {
		height: 30px;
	}
`;

export const Footer: React.FC = () => {
	const termsLink = IS_OFF_SEASON ? "" : `/${getSportName()}/help/terms`;
	return (
		<Wrapper>
			<FooterContent>
				<ContentBlocks isSecondBlock={false}>
					<LinksWrapper>
						<StyledNavLink to={`${termsLink}`} target="_blank" rel="noreferrer">
							Terms & Conditions
						</StyledNavLink>
						<ExternalLink
							href="https://www.cricket.com.au/privacy"
							target="_blank"
							rel="noreferrer">
							Privacy Policy
						</ExternalLink>
					</LinksWrapper>
					<CopyrightText>
						cricket.com.au is a production of CA Digital Media - a division of Cricket
						Australia.
					</CopyrightText>
					<CopyrightText>© 2024 Cricket Australia. All rights reserved.</CopyrightText>
					<LogosWrapper>
						<LogoLink
							href="https://www.cricket.com.au/big-bash"
							target="_blank"
							rel="noreferrer">
							<img src={wbbl_bbl_logo} alt="WBBL BBL Logos" />
						</LogoLink>
						<LogoLink
							className="cricket-logo"
							href="https://www.cricket.com.au/big-bash"
							target="_blank"
							rel="noreferrer">
							<img src={big_bash_app} alt="Big Bash App" />
						</LogoLink>
					</LogosWrapper>
				</ContentBlocks>
				<ContentBlocks isSecondBlock={true}>
					<FanhubLink
						href="https://geniussports.com/genius-marketing-suite/ "
						target="_blank"
						rel="noreferrer">
						<img src={genius_logo} alt="Genius Sports Logo" />
					</FanhubLink>
				</ContentBlocks>
			</FooterContent>
		</Wrapper>
	);
};
