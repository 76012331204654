import {SagaIterator} from "redux-saga";
import {all, takeLatest} from "redux-saga/effects";

import {
	fetchRounds,
	fetchSquads,
	fetchStates,
	fetchLadder,
	fetchTips,
	globalError,
	userFetch,
	userLogin,
	userLoginSSO,
	userLogout,
	recoverUserForGame,
	userRegistration,
	userRegistrationSSO,
	userRegisterForGame,
	fetchJoinedUsers,
	fetchLeague,
	fetchLeaguesForJoin,
	fetchShowMyLeagues,
	getLeagueLadder,
	getLeagueLadderData,
	joinToLeague,
	postCreateLeague,
	postDeleteJoinedUser,
	postDeleteJoinedUsers,
	postDeleteLeague,
	postEditUser,
	postInvite,
	postLeaveLeague,
	postSendEmails,
	postUpdateLeague,
	searchAndJoin,
	postPWDResetRequest,
	requestPWDReset,
	postAutoPick,
	postClearPicks,
	fetchSnapshot,
	userUpdate,
	postDeactivateUser,
	userPasswordUpdate,
	postContact,
	userCommunicationsUpdate,
	getHelpContent,
	getRankingsData,
	fetchRoundStats,
	fetchFormGuide,
	fetchDashboard,
	saveTip,
	fetchUserCountry,
	getFaqContent,
	fetchSelectedRound,
	fetchMREC,
	fetchNotificationBar,
	fetchVenues,
	subscribeToLiveScores,
	fetchChecksums,
	fetchOtherUserTips,
	userPreregister,
} from "modules/actions";

import {
	errorsManagerSaga,
	fetchRoundsSaga,
	fetchLadderSaga,
	fetchSquadsSaga,
	fetchStatesSaga,
	userFetchSaga,
	userLoginSaga,
	userLoginSSOSaga,
	userLogoutSaga,
	userRegistrationSaga,
	userRegistrationSSOSaga,
	userRegisterForGameSaga,
	recoverUserForGameSaga,
	fetchTipSaga,
	searchAndJoinSaga,
	fetchJoinedUsersSaga,
	fetchShowMyLeaguesSaga,
	getJoinListLeaguesSaga,
	postJoinToLeagueSaga,
	postSendEmailsSaga,
	postInviteSaga,
	postLeaveLeagueSaga,
	fetchLeagueSaga,
	postEditUserSaga,
	postDeleteLeagueSaga,
	postCreateLeagueSaga,
	postUpdateLeagueSaga,
	postDeleteJoinedUserSaga,
	postDeleteJoinedUsersSaga,
	getLadderDataSaga,
	getLeagueLadderDataSaga,
	postPWDResetRequestSaga,
	requestPWDResetSaga,
	postClearPicksSaga,
	postAutoPickSaga,
	userUpdateSaga,
	postDeactivateUserSaga,
	postContactSaga,
	getRankingsSaga,
	fetchRoundStatsSaga,
	fetchFormGuideSaga,
	fetchHelpContentSaga,
	saveTipSaga,
	fetchUserCountrySaga,
	fetchFaqSaga,
	fetchSelectedRoundSaga,
	fetchMRECSaga,
	fetchNotificationBarSaga,
	fetchVenueSaga,
	fetchLiveScoresSaga,
	fetchChecksumsSaga,
	fetchOtherUserTipsSaga,
	userPreregistrationSaga,
} from "modules/sagas";
import {fetchSnapshotSaga} from "modules/sagas/tipping_stats";
import {fetchDashboardSaga} from "modules/sagas/dashboard";

/**
 * Root saga that connect sagas with actions.
 */
export const rootSaga = function* (): SagaIterator {
	yield all([
		takeLatest(globalError, errorsManagerSaga),
		takeLatest(postContact, postContactSaga),

		// JSONs
		takeLatest(fetchStates, fetchStatesSaga),
		takeLatest(fetchSquads, fetchSquadsSaga),
		takeLatest(fetchRounds, fetchRoundsSaga),
		takeLatest(fetchLadder, fetchLadderSaga),
		takeLatest(fetchRoundStats, fetchRoundStatsSaga),
		takeLatest(fetchFormGuide, fetchFormGuideSaga),
		takeLatest(fetchSelectedRound, fetchSelectedRoundSaga),
		takeLatest(fetchMREC, fetchMRECSaga),
		takeLatest(fetchNotificationBar, fetchNotificationBarSaga),
		takeLatest(fetchVenues, fetchVenueSaga),

		// User
		takeLatest(userLogin, userLoginSaga),
		takeLatest(userPreregister, userPreregistrationSaga),
		takeLatest(userRegistration, userRegistrationSaga),
		takeLatest(userLoginSSO, userLoginSSOSaga),
		takeLatest(userRegistrationSSO, userRegistrationSSOSaga),
		takeLatest(userRegisterForGame, userRegisterForGameSaga),
		takeLatest(recoverUserForGame, recoverUserForGameSaga),
		takeLatest(userFetch, userFetchSaga),
		takeLatest(userLogout, userLogoutSaga),
		takeLatest(postPWDResetRequest, postPWDResetRequestSaga),
		takeLatest(requestPWDReset, requestPWDResetSaga),
		takeLatest(userUpdate, userUpdateSaga),
		takeLatest(userPasswordUpdate, userUpdateSaga),
		takeLatest(userCommunicationsUpdate, userUpdateSaga),
		takeLatest(postDeactivateUser, postDeactivateUserSaga),
		takeLatest(fetchUserCountry, fetchUserCountrySaga),

		// Picks
		takeLatest(fetchTips, fetchTipSaga),
		takeLatest(postAutoPick, postAutoPickSaga),
		takeLatest(postClearPicks, postClearPicksSaga),
		takeLatest(fetchSnapshot, fetchSnapshotSaga),
		takeLatest(saveTip, saveTipSaga),
		takeLatest(fetchOtherUserTips, fetchOtherUserTipsSaga),

		// Leagues
		takeLatest(postCreateLeague, postCreateLeagueSaga),
		takeLatest(fetchLeague, fetchLeagueSaga),
		takeLatest(postInvite, postInviteSaga),
		takeLatest(postUpdateLeague, postUpdateLeagueSaga),
		takeLatest(postLeaveLeague, postLeaveLeagueSaga),
		takeLatest(postDeleteLeague, postDeleteLeagueSaga),
		takeLatest(fetchShowMyLeagues, fetchShowMyLeaguesSaga),
		takeLatest(fetchLeaguesForJoin, getJoinListLeaguesSaga),
		takeLatest(joinToLeague, postJoinToLeagueSaga),
		takeLatest(searchAndJoin, searchAndJoinSaga),
		takeLatest(fetchJoinedUsers, fetchJoinedUsersSaga),
		takeLatest(postDeleteJoinedUser, postDeleteJoinedUserSaga),
		takeLatest(postDeleteJoinedUsers, postDeleteJoinedUsersSaga),
		takeLatest(getLeagueLadderData, getLadderDataSaga),
		takeLatest(getLeagueLadder, getLeagueLadderDataSaga),
		takeLatest(postEditUser, postEditUserSaga),
		takeLatest(postSendEmails, postSendEmailsSaga),

		// Checksums
		takeLatest(subscribeToLiveScores, fetchLiveScoresSaga),
		takeLatest(fetchChecksums, fetchChecksumsSaga),

		// Help
		takeLatest(getHelpContent, fetchHelpContentSaga),
		takeLatest(getFaqContent, fetchFaqSaga),

		// Rankings
		takeLatest(getRankingsData, getRankingsSaga),

		// Dashboard
		takeLatest(fetchDashboard, fetchDashboardSaga),
	]);
};

export * from "./errors";
export * from "./jsons";
export * from "./user";
export * from "./leagues";
export * from "./leagues_for_join";
export * from "./picks";
export * from "./league_ladder";
export * from "./help";
export * from "./rankings";
export * from "./liveScoring";
export * from "./tipping_stats";
