import styled from "styled-components";

export const WithSidebarContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding: 30px 10px 10px;
	box-sizing: border-box;
	flex-grow: 1;
	flex-direction: column;

	@media (min-width: 640px) {
		position: relative;
		padding: 0px;
		max-width: 1200px;
		margin-left: auto;
		margin-right: auto;
	}

	@media (min-width: 840px) {
		flex-direction: row;
	}

	> div {
		width: 100%;
	}

	aside {
		width: 100%;
		margin-left: 0;

		@media (min-width: 840px) {
			margin-left: 40px;
			width: 300px;
			flex-shrink: 0;
		}

		@media (max-width: 639px) {
			display: none;
		}
	}
	@media (max-width: 639px) {
		padding: 10px;
	}
`;
