import {SagaIterator} from "redux-saga";
import {call, delay, put, select} from "typed-redux-saga/macro";
import * as actions from "modules/actions";
import {Api} from "modules/utils/Api";
import {IPick, IPicksPayload, ISagaAction} from "modules/types";
import {
	getTips,
	getSelectedRoundID,
	getSelectedRound,
	getMergedTipsMatchesSelectedRound,
} from "modules/selectors";
import {ApiError, MatchStatus} from "modules/utils";
import {chain, identity} from "lodash";

export const fetchTipSaga = function* ({payload}: ISagaAction<IPicksPayload>): SagaIterator {
	try {
		const response = yield* call(Api.tip.show_my_list, payload);
		ApiError.CHECK(response);

		yield* put(actions.fetchTipsSuccess(response.success.predictions));
	} catch (err) {
		yield* put(actions.globalError(err));
	}
};

export const saveTipSaga = function* (): SagaIterator {
	yield* put(actions.tipsSaved(false));
	try {
		const selectedRoundID = yield* select(getSelectedRoundID);
		const selectedRound = yield* select(getSelectedRound(selectedRoundID));
		const picks = yield* select(getMergedTipsMatchesSelectedRound(selectedRound));
		const data =
			chain(picks)
				.map((tip) => {
					const {status, squad, margin, match} = tip;

					if (status !== MatchStatus.Scheduled || !squad) {
						return undefined;
					}
					return {
						squad,
						margin,
						match,
					};
				})
				.filter(identity)
				.value() || [];

		const response = yield* call(Api.tip.save_list, {predictions: data} as {
			predictions: IPick[];
		});

		ApiError.CHECK(response);

		yield* put(actions.fetchTipsSuccess(response.success.predictions));
		yield* put(actions.tipsSaved(true));
		yield* delay(1500);
		yield* put(actions.saveTipSuccess());
	} catch (err) {
		console.log(err);
		const tips = yield* select(getTips);
		yield* put(actions.fetchTipsSuccess(tips));
		yield* put(actions.globalError(err));
	}
};

// export const saveTipSaga = function* () {
// 	try {
// 		yield* put(actions.tipsSaved(false));
// 		yield* delay(1000);
// 		yield* put(actions.tipsSaved(true));
// 		yield* delay(1500);
// 		yield* put(actions.saveTipSuccess());
// 	} catch (err) {
// 		yield* put(actions.saveTipSuccess());
// 	}
// };

export const postAutoPickSaga = function* (): SagaIterator {
	try {
		const selectedRoundID = yield* select(getSelectedRoundID);
		const selectedRound = yield* select(getSelectedRound(selectedRoundID));
		const picks = yield* select(getMergedTipsMatchesSelectedRound(selectedRound));
		const payload = picks
			.filter((pick) => {
				return !pick.squad;
			})
			.map((pick) => ({
				match: pick.id,
			}));
		const response = yield* call(Api.tip.autopick, {
			predictions: payload,
		});
		ApiError.CHECK(response);
		yield* put(actions.fetchTipsSuccess(response.success.predictions));
		yield* put(actions.tipsSaved(true));
	} catch (err) {
		yield* put(actions.globalError(err));
	}
};
export const postClearPicksSaga = function* (): SagaIterator {
	try {
		const selectedRoundID = yield* select(getSelectedRoundID);
		const selectedRound = yield* select(getSelectedRound(selectedRoundID));
		const picks = yield* select(getMergedTipsMatchesSelectedRound(selectedRound));
		const payload = picks
			.filter((pick) => pick.status === "scheduled")
			.map((pick) => ({
				match: pick.id,
			}));
		const response = yield* call(Api.tip.clear_list, {
			predictions: payload,
		});
		ApiError.CHECK(response);
		yield* put(actions.fetchTipsSuccess(response.success.predictions));
		yield* put(actions.tipsSaved(true));
	} catch (err) {
		yield* put(actions.globalError(err));
	}
};
