import React from "react";

export const IconShare: React.FC = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="17"
			height="18"
			fill="none"
			viewBox="0 0 17 18">
			<path
				fill="#000"
				fillRule="evenodd"
				d="M14 12c-.808 0-1.539.323-2.078.843L5.873 9.818C5.947 9.556 6 9.286 6 9c0-.286-.053-.556-.127-.818l6.049-3.025c.539.52 1.27.843 2.078.843a3 3 0 10-3-3c0 .125.022.243.037.364L4.701 6.532a3 3 0 100 4.936l6.336 3.168c-.015.121-.037.239-.037.364a3 3 0 103-3z"
				clipRule="evenodd"></path>
		</svg>
	);
};
