import {SagaIterator} from "redux-saga";
import {Api} from "modules/utils/Api";
import * as actions from "modules/actions";
import {call, put, select} from "typed-redux-saga/macro";
import {getHelp} from "modules/selectors";
import {ISagaAction} from "modules/types";

export const fetchFaqSaga = function* (action: ISagaAction<string>): SagaIterator {
	try {
		const request = action.payload === "bbl" ? Api.JSON.faq_bbl : Api.JSON.faq_wbbl;
		const result = yield* call(request);
		const help = yield* select(getHelp);

		yield* put(
			actions.setHelpContent({
				...help,
				faq: result,
			})
		);
	} catch (err) {
		yield* put(actions.globalError(err));
	}
};

export const fetchHelpContentSaga = function* (action: ISagaAction<string>): SagaIterator {
	try {
		const request =
			action.payload === "bbl" ? Api.JSON.help_pages_bbl : Api.JSON.help_pages_wbbl;
		const helpPages = yield* call(request);

		yield* put(actions.setHelpContent(helpPages));
	} catch (err) {
		yield* put(actions.globalError(err));
	}
};
