/* eslint-disable complexity */
import React, {useCallback, useState, useEffect} from "react";
import styled from "styled-components";
import {useSelector, useDispatch} from "react-redux";
import {LoaderBar, Exist, TipRow} from "components";
import {xor, get, isEmpty} from "lodash";
import {
	viewUserPicks,
	removeViewUserPicks,
	fetchOtherUserTips,
	clearViewUserPicks,
} from "modules/actions";
import {
	getLeaguesLadderStore,
	getRankingsStore,
	getUser,
	getViewingUserTips,
	getSelectedRoundID,
	getOtherUserTips,
	getIsShowByOverall,
	getRoundByIdSelector,
} from "modules/selectors";
import {IRankingsItem} from "modules/types";
import {theme} from "assets/themes/bbl";
import {IconArrowRight} from "components/Icons/IconArrowRight";
import {useMediaQuery} from "modules/utils";

const Wrapper = styled.div`
	width: 100%;

	@media (max-width: 639px) {
		overflow: auto;
	}
`;

const ListHeader = styled.div`
	display: flex;
	align-items: center;
	height: 50px;
	background: black;
	width: 100%;
`;

const PositionHead = styled.div`
	width: 10%;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	color: white;
	font-family: ${theme.font.montserrat};
	font-weight: 600;
	font-size: 12px;
	line-height: 14px;
	@media screen and (max-width: 639px) {
		width: 15%;
	}
`;

const UserNameHead = styled(PositionHead)<{
	isShowOvr: boolean;
}>`
	justify-content: flex-start;
	width: ${({isShowOvr}) => (isShowOvr ? "55%" : "40%")};
`;

const RoundHead = styled(PositionHead)`
	width: 15%;
	text-transform: uppercase;
	span {
		display: none;
	}
	@media screen and (max-width: 640px) {
		width: 20%;
		font-size: 10px;
		text-transform: capitalize;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;
		span {
			position: absolute;
			bottom: 8px;
			display: block;
			font-size: 8px;
			text-transform: lowercase;
		}
	}
`;

const MarginHead = styled(RoundHead)`
	@media screen and (max-width: 640px) {
		display: none;
	}
`;

const TipViewHead = styled(RoundHead)`
	width: 5%;
	@media screen and (max-width: 640px) {
		width: 15%;
	}
`;

const PositionItem = styled.div`
	font-family: ${theme.font.montserrat};
	font-size: 12px;
	line-height: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	color: ${theme.colors.primary};
	width: 10%;
	@media screen and (max-width: 640px) {
		width: 15%;
	}
`;

const UserNameItem = styled(PositionItem)<{
	isShowOvr: boolean;
}>`
	width: ${({isShowOvr}) => (isShowOvr ? "55%" : "40%")};
	justify-content: flex-start;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	span {
		margin-left: 10px;
		font-weight: 400;
		letter-spacing: 0.5px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	@media screen and (max-width: 640px) {
		flex-direction: column;
		justify-content: unset;
		align-items: flex-start;
		font-size: 14px;
		span {
			margin-left: 0px;
			font-size: 12px;
		}
	}
`;

const PointsItem = styled(PositionItem)`
	width: 15%;
	span {
		display: none;
	}
	@media screen and (max-width: 640px) {
		width: 20%;
		flex-direction: column;
		justify-content: unset;
		align-items: center;
		span {
			display: block;
			font-size: 10px;
			color: rgba(0, 0, 0, 0.5);
		}
	}
`;

const MarginItem = styled(PointsItem)`
	@media screen and (max-width: 640px) {
		display: none;
	}
`;

const TipViewItem = styled.button<{
	isOpen: boolean;
}>`
	font-family: ${theme.font.montserrat};
	font-size: 12px;
	line-height: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	color: ${theme.colors.primary};
	width: 5%;
	outline: none;
	background: none;
	border: none;
	${({isOpen}) =>
		isOpen &&
		`
		svg{
			transform: rotate(90deg);
		}
	`}
	svg {
		min-height: 14px;
		min-width: 9px;
	}
	@media screen and (max-width: 640px) {
		width: 15%;
	}
`;

const TeamRow = styled.div<{
	isUser: boolean;
	isOpen: boolean;
}>`
	display: flex;
	align-items: center;
	height: 50px;
	background: white;
	border-bottom: 1px solid #f1f1f1;
	${({isUser}) =>
		isUser &&
		`
		border-bottom: 1px solid #303030;
	`}
`;

const ScrolledWrapper = styled.div`
	overflow: hidden;
	@media (min-width: 640px) {
		border-radius: 4px;
	}
`;

const EmptyText = styled.div`
	height: 70px;
	text-transform: uppercase;
	padding-left: 20px;
	display: flex;
	align-items: center;
`;

const isOwn = ({userId}: IRankingsItem, id?: number) => {
	return userId === id;
};

const initialState: number[] = [];

interface IProps {
	roundId?: number;
	isCommissioner: boolean;
	leagueId: number;
	isRanking?: boolean;
}

interface ILadderListItemProps {
	ladder: IRankingsItem;
	isRanking: boolean;
	hasRoundId: boolean;
	handleChecked: (e: React.SyntheticEvent<HTMLInputElement>) => void;
}

const LadderListItem: React.FC<ILadderListItemProps> = ({ladder}) => {
	const dispatch = useDispatch();
	const tipsOpen = useSelector(getViewingUserTips);
	const roundID = useSelector(getSelectedRoundID);
	const isShowOvr = useSelector(getIsShowByOverall);

	const user = useSelector(getUser);

	const {userId} = ladder;
	// User Tips pass into tips row component
	// squad and isCorrect into tipItem, use matchID to get home and away Teams
	const tipsByUser = useSelector(getOtherUserTips)(userId);

	const isThisTipsOpen = tipsOpen.includes(userId);
	const is_own = isOwn(ladder, user?.id);
	const teamName = get(ladder, "teamName", "");
	const firstName = get(ladder, "firstName", "");
	const lastInitial = get(ladder, "lastNameInitial", "");
	const totalMargin: string = String(get(ladder, "totalMargin", 0));
	const weekMargin: string = String(get(ladder, "margin", 0));

	const marginField = isShowOvr ? totalMargin : weekMargin;

	const handleViewTips = () => {
		if (isThisTipsOpen) {
			dispatch(removeViewUserPicks({team_id: userId}));
			return;
		}
		dispatch(fetchOtherUserTips({roundId: roundID, userId: userId}));
		dispatch(viewUserPicks({team_id: userId}));
	};

	return (
		<React.Fragment>
			<TeamRow isUser={is_own} isOpen={isThisTipsOpen}>
				<PositionItem>{ladder.rank}</PositionItem>
				<UserNameItem isShowOvr={isShowOvr}>
					{teamName}{" "}
					<span>
						{firstName} {lastInitial}
					</span>
				</UserNameItem>
				<Exist when={!isShowOvr}>
					<PointsItem>
						{ladder.points} <span>{`(${weekMargin})`}</span>{" "}
					</PointsItem>
				</Exist>
				<PointsItem>
					{ladder.totalPoints} <span>{`(${totalMargin})`}</span>
				</PointsItem>
				<MarginItem>{marginField}</MarginItem>
				{!isShowOvr && (
					<TipViewItem isOpen={isThisTipsOpen} onClick={handleViewTips}>
						<IconArrowRight />
					</TipViewItem>
				)}
			</TeamRow>
			<Exist when={isThisTipsOpen}>
				<TipRow tips={tipsByUser} roundID={roundID} onClose={handleViewTips} />
			</Exist>
		</React.Fragment>
	);
};

export const LadderList: React.FC<IProps> = ({
	roundId,
	isCommissioner,
	leagueId,
	isRanking = false,
}) => {
	const dispatch = useDispatch();
	const isMobile = useMediaQuery("(max-width: 640px)");
	const isShowOvr = useSelector(getIsShowByOverall);
	const showViewTips = isMobile && !isShowOvr;
	const selectedRound = useSelector(getRoundByIdSelector)(roundId);
	const displayWeek = get(selectedRound, "number", 0);

	useEffect(() => {
		dispatch(clearViewUserPicks());
	}, [dispatch]);
	const league_ladder_store = useSelector(isRanking ? getRankingsStore : getLeaguesLadderStore);

	const ladderToUse = isEmpty(league_ladder_store.items) ? [] : league_ladder_store.items;

	const [idsForRemove, setIdsForRemove] = useState(initialState);

	const handleChecked = useCallback(
		(e: React.SyntheticEvent<HTMLInputElement>) => {
			const {value} = e.currentTarget;
			setIdsForRemove([...xor(idsForRemove, [Number(value)])]);
		},
		[idsForRemove]
	);

	const hasLadderItems = Boolean(ladderToUse.length);

	const hasRoundId = Boolean(roundId);
	const positionTitle = isMobile ? "" : "Position";
	const userNameTitle = isMobile ? "Tipper" : "Username / Tipper";
	const weekTitle = isMobile ? `Week ${displayWeek}` : "WEEK PTS";
	const pointTitle = (title: string) => {
		return isMobile ? title.split(" ")[0] : title;
	};

	return (
		<Wrapper>
			<ScrolledWrapper>
				<ListHeader>
					<PositionHead>{positionTitle}</PositionHead>
					<UserNameHead isShowOvr={isShowOvr}>{userNameTitle}</UserNameHead>
					<Exist when={!isShowOvr}>
						<RoundHead>
							{weekTitle} <span>(margin)</span>
						</RoundHead>
					</Exist>
					<RoundHead>
						{pointTitle("Total Pts")}
						<span>(margin)</span>
					</RoundHead>
					<MarginHead>MARGIN</MarginHead>
					<TipViewHead>{showViewTips ? "View" : ""}</TipViewHead>
				</ListHeader>
				{hasLadderItems ? (
					ladderToUse.map((ladder) => {
						const key = ladder.rank;
						return (
							<LadderListItem
								key={key}
								ladder={ladder}
								isRanking={isRanking}
								hasRoundId={hasRoundId}
								handleChecked={handleChecked}
							/>
						);
					})
				) : (
					<EmptyText>
						{league_ladder_store.is_data_requested ? <LoaderBar /> : "No results"}
					</EmptyText>
				)}
			</ScrolledWrapper>
		</Wrapper>
	);
};
