import React from "react";
import styled from "styled-components";
import {Input} from "components";
import {debounce} from "lodash";

const InputWrapper = styled.div`
	width: 100%;
	margin: 0 auto;
`;

type IFunc<TP1, TR> = (param1: TP1) => TR;

interface IProps {
	search_placeholder?: string;
	defaultValue?: string;
	onSearch: IFunc<string, void>;
	wait?: number;
}

const default_wait = 1000;

export const SearchField = ({
	onSearch,
	search_placeholder = "",
	wait = default_wait,
	defaultValue = "",
}: IProps) => {
	const onSearchDebounced = debounce(onSearch, wait);

	const onChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value || "";
		onSearchDebounced(value);
	};

	return (
		<InputWrapper>
			<Input
				type="text"
				onChange={onChange}
				placeholder={search_placeholder}
				defaultValue={defaultValue}
			/>
		</InputWrapper>
	);
};
