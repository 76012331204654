import styled from "styled-components";

interface ICell {
	flex?: string;
	justify?: string;
	background?: string;
}

export const Row = styled.div<ICell>`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	background: ${(props) => props.background || "transparent"};
`;

export const CellStyled = styled.div<ICell>`
	flex: ${(props) => props.flex || "1"};
	height: 100%;
	display: flex;
	font-size: 12px;
	color: #000;
	align-items: center;
	justify-content: ${(props) => props.justify || "center"};
	background: ${(props) => props.background || "#fff"};
	border: 1px solid #eaeaea;
	padding: 0 10px;
	white-space: nowrap;

	@media print {
		background: none;
	}
`;

export const ListHeader = styled.div`
	height: 40px;
	background: #000;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;

	@media (min-width: 640px) {
		border-radius: 4px 4px 0 0;
	}

	${CellStyled} {
		color: #ffffff;
		background: #000;
		text-align: center;
		font-family: "InterFace Corp", sans-serif;
		font-size: 14px;
		font-weight: bold;
		line-height: 17px;
		text-transform: uppercase;
		border: 0;
	}
`;
