import React from "react";
import styled from "styled-components";

const mapDirectionToDegrees = {
	top: 270,
	right: 0,
	bottom: 90,
	left: 180,
};

type Direction = "top" | "bottom" | "left" | "right";

interface IPropSide {
	to: Direction;
}

interface IProps {
	width?: number;
	height?: number;
	color: string;
	to: Direction;
}

const SvgContainer = styled.svg`
	transform: rotate(${({to}: IPropSide) => mapDirectionToDegrees[to]}deg);
`;

export const IconArrowSlim: React.FC<IProps> = ({width, height, to, color, ...rest}) => (
	<SvgContainer
		to={to}
		width={`${width || 0}px`}
		height={`${height || 0}px`}
		viewBox={`0 0 9 14`}
		{...rest}>
		<title>Arrow Slim</title>
		<g transform="translate(-8.000000, -5.000000)">
			<polygon
				fill={color}
				points="10.293 18.707 8.879 17.293 14.172 12 8.879 6.707 10.293 5.293 17 12"
			/>
		</g>
	</SvgContainer>
);

IconArrowSlim.defaultProps = {
	width: 9,
	height: 14,
	color: "#9E9E9E",
	to: "top",
};
