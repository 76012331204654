import React from "react";
import styled from "styled-components";
import {Snapshot} from "components/GameBar/Snapshot";
import {useSelectedRound} from "modules/utils/hooks";

const GameBarContainer = styled.header`
	max-width: 1200px;
	border-radius: 4px;
	margin: 20px auto;
	background: White;
	color: #000;
	box-sizing: border-box;
	position: relative;

	@media (max-width: 639px) {
		flex-wrap: wrap;
		padding: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		background: transparent;
	}
`;

export const GameBar: React.FC = () => {
	const selectedRound = useSelectedRound();

	if (!selectedRound) {
		return null;
	}

	return (
		<GameBarContainer>
			<Snapshot />
		</GameBarContainer>
	);
};
