import {createReducer} from "redux-act";
import {get} from "lodash";
import {
	clearLadder,
	fetchLadderSuccess,
	fetchRoundsSuccess,
	fetchRoundStatsSuccess,
	fetchSquadsSuccess,
	fetchStatesSuccess,
	ILadder,
	IRound,
	fetchSelectedRoundSuccess,
	changeSelectedRound,
	IMRECSuccess,
	fetchMRECSuccess,
	INotificationBar,
	fetchNotificationBarSuccess,
	fetchVenuesSuccess,
	IFormGuide,
	fetchFormGuideSuccess,
} from "modules/actions";
import {IDictionary, IRoundStats, ISquad, IVenueElement, TState} from "modules/types";

export interface IJsons {
	states: IDictionary<TState>;
	squads: ISquad[];
	rounds: IRound[];
	ladder: ILadder[];
	round_stats: IRoundStats[];
	selected_round: number;
	mrec: IMRECSuccess[];
	notification_bar: INotificationBar[];
	venues: IVenueElement[];
	formguide: IDictionary<IFormGuide>;
}

const defaultMREC = [
	{
		sport: "wbbl",
		type: "leaderboard",
		desktopImage: "",
		mobileImage: "",
		url: "",
	},
	{
		sport: "wbbl",
		type: "mrec",
		desktopImage: "",
		mobileImage: "",
		url: "",
	},
];

const defaultNotification = [
	{
		sport: "bbl",
		isEnabled: false,
		message: "",
	},
	{
		sport: "wbbl",
		isEnabled: false,
		message: "",
	},
];

const initialState: IJsons = {
	states: {},
	squads: [],
	rounds: [],
	ladder: [],
	round_stats: [],
	selected_round: 0,
	mrec: defaultMREC,
	notification_bar: defaultNotification,
	venues: [],
	formguide: {},
};

export const jsons = createReducer<IJsons>({}, initialState)
	.on(fetchStatesSuccess, (state, payload) => ({
		...state,
		states: payload,
	}))
	.on(fetchSquadsSuccess, (state, payload) => ({
		...state,
		squads: payload,
	}))
	.on(fetchRoundsSuccess, (state, roundsList) => {
		const firstActive = roundsList.find(
			(round) => round.status === "active" || round.status === "scheduled"
		);
		if (!firstActive) {
			const length = roundsList.length;
			const lastRound = roundsList[length - 1];
			const selectedRound = get(lastRound, "id", 0);

			return {
				...state,
				rounds: roundsList,
				selected_round: selectedRound,
			};
		}
		const roundID = get(firstActive, "id", 1);
		return {
			...state,
			rounds: roundsList,
			selected_round: roundID,
		};
	})
	.on(fetchLadderSuccess, (state, payload) => ({
		...state,
		ladder: payload,
	}))
	.on(clearLadder, (state) => ({
		...state,
		ladder: [],
	}))
	.on(fetchRoundStatsSuccess, (state, payload) => ({
		...state,
		round_stats: payload,
	}))
	.on(fetchSelectedRoundSuccess, (state, payload) => ({
		...state,
		selected_round: payload.round,
	}))
	.on(changeSelectedRound, (state, payload) => ({
		...state,
		selected_round: payload.round,
	}))
	.on(fetchMRECSuccess, (state, payload) => ({
		...state,
		mrec: payload,
	}))
	.on(fetchNotificationBarSuccess, (state, payload) => ({
		...state,
		notification_bar: payload,
	}))
	.on(fetchVenuesSuccess, (state, payload) => ({
		...state,
		venues: payload,
	}))
	.on(fetchFormGuideSuccess, (state, payload) => ({
		...state,
		formguide: payload,
	}));
