import React from "react";
import {IIconProps} from "modules/types";

export const IconArrowRight: React.FC<IIconProps> = ({title, width, height, color, onClick}) => (
	<svg width={`${width || 0}px`} height={`${height || 0}px`} onClick={onClick} viewBox="0 0 9 14">
		<title>{title}</title>
		<g transform="translate(-8.000000, -5.000000)">
			<polygon
				fill={color}
				points="10.293 18.707 8.879 17.293 14.172 12 8.879 6.707 10.293 5.293 17 12"
			/>
		</g>
	</svg>
);

IconArrowRight.defaultProps = {
	title: "Cross",
	width: 9,
	height: 14,
	color: "#010101",
};
