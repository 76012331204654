import {IError} from "modules/types";
import {createReducer} from "redux-act";
import {IS_SECRET} from "modules/utils/constants";
import {LoadingStatus} from "modules/utils/enums";
import {
	clearContact,
	clearPageHeader,
	clearUserError,
	hideUserAlert,
	passwordResetClearForm,
	postContact,
	postContactSuccess,
	requestPWDResetFailed,
	requestPWDResetSuccess,
	setPageHeader,
	setUserError,
	showUserAlert,
	updateSecret,
	userFetch,
	userFetchFailed,
	userFetchSuccess,
	userLogin,
	userLoginFailed,
	userLoginSuccess,
	userLoginSSOSuccess,
	userLogout,
	// userLogoutSuccess,
	userRegistration,
	userRegistrationFailed,
	userRegistrationSuccess,
	userUpdate,
	userUpdateSuccess,
	userUpdateReset,
	fetchUserCountrySuccess,
	userRegisterForGameSuccess,
	userUpdateFailed,
	userPreregister,
	userPreregisterSuccess,
	userPreregisterFailed,
	recoverUserForGameSuccess,
} from "modules/actions";

export interface IUser {
	id: number;
	team_name: string | null;
	team_supported: number;
	email: string;
	cricket_id: string;
	disabledNotifications: boolean;
	isWeeklyReminderEnabled: boolean;
	roles: string[];
}

export interface IUserReducer {
	isSessionChecked: boolean;
	isAuthorized: boolean;
	isDataRequested: boolean;
	isRequestInProgress: boolean;
	user: IUser;
	error?: string;
	code_error?: number;
	param_error?: string;
	resetPassword: {
		success?: boolean;
		error?: string;
	};
	contact: {
		inProgress: boolean;
		submitted: boolean;
	};
	alert: {
		isShow: boolean;
		text?: string;
	};
	pageHeader: string;
	isSecretEnabled: boolean;
	state: string | boolean;
	userLoginStatus: LoadingStatus;
	userUpdateStatus: LoadingStatus;
	preregister: {
		message: string;
	};
}

const defaultState = {
	isSessionChecked: false,
	isAuthorized: false,
	isDataRequested: false,
	isRequestInProgress: false,
	resetPassword: {},
	contact: {
		inProgress: false,
		submitted: false,
	},
	user: {
		id: 0,
		team_name: null,
		team_supported: 0,
		email: "",
		cricket_id: "",
		disabledNotifications: false,
		isWeeklyReminderEnabled: true,
		roles: [],
	},
	alert: {
		isShow: false,
		text: "",
	},
	pageHeader: "",
	isSecretEnabled: IS_SECRET,
	state: "",
	userLoginStatus: LoadingStatus.Idle,
	userUpdateStatus: LoadingStatus.Idle,
	preregister: {
		message: "",
	},
};
/*
const postDefaultState = {
	...defaultState,
	isSessionChecked: true,
};
*/

const onRequestUser = (state: IUserReducer) => ({
	...state,
	isDataRequested: true,
});

const onRequestUserLogin = (state: IUserReducer) => ({
	...state,
	isDataRequested: true,
	userLoginStatus: LoadingStatus.Loading,
});

const onUserRegistrationSuccess = (state: IUserReducer, payload: IUser) => ({
	...state,
	isAuthorized: true,
	userLoginStatus: LoadingStatus.Success,
	isDataRequested: false,
	isRequestInProgress: false,
	user: payload,
});

const onUserReceived = (state: IUserReducer, payload: IUser) => ({
	...state,
	isAuthorized: true,
	userLoginStatus: LoadingStatus.Success,
	isSessionChecked: true,
	isDataRequested: false,
	user: payload,
});

const onUserUpdated = (state: IUserReducer, payload: IUser) => ({
	...state,
	isAuthorized: true,
	isDataRequested: false,
	user: payload,
	userUpdateStatus: LoadingStatus.Success,
});

const onError = (state: IUserReducer, error: IError) => {
	return {
		...state,
		isRequestInProgress: false,
		code_error: error.code,
		param_error: error.param,
		error: error.message,
	};
};

export const user = createReducer<IUserReducer>({}, defaultState)
	.on(userFetch, onRequestUser)

	.on(userLogout, (state) => ({
		...state,
		userLoginStatus: LoadingStatus.Idle,
	}))
	.on(userLogin, onRequestUserLogin)
	.on(userLoginSuccess, onUserReceived)
	.on(userLoginSSOSuccess, onUserReceived)
	.on(userLoginFailed, onError)

	.on(userFetchSuccess, onUserReceived)
	.on(userFetchFailed, (state) => ({
		...state,
		isDataRequested: false,
		isSessionChecked: true,
		isAuthorized: false,
	}))
	.on(userRegistration, (state) => ({
		...state,
		isRequestInProgress: true,
	}))
	.on(userRegistrationSuccess, onUserRegistrationSuccess)
	.on(userRegistrationFailed, onError)
	.on(userRegisterForGameSuccess, onUserRegistrationSuccess)
	.on(recoverUserForGameSuccess, onUserRegistrationSuccess)
	.on(userUpdate, (state) => ({
		...state,
		userUpdateStatus: LoadingStatus.Loading,
	}))
	.on(userUpdateSuccess, onUserUpdated)
	.on(userUpdateReset, (state) => ({
		...state,
		userUpdateStatus: LoadingStatus.Idle,
	}))
	.on(userUpdateFailed, (state) => ({
		...state,
		userUpdateStatus: LoadingStatus.Idle,
	}))
	.on(clearUserError, (state) => ({
		...state,
		error: undefined,
		code_error: undefined,
		param_error: undefined,
	}))
	.on(setUserError, onError)
	.on(passwordResetClearForm, (state) => ({
		...state,
		resetPassword: {},
	}))
	.on(requestPWDResetSuccess, (state) => ({
		...state,
		resetPassword: {
			success: true,
		},
	}))
	.on(requestPWDResetFailed, (state, err) => ({
		...state,
		resetPassword: {
			success: false,
			error: err.message,
		},
	}))
	.on(postContact, (state) => ({
		...state,
		contact: {
			...state.contact,
			inProgress: true,
			submitted: false,
		},
	}))
	.on(postContactSuccess, (state) => ({
		...state,
		contact: {
			...state.contact,
			inProgress: false,
			submitted: true,
		},
	}))
	.on(clearContact, (state) => ({
		...state,
		contact: {
			...state.contact,
			inProgress: false,
			submitted: false,
		},
	}))
	.on(showUserAlert, (state, alert) => ({
		...state,
		alert: {
			...state.alert,
			isShow: true,
			text: alert.text,
		},
	}))
	.on(hideUserAlert, (state) => ({
		...state,
		alert: {
			...state.alert,
			isShow: false,
			text: "",
		},
	}))
	.on(setPageHeader, (state, payload) => ({
		...state,
		pageHeader: payload,
	}))
	.on(clearPageHeader, (state) => ({
		...state,
		pageHeader: "",
	}))
	.on(updateSecret, (state) => ({
		...state,
		isSecretEnabled: false,
	}))
	.on(userPreregister, (state) => ({
		...state,
		preregister: {
			message: "",
		},
	}))
	.on(userPreregisterSuccess, (state, payload) => ({
		...state,
		preregister: {
			message: payload.message,
		},
	}))
	.on(userPreregisterFailed, (state, payload) => ({
		...state,
		error: payload.message,
	}))
	.on(fetchUserCountrySuccess, (state, payload) => ({
		...state,
		state: payload,
	}));
