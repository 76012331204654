import styled from "styled-components";

export const Textarea = styled.textarea`
	width: 100%;
	min-height: 165px;
	margin-bottom: 10px;
	padding: 20px;
	box-sizing: border-box;
	border: 1px solid #000000;
	border-radius: 4px;
	color: #000000;
	font-family: Arial;
	font-size: 14px;
	letter-spacing: 0;
	line-height: 20px;

	&:disabled {
		opacity: 0.6;
	}

	&::placeholder,
	&::-ms-input-placeholder,
	&:-ms-input-placeholder {
		color: #000 !important;
	}

	&.error {
		color: #dc1515;
		border: 2px solid #dc1515;
	}
`;
