import {
	IContactPayload,
	IPWDResetParams,
	IPWDResetRequestParams,
	ISagaAction,
	IUserCommunicationsUpdatePayload,
	IUserLoginPayload,
	IUserLoginSSOPayload,
	IUserPasswordUpdatePayload,
	IUserPreregisterPayload,
	IUserRecoverForGamePayload,
	IUserRegisterForGamePayload,
	IUserRegistrationPayload,
	IUserRegistrationSSOPayload,
	IUserUpdatePayload,
} from "modules/types";
import {call, put, delay} from "typed-redux-saga/macro";
import {
	Api,
	ApiError,
	REACT_APP_LOGOUT_SSO_LINK,
	REACT_APP_RETURN_URL,
	REACT_APP_SSO_API_KEY,
	REACT_APP_SSO_API_KEY_CA_LIVE_APP,
	Storage,
	User,
} from "modules/utils";
import {
	hideConfirmModal,
	setUserError,
	userFetchFailed,
	userFetchSuccess,
	userLoginFailed,
	userLoginSuccess,
	userLogoutFailed,
	userLogoutSuccess,
	userRegistrationFailed,
	userRegistrationSuccess,
	userUpdateFailed,
	userUpdateSuccess,
	userUpdateReset,
	postPWDResetRequestSuccess,
	postPWDResetRequestFailed,
	requestPWDResetFailed,
	requestPWDResetSuccess,
	postContactFailed,
	postContactSuccess,
	showUserAlert,
	hideUserAlert,
	fetchUserCountrySuccess,
	userLoginSSOFailed,
	userLoginSSOSuccess,
	userRegistrationSSOFailed,
	userRegistrationSSOSuccess,
	userRegisterForGameSuccess,
	userRegisterForGameFailed,
	showGlobalError,
	userPreregisterSuccess,
	recoverUserForGameFailed,
	recoverUserForGameSuccess,
} from "modules/actions";
import {get} from "lodash";
import {ConnextraType, createConnextraScriptTag} from "modules/utils/connextra";

export const userLoginSaga = function* ({payload}: ISagaAction<IUserLoginPayload>) {
	try {
		const response = yield* call(Api.Auth.login, payload);
		ApiError.CHECK(response);
		User.SAVE(response.success.user);
		yield* put(userLoginSuccess(response.success.user));
	} catch (e: unknown) {
		const message = get(e, "message", "");
		yield* put(
			userLoginFailed({
				message,
			})
		);
	}
};

export const userPreregistrationSaga = function* ({payload}: ISagaAction<IUserPreregisterPayload>) {
	try {
		const response = yield* call(Api.Auth.preregister, payload);
		if (!response.errors.length) {
			yield* put(userPreregisterSuccess({message: "Successfully Pre-registered"}));
		}
	} catch (e) {
		// yield* put(setUserError(e));
		const message = get(e, "message", "");
		yield* put(userRegistrationFailed({message}));
	}
};

export const userRegistrationSaga = function* ({payload}: ISagaAction<IUserRegistrationPayload>) {
	try {
		const response = yield* call(Api.Auth.register, payload);
		ApiError.CHECK(response);
		User.SAVE(response.success.user);
		yield* put(userRegistrationSuccess(response.success.user));
	} catch (e) {
		// yield* put(setUserError(e));
		const message = get(e, "message", "");
		yield* put(userRegistrationFailed({message}));
	}
};

export const userLoginSSOSaga = function* ({payload}: ISagaAction<IUserLoginSSOPayload>) {
	try {
		const response = yield* call(Api.Auth.login_sso, payload);
		ApiError.CHECK(response);
		User.SAVE(response.success.user);
		const decodedCricketId = atob(response.success.user.cricket_id);
		window.dataLayer?.push({
			cricket_id: decodedCricketId,
		});
		yield* put(userLoginSSOSuccess(response.success.user));
	} catch (e) {
		const message = get(e, "message", "");
		yield* put(userLoginSSOFailed({message}));
	}
};

export const userRegistrationSSOSaga = function* ({
	payload,
}: ISagaAction<IUserRegistrationSSOPayload>) {
	try {
		const response = yield* call(Api.Auth.register_sso, payload);
		ApiError.CHECK(response);
		User.SAVE(response.success.user);
		yield* put(userRegistrationSSOSuccess(response.success.user));
	} catch (e) {
		// yield* put(setUserError(e));
		const message = get(e, "message", "");
		yield* put(userRegistrationSSOFailed({message}));
	}
};

export const userRegisterForGameSaga = function* ({
	payload,
}: ISagaAction<IUserRegisterForGamePayload>) {
	try {
		const response = yield* call(Api.Auth.register_for_game, payload);
		ApiError.CHECK(response);
		User.SAVE(response.success.user);
		yield* put(userRegisterForGameSuccess(response.success.user));
		const sport = Storage.GET("sport_select") || "";
		if (sport === "bbl") {
			createConnextraScriptTag(ConnextraType.BBL_REGISTRATION_CONFIRM, response.success.user);
		} else {
			createConnextraScriptTag(
				ConnextraType.WBBL_REGISTRATION_CONFIRM,
				response.success.user
			);
		}
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "Tipping Registration",
			action: "Registration Complete",
			label: undefined, // set to JavaScript undefined
		});
	} catch (e: unknown) {
		// yield* put(setUserError(e));
		const message = get(e, "message", "");
		yield* put(showGlobalError({message}));
		yield* put(userRegisterForGameFailed({message}));
	}
};

export const recoverUserForGameSaga = function* ({
	payload,
}: ISagaAction<IUserRecoverForGamePayload>) {
	try {
		const response = yield* call(Api.Auth.recover_user, payload);
		ApiError.CHECK(response);
		User.SAVE(response.success.user);
		yield* put(recoverUserForGameSuccess(response.success.user));
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "Tipping Recover",
			action: "Recover Complete",
			label: undefined, // set to JavaScript undefined
		});
	} catch (err: unknown) {
		const message = get(err, "message", "");
		yield* put(showGlobalError({message}));
		yield* put(recoverUserForGameFailed({message}));
	}
};

export const userFetchSaga = function* () {
	try {
		const response = yield* call(Api.User.show_my);

		ApiError.CHECK(response);
		User.SAVE(response.success.user);

		yield* put(userFetchSuccess(response.success.user));
	} catch (e) {
		const message = get(e, "message", "");
		yield* put(userFetchFailed({message}));
	}
};

const LOGOUT_LINK = REACT_APP_LOGOUT_SSO_LINK;

export const userLogoutSaga = function* () {
	try {
		const response = yield* call(Api.Auth.logout);
		ApiError.CHECK(response);
		User.CLEAR();
		const isAppUser = Storage.GET("is_app_user") === "true";
		const extraPath = isAppUser ? "login-app" : "";
		const keyToUse = isAppUser ? REACT_APP_SSO_API_KEY_CA_LIVE_APP : REACT_APP_SSO_API_KEY;
		yield* put(userLogoutSuccess());
		const params = [`apiKey=${keyToUse}`, `redirect-uri=${REACT_APP_RETURN_URL}`];
		const paramString = `${params.join("&")}${extraPath}`;
		const reactAppLogoutLink = `${LOGOUT_LINK}${paramString}`;
		window.location.assign(reactAppLogoutLink);
	} catch (e) {
		const message = get(e, "message", "");
		yield* put(userLogoutFailed(message));
	}
};

export const userUpdateSaga = function* ({
	payload,
}: ISagaAction<
	IUserUpdatePayload | IUserPasswordUpdatePayload | IUserCommunicationsUpdatePayload
>) {
	try {
		const {successMsg = "", ...data} = payload;
		const response = yield* call(Api.User.update, data);
		ApiError.CHECK(response);
		yield* put(userUpdateSuccess(response.success.user));
		if (successMsg) {
			yield* put(showUserAlert({text: successMsg}));
			yield* delay(3000);
			yield* put(hideUserAlert());
			yield* put(userUpdateReset());
		}
	} catch (e) {
		const message = get(e, "message", "");
		yield* put(showGlobalError(e));
		yield* put(setUserError({message}));
		yield* put(userUpdateFailed({message}));
	}
};

export const postPWDResetRequestSaga = function* ({payload}: ISagaAction<IPWDResetRequestParams>) {
	try {
		yield* call(Api.Auth.password_reset_request, payload);
		yield* put(postPWDResetRequestSuccess());
	} catch (e) {
		const message = get(e, "message", "");
		yield* put(postPWDResetRequestFailed({message}));
	}
};

export const requestPWDResetSaga = function* ({payload}: ISagaAction<IPWDResetParams>) {
	try {
		yield* call(Api.Auth.password_reset, payload);
		yield* put(requestPWDResetSuccess());
	} catch (e) {
		const message = get(e, "message", "");
		yield* put(requestPWDResetFailed({message}));
	}
};

export const postDeactivateUserSaga = function* () {
	try {
		const response = yield* call(Api.User.deactivate);
		ApiError.CHECK(response);
		User.CLEAR();
		yield* put(userLogoutSuccess());
		yield* put(hideConfirmModal());
	} catch (e) {
		const message = get(e, "message", "");
		yield* put(userLogoutFailed(message));
	}
};
export const postContactSaga = function* ({payload}: ISagaAction<IContactPayload>) {
	try {
		yield* call(Api.Contact.contact, payload);
		yield* put(postContactSuccess());
	} catch (e) {
		const message = get(e, "message", "");
		yield* put(postContactFailed({message}));
	}
};

export const fetchUserCountrySaga = function* () {
	try {
		const response = yield* call(Api.geo_ip.country);
		ApiError.CHECK(response);
		yield* put(fetchUserCountrySuccess(response.success.isoCode));
	} catch (e) {
		const message = get(e, "message", "");
		yield* put(setUserError({message}));
	}
};
