import {createReducer} from "redux-act";
import * as actions from "modules/actions";
import {IDeleteUserPayload, IJoinedUsersSuccess} from "modules/types";
import {LeagueStatus} from "./status";
import {LoadingStatus, PrivacyStatus} from "modules/utils";

export interface ILeague {
	id: number;
	name: string;
	userId: number;
	code: string;
	privacy: string;
	status: LeagueStatus;
	class: string;
	description: string;
	numUser: number;
	startRound: number;
}

export interface IFetchLeagueResponse {
	league: ILeague;
	isJoined: boolean;
}

export interface IJoinedUser {
	firstName: number;
	id: number;
	ladderPos: string;
	teamName: string;
	lastName: string;
}

export interface IRemoveLeaguePayload {
	leagueID: number;
}

export interface ILeaguesReducer {
	league: ILeague;
	leagues: ILeague[];
	joined_users: IJoinedUser[];
	create_league_process: boolean;
	league_created: boolean;
	update_league_process: boolean;
	errors: string;
	invites_sent: boolean;
	league_updated: boolean;
	isUserLeftLeague: boolean;
	leave_league_process: boolean;
	send_email_process: boolean;
	blurPage: boolean;
	isJoined?: boolean;
	league_loading_status: LoadingStatus;
	next_joined_users: boolean;
}

const initialState: ILeaguesReducer = {
	league: {
		userId: 0,
		description: "",
		id: 0,
		name: "",
		status: LeagueStatus.Scheduled,
		class: "",
		startRound: 0,
		privacy: PrivacyStatus.Public,
		code: "",
		numUser: 0,
	},
	leagues: [],
	joined_users: [],
	next_joined_users: false,
	create_league_process: false,
	league_created: false,
	update_league_process: false,
	league_updated: false,
	errors: "",
	invites_sent: false,
	isUserLeftLeague: false,
	leave_league_process: false,
	send_email_process: false,
	blurPage: false,
	isJoined: false,
	league_loading_status: LoadingStatus.Idle,
};

const resetLeagueProcess = (state: ILeaguesReducer, payload: IRemoveLeaguePayload) => {
	const handledLeagues = state.leagues.filter((league) => league.id !== payload.leagueID);
	return {
		...state,
		leave_league_process: false,
		isUserLeftLeague: true,
		leagues: handledLeagues,
	};
};

const handleErrors = (state: ILeaguesReducer, payload: ILeaguesReducer["errors"]) => ({
	...state,
	leave_league_process: false,
	errors: payload,
});

export const leagues = createReducer<ILeaguesReducer>({}, initialState)
	.on(actions.setLeagueStatusIdle, (state) => ({
		...state,
		league_loading_status: LoadingStatus.Idle,
		league_created: false,
	}))
	.on(actions.postCreateLeague, (state) => ({
		...state,
		create_league_process: true,
		league_created: true,
	}))
	.on(actions.postCreateLeagueSuccess, (state, payload: ILeague) => ({
		...state,
		league: payload,
		create_league_process: false,
		errors: "",
	}))
	.on(actions.postCreateLeagueFailed, (state, payload: string) => ({
		...state,
		create_league_process: false,
		errors: payload,
	}))
	.on(actions.fetchLeagueSuccess, (state, payload: IFetchLeagueResponse) => ({
		...state,
		...payload,
		errors: "",
	}))
	.on(actions.postInviteSuccess, (state) => ({
		...state,
		invites_sent: true,
	}))
	.on(actions.postInviteReset, (state) => ({
		...state,
		invites_sent: false,
	}))
	.on(actions.postUpdateLeague, (state) => ({
		...state,
		update_league_process: true,
	}))
	.on(actions.postUpdateLeagueSuccess, (state, payload: ILeague) => ({
		...state,
		league: payload,
		update_league_process: false,
		league_updated: true,
		errors: "",
	}))
	.on(actions.postUpdateLeagueFailed, (state, payload: string) => ({
		...state,
		update_league_process: false,
		errors: payload,
	}))
	.on(actions.postUpdateLeagueReset, (state) => ({
		...state,
		league_updated: false,
	}))
	.on(actions.postLeaveLeague, (state) => ({
		...state,
		leave_league_process: true,
	}))
	.on(actions.postLeaveLeagueSuccess, resetLeagueProcess)
	.on(actions.postLeaveLeagueFailed, handleErrors)
	.on(actions.postDeleteLeague, (state) => ({
		...state,
		leave_league_process: true,
	}))
	.on(actions.postDeleteLeagueSuccess, resetLeagueProcess)
	.on(actions.postDeleteLeagueFailed, handleErrors)
	.on(actions.fetchShowMyLeaguesSuccess, (state, payload) => ({
		...state,
		leagues: payload,
	}))
	.on(actions.clearLeague, (state) => ({
		...state,
		league: {
			...initialState.league,
		},
		isUserLeftLeague: false,
		leave_league_process: false,
	}))
	.on(actions.fetchJoinedUsers, (state) => ({
		...state,
		league_loading_status: LoadingStatus.Loading,
	}))
	.on(actions.fetchJoinedUsersSuccess, (state, payload: IJoinedUsersSuccess) => ({
		...state,
		joined_users: payload.users,
		next_joined_users: payload.nextPage,
		league_loading_status: LoadingStatus.Success,
	}))
	.on(actions.fetchJoinedUsersMoreSuccess, (state, payload: IJoinedUsersSuccess) => ({
		...state,
		joined_users: state.joined_users.concat(payload.users),
		next_joined_users: payload.nextPage,
		league_loading_status: LoadingStatus.Success,
	}))
	.on(actions.postDeleteJoinedUserSuccess, (state, payload: IDeleteUserPayload) => ({
		...state,
		joined_users: state.joined_users.filter((user) => {
			return user.id !== payload.user_id;
		}),
	}))
	.on(actions.postSendEmails, (state) => ({
		...state,
		send_email_process: true,
	}))
	.on(actions.postSendEmailsSuccess, (state) => ({
		...state,
		send_email_process: false,
	}))
	.on(actions.toggleBlurPage, (state) => ({
		...state,
		blurPage: !state.blurPage,
	}))
	.on(actions.setBlurPage, (state, payload) => ({
		...state,
		blurPage: payload,
	}))
	.on(actions.clearIsUserLeftLeague, (state) => ({
		...state,
		isUserLeftLeague: false,
	}));
