import {
	ICreateLeaguePayload,
	IInviteEmails,
	ILeagueEntryActionsPayload,
	ISagaAction,
	IUpdateLeaguePayload,
	IJoinedUsersPayload,
	IDeleteUserPayload,
	IDeleteUsersPayload,
	IDeleteLeaguePayload,
	IEditUserPayload,
	ISendEmailsPayload,
} from "modules/types";
import {SagaIterator} from "redux-saga";
import {call, put, delay} from "typed-redux-saga/macro";
import {Api} from "modules/utils/Api";
import * as actions from "modules/actions";
import {ApiError} from "modules/utils";
import {get} from "lodash";

export const postCreateLeagueSaga = function* ({
	payload,
}: ISagaAction<ICreateLeaguePayload>): SagaIterator {
	try {
		const response = yield* call(Api.tipping_league.create, payload);
		ApiError.CHECK(response);

		// const league_id = get(response, "success.id");
		// if (league_id) {
		// 	yield* put(actions.fetchLeague({id: league_id}));
		// }
		return yield* put(actions.postCreateLeagueSuccess(response.success.league));
	} catch (err) {
		yield* put(actions.globalError(err));
	}
};
export const fetchLeagueSaga = function* ({payload}: ISagaAction<{id: number}>): SagaIterator {
	try {
		const response = yield* call(Api.tipping_league.show, payload);
		ApiError.CHECK(response);

		return yield* put(actions.fetchLeagueSuccess(response.success));
	} catch (err) {
		yield* put(actions.globalError(err));
	}
};
export const postInviteSaga = function* ({payload}: ISagaAction<IInviteEmails>): SagaIterator {
	try {
		const response = yield* call(Api.tipping_league.invite, payload);
		ApiError.CHECK(response);

		yield* put(actions.postInviteSuccess());
		yield* delay(2000);
		yield* put(actions.postInviteReset());
	} catch (err) {
		yield* put(actions.globalError(err));
	}
};

export const postUpdateLeagueSaga = function* ({
	payload,
}: ISagaAction<IUpdateLeaguePayload>): SagaIterator {
	try {
		const response = yield* call(Api.tipping_league.update, payload);
		ApiError.CHECK(response);

		yield* put(actions.postUpdateLeagueSuccess(response.success.league));
		yield* delay(2000);
		yield* put(actions.postUpdateLeagueReset());
	} catch (err) {
		yield* put(actions.globalError(err));
	}
};

export const postLeaveLeagueSaga = function* ({
	payload,
}: ISagaAction<ILeagueEntryActionsPayload>): SagaIterator {
	try {
		const response = yield* call(Api.tipping_league.leave, payload);
		ApiError.CHECK(response);

		yield* put(actions.postLeaveLeagueSuccess({leagueID: payload.league_id}));
	} catch (err) {
		yield* put(actions.globalError(err));
	}
};

export const postDeleteLeagueSaga = function* ({
	payload,
}: ISagaAction<IDeleteLeaguePayload>): SagaIterator {
	try {
		const response = yield* call(Api.tipping_league.delete, payload);
		ApiError.CHECK(response);

		yield* put(actions.postDeleteLeagueSuccess({leagueID: payload.id}));
	} catch (err) {
		yield* put(actions.globalError(err));
	}
};

export const fetchShowMyLeaguesSaga = function* (): SagaIterator {
	try {
		const response = yield* call(Api.tipping_league.show_my);
		ApiError.CHECK(response);
		return yield* put(actions.fetchShowMyLeaguesSuccess(response.success.leagues));
	} catch (err) {
		console.log(err);
		const message = get(err, "message", "");
		yield* put(
			actions.showGlobalError({
				message: message,
			})
		);
	}
};

export const fetchJoinedUsersSaga = function* ({
	payload,
}: ISagaAction<IJoinedUsersPayload>): SagaIterator {
	try {
		const response = yield* call(Api.tipping_join.show_joined_users, payload);
		ApiError.CHECK(response);

		if (payload.page !== 1) {
			return yield* put(actions.fetchJoinedUsersMoreSuccess(response.success));
		} else {
			return yield* put(actions.fetchJoinedUsersSuccess(response.success));
		}
	} catch (err) {
		console.log(err);
		// yield* put(
		// 	actions.showGlobalError({
		// 		message: err.message,
		// 	})
		// );
	}
};

export const postDeleteJoinedUserSaga = function* ({
	payload,
}: ISagaAction<IDeleteUserPayload>): SagaIterator {
	try {
		const response = yield* call(Api.tipping_join.remove, payload);
		ApiError.CHECK(response);

		return yield* put(actions.postDeleteJoinedUserSuccess(payload));
	} catch (err) {
		const message = get(err, "message", "");
		yield* put(
			actions.showGlobalError({
				message: message,
			})
		);
	}
};

export const postDeleteJoinedUsersSaga = function* ({
	payload,
}: ISagaAction<IDeleteUsersPayload>): SagaIterator {
	try {
		const response = yield* call(Api.tipping_join.remove_multi, payload);
		ApiError.CHECK(response);

		return yield* put(actions.postDeleteJoinedUsersSuccess(payload));
	} catch (err) {
		const message = get(err, "message", "");
		yield* put(
			actions.showGlobalError({
				message: message,
			})
		);
	}
};

export const postEditUserSaga = function* ({payload}: ISagaAction<IEditUserPayload>): SagaIterator {
	try {
		const response = yield* call(Api.tipping_join.edit_users, payload);
		ApiError.CHECK(response);
	} catch (err) {
		const message = get(err, "message", "");
		yield* put(
			actions.showGlobalError({
				message: message,
			})
		);
	}
};

export const postSendEmailsSaga = function* ({
	payload,
}: ISagaAction<ISendEmailsPayload>): SagaIterator {
	try {
		const response = yield* call(Api.tipping_league.send_emails, payload);
		ApiError.CHECK(response);

		return yield* put(actions.postSendEmailsSuccess());
	} catch (err) {
		const message = get(err, "message", "");
		yield* put(
			actions.showGlobalError({
				message: message,
			})
		);
	}
};
