import * as React from "react";
import styled from "styled-components";
const RadioStyled = styled.input`
	display: none;
`;

const RadioButtonWrapper = styled.div`
	position: relative;
	display: inline-flex;
	width: 100%;
	margin-bottom: 10px;
	&.disabled {
		&:after {
			content: "";
			background: #f7f7f7;
			position: absolute;
			width: 100%;
			height: 100%;
			opacity: 0.3;
		}
	}

	@media screen and (max-width: 840px) {
		width: 100%;
	}
`;

interface IRadioButtonStyled {
	readonly isFirst: boolean;
	readonly isLast: boolean;
}

const RadioButtonStyled = styled.label`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	background-color: #fff;
	color: #07a827;
	border: 1px solid #07a827;
	flex: 1 1 auto;
	cursor: pointer;
	min-width: 100px;
	width: 50%;
	font-size: 14px;
	font-weight: bold;
	text-transform: uppercase;

	@media screen and (max-width: 840px) {
		min-width: auto;
	}

	${({isFirst}: IRadioButtonStyled) => (isFirst ? "border-radius: 2px 0 0 2px;" : "")};

	${({isLast}: IRadioButtonStyled) => (isLast ? "border-radius: 0 2x 2px 0;" : "")};

	${RadioStyled}:checked + & {
		background-color: #07a827;
		color: #ffffff;
	}
`;

interface IOption {
	readonly value: number | string;
	readonly text: string;
}

interface IProps {
	readonly name: string;
	readonly disabled?: boolean;
	readonly options: IOption[];
	form_value: string;
	onChange: (e: React.SyntheticEvent<HTMLInputElement>) => void;
}
const compare = (value: string | number, to_compare: string | number) => value === to_compare;
const getClassName = (comparator: boolean) => (comparator ? "disabled" : "");
export const FormRadioButtons = ({name, options, disabled, form_value, onChange}: IProps) => (
	<RadioButtonWrapper className={getClassName(Boolean(disabled))}>
		{options
			? options.map(({value, text}, index) => (
					<React.Fragment key={index}>
						<RadioStyled
							type="radio"
							id={`${name}_${value}`}
							name={name}
							value={value}
							checked={compare(value, form_value)}
							onChange={onChange}
							disabled={disabled}
						/>
						<RadioButtonStyled
							htmlFor={`${name}_${value}`}
							isFirst={compare(index, 0)}
							isLast={compare(index, options.length - 1)}>
							{text}
						</RadioButtonStyled>
					</React.Fragment>
			  ))
			: null}
	</RadioButtonWrapper>
);
