import React from "react";
import styled from "styled-components";
import {useDispatch} from "react-redux";
import {RemoveLeaveLeagueModal} from "components/League/RemoveLeaveLeagueModal";
import {showConfirmModal} from "modules/actions/modals";
import {theme} from "assets/themes/bbl";
import {ILeague} from "modules/types";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 60px;
	@media screen and (max-width: 639px) {
		margin-top: 20px;
	}
`;

const Heading = styled.h1`
	// font-family: ${theme.font.montserrat};
	font-family: Halisa;
	font-weight: 800;
	font-size: 12px;
	color: ${theme.colors.red};
	line-height: 16px;
	margin-bottom: 18px;
	text-transform: uppercase;
`;

const Description = styled.p`
	font-family: ${theme.font.montserrat};
	font-weight: 400;
	font-size: 12px;
	color: ${theme.colors.red};
	line-height: 16px;
	margin-bottom: 18px;
`;

const ActionButton = styled.button`
	width: 200px;
	height: 40px;
	color: #fff;
	font-family: ${theme.font.montserrat};
	font-weight: 600;
	font-size: 14px;
	text-align: center;
	line-height: 16px;
	outline: none;
	border: none;
	border-radius: 2px;
	background: ${theme.colors.red};
	&:hover {
		background: #d21907;
	}
`;

interface ILeaveRemove {
	isCommissioner: boolean;
	league: ILeague;
}

export const LeaveRemoveLeague: React.FC<ILeaveRemove> = ({isCommissioner, league}) => {
	const dispatch = useDispatch();

	const headingText = isCommissioner ? "Remove League" : "Leave League";
	const descriptionText = isCommissioner
		? "If you remove a league, it will be removed forever"
		: "If you leave a league, you can always rejoin it, should you wish";
	const buttonText = isCommissioner ? "Remove League" : "Leave League";

	const handleExitComp = () => {
		dispatch(showConfirmModal());
	};
	if (isCommissioner && league.status !== "scheduled") {
		return null;
	}

	return (
		<Wrapper>
			<RemoveLeaveLeagueModal isCommissioner={isCommissioner} />
			<Heading>{headingText}</Heading>
			<Description>{descriptionText}</Description>
			<ActionButton onClick={handleExitComp}>{buttonText}</ActionButton>
		</Wrapper>
	);
};
