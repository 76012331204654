import React from "react";
import styled from "styled-components";

interface IProps {
	width?: number;
	height?: number;
	color?: string;
}

const SVG = styled.svg``;

export const IconBlackInfo: React.FC<IProps> = ({color, ...rest}) => (
	<SVG viewBox="0 0 24 24" fill="none" {...rest} width="24" height="24">
		<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
			<path
				fill="#000"
				d="M22 9v3.25a6 6 0 01-6 6H4.65L2 21V9a6 6 0 016-6h8a6 6 0 016 6zm-9 1h-2v5h2v-5zm0-4h-2v2h2V6z"
			/>
		</g>
	</SVG>
);
IconBlackInfo.defaultProps = {
	width: 8,
	height: 10,
	color: "currentColor",
};
