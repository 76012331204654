import {SagaIterator} from "redux-saga";

import {call, race, take, select, all, delay} from "redux-saga/effects";

import {differenceBy, toPairs, last, first, flow, partial, get, identity, isEmpty} from "lodash";

import {unsubscribeFromLiveScores} from "modules/actions";
import {getChecksums} from "modules/selectors";

import {fetchSquadsSaga, fetchRoundsSaga, fetchChecksumsSaga} from "modules/sagas";

// TODO
// Check if below matches to checksums file for CA
const mapChecksumsToSaga = {
	rounds: fetchRoundsSaga,
	squads: fetchSquadsSaga,
	"wbbl/rounds": fetchRoundsSaga,
	"bbl/rounds": fetchRoundsSaga,
};

const WAIT = 31000; // Half minute

export const fetchLiveScoresSaga = function* (): SagaIterator {
	const {stopped} = yield race({
		wait: delay(WAIT), // Wait for a half minute
		stopped: take(unsubscribeFromLiveScores),
	});

	if (!stopped) {
		const [old_checksums] = yield all([select(getChecksums), call(fetchChecksumsSaga)]);

		const new_checksums = yield select(getChecksums);
		const requestsForChanges = differenceBy(
			toPairs(old_checksums),
			toPairs(new_checksums),
			last
		)
			.map(flow([first, partial(get, mapChecksumsToSaga)]))
			.filter(identity);

		if (!isEmpty(requestsForChanges)) {
			yield all(requestsForChanges.map((request) => call(request)));
		}

		yield call(fetchLiveScoresSaga);
	}
};
