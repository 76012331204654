import React from "react";
import {useSelector} from "react-redux";
import {getLeague} from "modules/selectors";
import styled from "styled-components";
import {partial} from "lodash";
import {IconFacebook, IconTwitter} from "components/Icons";
import {share} from "modules/utils/Share";
import {IShareType} from "modules/types";
import {theme} from "assets/themes/bbl";
import {Flex} from "components/Flex";
import {getSportName} from "modules/utils/helpers";

const InviteButton = styled.button`
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: #000;
	border: none;
	outline: none;
	transition: all 0.25s ease-out;
	cursor: pointer;
	color: #fff;
	display: inline-flex;
	justify-content: center;
	font-size: 10px;
	align-items: center;
	margin-right: 10px;
	&.facebook-icon {
	}

	&:hover {
		background-color: ${theme.colors.darkGrey};
		transition: background-color 0.25s ease-out;
	}
`;

const ShareWrapper = styled(Flex)`
	margin: 10px 0;
	@media screen and (max-width: 639px) {
		justify-content: center;
	}
`;

const InviteButtonWrap = styled(Flex)`
	background-color: transparent;
	margin: 0 0 10px 0;
`;

interface IProps {
	is_separate_page?: boolean;
	isCommissioner?: boolean;
	isDisabled?: boolean;
	isSkipBtn?: boolean;
	isLeaguePage?: boolean;
}
export const SocialInvites: React.FC<IProps> = () => {
	const league = useSelector(getLeague);

	const shareClick = (type: IShareType) =>
		share({
			sportName: getSportName(),
			sc: type,
			section: "league",
			league_data: league,
		});

	return (
		<ShareWrapper>
			<InviteButtonWrap>
				<InviteButton onClick={partial(shareClick, "fb")} className="facebook-icon">
					<IconFacebook height={20} width={20} color={theme.colors.white} />
				</InviteButton>
			</InviteButtonWrap>
			<InviteButtonWrap>
				<InviteButton onClick={partial(shareClick, "tw")}>
					<IconTwitter color={theme.colors.white} />
				</InviteButton>
			</InviteButtonWrap>
		</ShareWrapper>
	);
};
