import React, {useRef, useState} from "react";
import styled from "styled-components";
import {get, partial} from "lodash";
import {useSelector} from "react-redux";
import {
	getSquadsKeyedByID,
	getSelectedRoundMatchesById,
	isTippingStatsLoaded,
	getRoundByIdSelector,
} from "modules/selectors";
import {IUserPrediction} from "modules/types";
import {MatchStatus} from "modules/utils/enums";
import {theme} from "assets/themes/bbl";
import {IconCross} from "components/Icons/IconCross";
import {IconWarning} from "components/Icons/IconWarning";
import {IconArrowRight} from "components/Icons/IconArrowRight";
import {Exist, LoaderBar} from "components";
import {TippingItem} from "components/TipRow/TippingItem";
import {useMediaQuery} from "modules/utils";

// CREATE HOLDING MESSAGE UNTIL AT LEAST ONE MATCH COMPLETE

const Wrapper = styled.div`
	height: 128px;
	width: 100%;
	padding: 15px 28px;
	display: flex;
	flex-direction: column;
	background-color: #f1f1f1;
	align-items: center;
	justify-content: space-between;
	@media screen and (max-width: 640px) {
		padding: 10px 14px;
		height: 135px;
	}
`;

const TitleRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	font-family: ${theme.font.montserrat};
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
	color: #000;
	margin-bottom: 15px;
`;

const CloseButton = styled.button`
	outline: none;
	background: none;
	border: none;
	width: 12px;
	height: 12px;
	svg {
		height: 12px;
		width: 12px;
		path {
			fill: #000;
		}
	}
`;

const TipsNotAvailableYet = styled.div`
	width: 80%;
	height: 100%;
	background: white;
	border-radius: 5px;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	padding: 5px;
	align-items: center;
	font-size: 16px;
	font-family: ${theme.font.halisa};
	font-weight: 700;
	color: ${theme.colors.primary};
	span {
		font-weight: 400;
		font-family: ${theme.font.montserrat};
		color: ${theme.colors.darkGrey};
		font-size: 14px;
		text-align: center;
	}
	svg {
		height: 25px;
		width: 25px;
	}
	@media screen and (max-width: 640px) {
		width: 95%;
	}
`;

const UserTipsWrapper = styled.div`
	display: flex;
	position: relative;
	align-items: center;
	justify-content: flex-start;
	overflow-x: auto;
	white-space: nowrap;
	overflow-y: hidden;
	scroll-behavior: smooth;
	&::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none;
	scrollbar-width: none;

	width: 784px;
	@media screen and (max-width: 640px) {
		width: 322px;
	}
`;

const UserTipsInnerWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const OverflowButton = styled.button<{
	isLeft?: boolean;
}>`
	min-height: 32px;
	min-width: 32px;
	display: flex;
	z-index: 1;
	background: white;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	border: 2px solid black;
	box-shadow: 0 0 10px 0rgba (0, 0, 0, 0.2);
	position: sticky;
	right: 5px;
	top: calc(50% - 16px);
	cursor: pointer;
	${({isLeft}) =>
		isLeft &&
		`
		left: 5px;
		right: unset;
		svg{
			transform: rotate(180deg);
		}
	`}
`;

interface ITipRow {
	tips: {
		[round: number]: IUserPrediction[];
	};
	roundID: number;
	onClose: () => void;
}

export const TipRow: React.FC<ITipRow> = ({tips, roundID, onClose}) => {
	// dont show result until complete match
	const isMobile = useMediaQuery("(max-width: 640px)");
	const [scrollState, setScrollState] = useState("");
	const tipsRef = useRef<HTMLDivElement>(null);
	const selectedRound = useSelector(getRoundByIdSelector)(roundID);
	const displayWeek = get(selectedRound, "number", 1);
	const matchesByID = useSelector(getSelectedRoundMatchesById);
	const squadsByID = useSelector(getSquadsKeyedByID);
	const isLoaded = useSelector(isTippingStatsLoaded);

	const tipsByRound = get(tips, roundID, []);

	// create placeholder
	let isAnyMatchCompleteTally = 0;

	const userTipsData = tipsByRound
		.map((userPred, index) => {
			const match = matchesByID[userPred.match];
			const matchStatus = match.status;
			const homeSquad = squadsByID[match.homeSquadId];
			const awaySquad = squadsByID[match.awaySquadId];
			const isCorrect = userPred.isCorrect;
			if (matchStatus === MatchStatus.Complete) {
				isAnyMatchCompleteTally += 1;
				return {
					homeSquad,
					awaySquad,
					isCorrect,
					date: match.date,
					tippedSquad: userPred.squad,
				};
			}
			return {
				homeSquad,
				awaySquad,
				isCorrect,
				date: "",
				tippedSquad: userPred.squad,
			};
		})
		.filter((tip) => tip.date !== "")
		.sort((tipA, tipB) => {
			return tipA.date < tipB.date ? -1 : 1;
		});

	const isAnyMatchComplete = isAnyMatchCompleteTally > 0;
	const overflowValue = isMobile ? 3 : 8;
	const showRightArrow = userTipsData.length > overflowValue && scrollState !== "right";
	const showLeftArrow = userTipsData.length > overflowValue && scrollState === "right";

	const handleScroll = (direction: string) => {
		const scrollValue = direction === "left" ? -1000 : 1000;
		if (tipsRef && tipsRef.current) {
			tipsRef.current.scrollLeft += scrollValue;
		}
		setScrollState(direction);
	};

	return (
		<Wrapper>
			<TitleRow>
				<span>Week {displayWeek} Tips</span>
				<CloseButton onClick={onClose}>
					<IconCross />
				</CloseButton>
			</TitleRow>
			<Exist when={isAnyMatchComplete}>
				<UserTipsWrapper ref={tipsRef}>
					<Exist when={showLeftArrow}>
						<OverflowButton onClick={partial(handleScroll, "left")} isLeft={true}>
							<IconArrowRight />
						</OverflowButton>
					</Exist>
					<UserTipsInnerWrapper>
						{userTipsData.map((tip, index) => (
							<TippingItem key={index} {...tip} />
						))}
					</UserTipsInnerWrapper>
					<Exist when={showRightArrow}>
						<OverflowButton onClick={partial(handleScroll, "right")}>
							<IconArrowRight />
						</OverflowButton>
					</Exist>
				</UserTipsWrapper>
			</Exist>
			<Exist when={!isAnyMatchComplete}>
				{!isLoaded ? (
					<LoaderBar />
				) : (
					<TipsNotAvailableYet>
						<IconWarning />
						Please Note:
						<span>
							You will have the ability to view other users tips when each game
							finishes
						</span>
					</TipsNotAvailableYet>
				)}
			</Exist>
		</Wrapper>
	);
};
