import {createReducer} from "redux-act";
import * as actions from "modules/actions";
import {INextAction, IRankingsItem, IRequestData} from "modules/types";

export interface ILeagueActionGetLadder {
	id: number;
	q: string;
	round?: number;
	offset: number;
}

export interface IActionSuccessLadder extends INextAction<IRankingsItem> {
	q: string;
}

interface IRankNum {
	readonly rank: number;
}

interface IFilterLadder {
	readonly q: string;
}

export type TLadder = INextAction<IRankingsItem> & IRequestData & IRankNum & IFilterLadder;

const defaultState: TLadder = {
	items: [],
	nextPage: false,
	rank: 0,
	is_data_requested: false,
	q: "",
};

export const league_ladder = createReducer<TLadder>({}, defaultState)
	.on(actions.getLeagueLadderData, (state) => ({
		...state,
		is_data_requested: true,
	}))
	.on(actions.clearLeagueLadder, (state) => ({
		...state,
		items: [],
		next: false,
		is_data_requested: false,
	}))
	.on(actions.setLeagueLadderQuery, (state, payload) => ({
		...state,
		q: payload,
	}))
	.on(actions.getLeagueLadderDataSuccess, (state, payload) => ({
		...state,
		...payload,
		is_data_requested: false,
	}))
	.on(actions.getLeagueLadderDataFailed, (state) => ({
		...state,
		is_data_requested: false,
	}))
	.on(actions.postDeleteJoinedUsersSuccess, (state, payload) => ({
		...state,
		items: state.items.filter((user) => {
			return !payload.users.includes(user.userId);
		}),
	}));
