import {call, put} from "typed-redux-saga/macro";
import {Api} from "modules/utils";
import {fetchDashboardSuccess, showGlobalError} from "modules/actions";
import {get} from "lodash";

export const fetchDashboardSaga = function* () {
	try {
		const response = yield* call(Api.dashboard.dashboard);

		yield* put(fetchDashboardSuccess(response.success.sports));
	} catch (err: unknown) {
		console.log(err);
		const message = get(err, "message", "");
		yield* put(
			showGlobalError({
				message: message,
			})
		);
	}
};
