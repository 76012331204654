import styled from "styled-components";

export const Flex = styled.div`
	display: flex;
`;

export const FlexCol = styled.div`
	display: flex;
	flex-direction: column;
`;
