import * as React from "react";
import {IIconProps} from "modules/types";

export const IconTwitter: React.FC<IIconProps> = ({title, width, height, color, onClick}) => (
	<svg
		width={`${width || 0}px`}
		height={`${height || 0}px`}
		viewBox="0 0 16 13"
		onClick={onClick}>
		<title>{title}</title>
		<g transform="translate(-4.000000, -5.000000)">
			<path
				fill={color}
				d="M20,6.55932203 C19.5932183,7.19209356 19.0508508,7.75705966 18.3728814,8.25423729 L18.3728814,8.66101695 C18.3728814,11.0339102 17.5197825,13.186431 15.8135593,15.1186441 C14.1073361,17.0508571 11.841822,18.0169492 9.01694915,18.0169492 C7.20903051,18.0169492 5.53673085,17.519779 4,16.5254237 C4.36158373,16.5706217 4.62146814,16.5932203 4.77966102,16.5932203 C6.29379288,16.5932203 7.64971153,16.1186488 8.84745763,15.1694915 C8.14688915,15.1468925 7.51412712,14.9265558 6.94915254,14.5084746 C6.38417797,14.0903934 6,13.5536756 5.79661017,12.8983051 C6.06779797,12.9435031 6.27118576,12.9661017 6.40677966,12.9661017 C6.63276949,12.9661017 6.92655186,12.9209044 7.28813559,12.8305085 C6.54236915,12.6723156 5.91525678,12.2937883 5.40677966,11.6949153 C4.89830254,11.0960422 4.6440678,10.4011339 4.6440678,9.61016949 L4.6440678,9.57627119 C5.14124542,9.84745898 5.63841559,9.98305085 6.13559322,9.98305085 C5.16383695,9.35027932 4.6779661,8.43503424 4.6779661,7.23728814 C4.6779661,6.67231356 4.82485729,6.1299461 5.11864407,5.61016949 C6.85876576,7.75707288 9.10733085,8.89830441 11.8644068,9.03389831 C11.8192088,8.87570542 11.7966102,8.62712034 11.7966102,8.28813559 C11.7966102,7.36157729 12.1129912,6.58192407 12.7457627,5.94915254 C13.3785342,5.31638102 14.1581875,5 15.0847458,5 C16.0113041,5 16.8022566,5.35027898 17.4576271,6.05084746 C18.1355966,5.93785254 18.8361546,5.66666881 19.559322,5.23728814 C19.3333322,6.02825254 18.8474614,6.63841593 18.1016949,7.06779661 C18.8022634,7 19.4350254,6.83050983 20,6.55932203 Z"
			/>
		</g>
	</svg>
);

IconTwitter.defaultProps = {
	title: "Twitter",
	width: 16,
	height: 13,
	color: "#fff",
};
