import React, {HTMLProps} from "react";
import styled from "styled-components";
import checkboxTickMark from "assets/img/icons/checkboxTick.svg";

const CheckboxWrapper = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: relative;
	cursor: pointer;
	margin-right: 10px;

	input {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		margin: 0;
		opacity: 0;
		cursor: pointer;

		&:checked + span {
			background: url(${checkboxTickMark}) no-repeat center center #ff8c00;
			background-size: 100% auto;
		}

		&:focus:invalid + span {
			box-shadow: 0 0 3px #dc1515;
		}
	}

	span {
		width: 18px;
		height: 18px;
		border: 1px solid #ff8c00;
	}
`;

export const SimpleCheckbox: React.FC<HTMLProps<HTMLInputElement>> = (props) => {
	const {className, type = "checkbox", required = false, ...rest} = props;

	return (
		<CheckboxWrapper className={className}>
			{/* tslint:disable-next-line:react-a11y-input-elements react-a11y-required */}
			<input type={type} required={required} aria-required={required} {...rest} />
			<span />
		</CheckboxWrapper>
	);
};
