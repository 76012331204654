import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchRounds, fetchSquads, getLeagueLadderData, IRound} from "modules/actions";
import {
	getRounds,
	getLeague,
	getLeaguesLadderStore,
	isLeagueCommissioner,
	getSelectedRoundID,
	getLeagueLadderLoading,
	getIsShowByOverall,
	getSquads,
} from "modules/selectors";
import {isEmpty, get} from "lodash";
import {useParams} from "react-router-dom";
import styled from "styled-components";
import {LoadMoreButton, LadderList, RankingsHolding} from "components";

const Wrapper = styled.section`
	margin-bottom: 0;
	width: 100%;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: column;
	align-items: center;

	@media (min-width: 640px) {
		margin-bottom: 40px;
		padding-bottom: 20px;
	}
`;

export const findLastRoundIDWithActiveMatch = (rounds: IRound[]): number => {
	const handledRounds: IRound[] = rounds.filter((round) => {
		const {matches} = round;
		return matches.filter((match) => match.status === "complete").length > 0;
	});
	return handledRounds[handledRounds.length - 1]?.id;
};

// match league ladder load next logic
export const LeagueLadder: React.FC = () => {
	const params = useParams();
	const league_id = get(params, "league_id", 0);
	const dispatch = useDispatch();
	const [offset, setOffset] = useState(1);
	const league = useSelector(getLeague);
	const rounds = useSelector(getRounds);
	const lastCompleteRoundId = findLastRoundIDWithActiveMatch(rounds);
	const squads = useSelector(getSquads);
	const league_ladder_store = useSelector(getLeaguesLadderStore);
	const isCommissioner = useSelector(isLeagueCommissioner);
	const selectedRoundID = useSelector(getSelectedRoundID);
	const isLoading = useSelector(getLeagueLadderLoading) || false;
	const isShowOvr = useSelector(getIsShowByOverall);
	const isShowLadder = lastCompleteRoundId ? league.startRound <= lastCompleteRoundId : false;

	useEffect(() => {
		if (league_id) {
			dispatch(
				getLeagueLadderData({
					id: +league_id,
					round: selectedRoundID,
					offset,
				})
			);
		}
		if (isEmpty(rounds)) {
			dispatch(fetchRounds());
		}
		if (isEmpty(squads)) {
			dispatch(fetchSquads());
		}
	}, [
		dispatch,
		league_id,
		league_ladder_store.q,
		selectedRoundID,
		offset,
		rounds,
		squads,
		isShowOvr,
	]);

	const onClickLoadMore = () => {
		dispatch(
			getLeagueLadderData({
				id: +league_id,
				round: selectedRoundID,
				offset: offset + 1,
			})
		);
		setOffset(offset + 1);
	};

	if ((league_ladder_store.items.length === 0 && !isLoading) || !isShowLadder) {
		return <RankingsHolding />;
	}

	return (
		<Wrapper>
			<LadderList
				isCommissioner={isCommissioner}
				roundId={selectedRoundID}
				leagueId={league.id}
			/>
			{league_ladder_store.nextPage && (
				<LoadMoreButton onClick={onClickLoadMore} loading={isLoading} />
			)}
		</Wrapper>
	);
};
