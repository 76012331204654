import {createReducer} from "redux-act";
import {IDashboard} from "modules/types";
import {fetchDashboardSuccess} from "modules/actions/dashboard";

const initialState = {
	isLoaded: false,
	items: [],
};

export const dashboard = createReducer<IDashboard>({}, initialState).on(
	fetchDashboardSuccess,
	(state, payload) => ({
		...state,
		isLoaded: true,
		items: payload,
	})
);
