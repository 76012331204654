import {createAction} from "redux-act";
import {ISnapshot} from "modules/reducers";

export const fetchSnapshot = createAction<{round: number}>();
export const fetchSnapshotSuccess = createAction<ISnapshot>();

export interface IFetchUserTipsPayload {
	roundId: number;
	userId: number;
}

export type IUserPrediction = {
	match: number;
	squad: number;
	margin: number;
	isCorrect: boolean | null;
	isAutoTip: boolean;
};

export interface IOtherUserSuccessPayload {
	round: number;
	user: number;
	predictions: IUserPrediction[];
}

export const fetchOtherUserTips = createAction<IFetchUserTipsPayload>();
export const fetchOtherUserTipsSuccess = createAction<IOtherUserSuccessPayload>();
export const fetchOtherUserTipsFailed = createAction<Error>();
