import React from "react";
import styled from "styled-components";

interface IProps {
	width?: number;
	height?: number;
	color?: string;
}

const SVG = styled.svg``;

export const IconGoldenWeek: React.FC<IProps> = ({color, ...rest}) => (
	<SVG viewBox="0 0 60 60" fill="none" {...rest} width="60" height="60">
		<path
			fill="#D4AF37"
			fillRule="evenodd"
			d="M30 43.695L14.55 55 20 36.056 5 24.098l18.82-.403L30 5l6.18 18.695 18.82.403-15 11.958L45.45 55 30 43.695z"
			clipRule="evenodd"
		/>
		<mask
			style={{maskType: "alpha"}}
			width="54"
			height="54"
			x="3"
			y="3"
			maskUnits="userSpaceOnUse">
			<path
				fill="#fff"
				fillRule="evenodd"
				stroke="#fff"
				d="M30 43.695L14.55 55 20 36.056 5 24.098l18.82-.403L30 5l6.18 18.695 18.82.403-15 11.958L45.45 55 30 43.695z"
				clipRule="evenodd"
			/>
		</mask>
		<path
			fill="#fff"
			d="M27.996 37l-2.028-2.916L23.976 37h-2.232l3.108-4.272L21.9 28.6h2.208l1.932 2.724 1.896-2.724h2.1l-2.928 4.056L30.24 37h-2.244zm8.962-1.584V37H30.61v-1.26l3.24-3.06c.344-.328.576-.608.696-.84.12-.24.18-.476.18-.708 0-.336-.116-.592-.348-.768-.224-.184-.556-.276-.996-.276-.368 0-.7.072-.996.216a1.859 1.859 0 00-.744.624l-1.416-.912c.328-.488.78-.868 1.356-1.14.576-.28 1.236-.42 1.98-.42.624 0 1.168.104 1.632.312.472.2.836.488 1.092.864.264.368.396.804.396 1.308 0 .456-.096.884-.288 1.284-.192.4-.564.856-1.116 1.368l-1.932 1.824h3.612z"
		/>
	</SVG>
);
IconGoldenWeek.defaultProps = {
	width: 60,
	height: 60,
	color: "currentColor",
};
