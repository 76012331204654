import styled, {keyframes} from "styled-components";

const Spin = keyframes`
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
`;

export const Preloader = styled.div`
	border: 16px solid #f3f3f3;
	border-radius: 50%;
	border-top: 16px solid #e6ff00;
	width: 120px;
	height: 120px;
	display: flex;
	margin: auto;
	animation: ${Spin} 2s linear infinite;
`;
