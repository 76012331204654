import React from "react";
import styled from "styled-components";

interface IProps {
	width?: number;
	height?: number;
	color?: string;
}

const SVG = styled.svg``;

export const IconBurger: React.FC<IProps> = ({color, ...rest}) => (
	<SVG viewBox="0 0 24 18" fill="none" {...rest}>
		<path
			fill="#4F5C68"
			fillRule="evenodd"
			d="M22.5 15a1.5 1.5 0 010 3h-21a1.5 1.5 0 010-3h21zm0-7.5a1.5 1.5 0 010 3h-21a1.5 1.5 0 110-3h21zm0-7.5a1.5 1.5 0 010 3h-21a1.5 1.5 0 110-3h21z"
			clipRule="evenodd"
		/>
	</SVG>
);
IconBurger.defaultProps = {
	width: 24,
	height: 18,
	color: "currentColor",
};
