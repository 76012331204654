import {IStore} from "modules/types";
import {get} from "lodash";
import {createSelector} from "reselect";

export const getSnapshot = (state: IStore) => state.tipping_stats.snapshot;
export const isTippingStatsLoaded = (state: IStore) => state.tipping_stats.is_loaded;

export const getOtherUserTipsState = (state: IStore) => state.tipping_stats.userTipsByID;

export const getOtherUserTips = createSelector(
	getOtherUserTipsState,
	(tipsByID) => (teamID: number) => {
		return get(tipsByID, `${teamID}`, []);
	}
);
