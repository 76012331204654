import React, {useCallback} from "react";
import {HOCConfirmModal} from "components";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {getLeague} from "modules/selectors";
import {IStore} from "modules/types";
import {Navigate} from "react-router-dom";
import {postLeaveLeague, postDeleteLeague} from "modules/actions";
import {useSportRoute} from "modules/utils";
import {theme} from "assets/themes/bbl";

const LeaveLeagueWrap = styled.div`
	margin: 30px 0;
	p {
		color: ${theme.colors.primary};
		font-family: ${theme.font.montserrat};
		font-size: 20px;
		font-weight: 600;
		letter-spacing: -0.1px;
		line-height: 24px;
		text-align: center;
	}
`;

interface IRemoveLeaveLeague {
	isCommissioner: boolean;
}

export const RemoveLeaveLeagueModal: React.FC<IRemoveLeaveLeague> = ({isCommissioner}) => {
	const dispatch = useDispatch();
	const sport = useSportRoute();
	const league = useSelector(getLeague);
	const {isUserLeftLeague} = useSelector((state: IStore) => state.leagues);
	const modalText = isCommissioner
		? "Are you sure you want to delete this league"
		: "Are you sure you want to leave this league";

	const leave = useCallback(() => {
		const id = league.id;

		dispatch(postLeaveLeague({league_id: id}));
	}, [dispatch, league]);

	const deleteLeague = useCallback(() => {
		dispatch(postDeleteLeague({id: league.id}));
	}, [dispatch, league]);

	const handleLeague = () => {
		isCommissioner ? deleteLeague() : leave();
	};

	if (isUserLeftLeague) {
		return <Navigate to={`${sport}/leagues`} replace />;
	}

	return (
		<HOCConfirmModal onConfirm={handleLeague}>
			<LeaveLeagueWrap>
				<p>{modalText}</p>
			</LeaveLeagueWrap>
		</HOCConfirmModal>
	);
};
