import {useRef, useEffect, useState, RefObject, ContextType, useCallback, useContext} from "react";
import {debounce, keys, includes} from "lodash";
import {useDispatch, useSelector} from "react-redux";
import type {Blocker, History, Transition} from "history";
import {getRoundByIdSelector, getSquadsById} from "modules/selectors";
import {IMAGES_URL, SPORTS} from "modules/utils/constants";
import {
	useLocation,
	useParams,
	Navigator as BaseNavigator,
	UNSAFE_NavigationContext as NavigationContext,
	useNavigate,
} from "react-router-dom";
import {setPageHeader, clearPageHeader} from "modules/actions";
import placeholderLogo from "assets/img/thunderPlaceholder.png";

interface INavigator extends BaseNavigator {
	block: History["block"];
}

type NavigationContextWithBlock = ContextType<typeof NavigationContext> & {navigator: INavigator};

/**
 * @source https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874
 */
const useBlocker = (blocker: Blocker, when = true) => {
	const {navigator} = useContext(NavigationContext) as NavigationContextWithBlock;

	useEffect(() => {
		if (!when) {
			return;
		}

		const unblock = navigator.block((tx: Transition) => {
			const autoUnblockingTx = {
				...tx,
				retry() {
					// Automatically unblock the transition so it can play all the way
					// through before retrying it. TODO: Figure out how to re-enable
					// this block if the transition is cancelled for some reason.
					unblock();
					tx.retry();
				},
			};

			blocker(autoUnblockingTx);
		});

		return unblock;
	}, [navigator, blocker, when]);
};

export const useBlockNavigation = (when: boolean) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [showPrompt, setShowPrompt] = useState(false);
	const [lastLocation, setLastLocation] = useState<Transition | null>(null);
	const [confirmedNavigation, setConfirmedNavigation] = useState(false);

	const cancelNavigation = useCallback(() => {
		setShowPrompt(false);
	}, []);

	const handleBlockedNavigation: Blocker = useCallback(
		(nextLocation) => {
			const isRouteChanged = nextLocation.location.pathname !== location.pathname;

			if (!confirmedNavigation && isRouteChanged) {
				setShowPrompt(true);
				setLastLocation(nextLocation);

				return false;
			}

			return true;
		},
		[confirmedNavigation, location.pathname]
	);

	const confirmNavigation = useCallback(() => {
		setShowPrompt(false);
		setConfirmedNavigation(true);
	}, []);

	useEffect(() => {
		if (confirmedNavigation && lastLocation) {
			navigate(lastLocation.location.pathname);
		}
	}, [confirmedNavigation, lastLocation, navigate]);

	useBlocker(handleBlockedNavigation, when);

	return [showPrompt, confirmNavigation, cancelNavigation] as const;
};

export const usePrevious = <T = undefined>(value: T): T | undefined => {
	const ref = useRef<T>();

	useEffect(() => {
		ref.current = value;
	});

	return ref.current;
};

export const useMediaQuery = (query: string) => {
	const mediaMatch = window.matchMedia(query);
	const [matches, setMatches] = useState(mediaMatch.matches);
	useEffect(() => {
		const handler = (e: MediaQueryListEvent) => setMatches(e.matches);

		if ("addListener" in mediaMatch) {
			mediaMatch.addListener(handler);
		} else {
			// mediaMatch.addEventListener("change", handler);
		}

		return () => {
			if ("addListener" in mediaMatch) {
				mediaMatch.removeListener(handler);
			} else {
				// mediaMatch.removeEventListener("change", handler);
			}
		};
	}, [mediaMatch]);

	return matches;
};

export const useScrollTo =
	({current}: RefObject<HTMLElement>) =>
	() => {
		if (current) {
			window.scrollTo({
				top: current.offsetTop,
				behavior: "smooth",
			});
		}
	};

export const useWindowSize = (delay = 300) => {
	const [windowSize, setWindowSize] = useState({
		width: 0,
		height: 0,
	});

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}

		const debounced = debounce(handleResize, delay);
		window.addEventListener("resize", debounced);
		handleResize();

		return () => window.removeEventListener("resize", debounced);
	}, [delay]);

	return windowSize;
};

const getTeamIcon = ({logo, sportName}: {logo?: string; sportName: string}) => {
	if (logo) {
		return `${IMAGES_URL}squads/${sportName}/${logo}`;
	}

	return "";
};

export const useSquadsLogo = (id: number) => {
	const custom_logo = useSelector(getSquadsById)(id)?.custom_logo;
	const sport = useSportRoute();
	const sportName = sport.substring(1);
	const teamIcon = getTeamIcon({logo: custom_logo, sportName});

	return teamIcon === "" ? placeholderLogo : teamIcon;
};

const getRoundId = (roundId?: string) => (roundId ? parseInt(roundId, 10) : undefined);

export const useSelectedRound = () => {
	const {roundId} = useParams<{roundId: string}>();
	const roundIdInt = getRoundId(roundId);
	return useSelector(getRoundByIdSelector)(roundIdInt);
};

export const usePageHeader = (title: string) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setPageHeader(title));

		return () => {
			dispatch(clearPageHeader());
		};
	}, [dispatch, title]);
};

export const useOverflowHidden = (show: boolean) => {
	useEffect(() => {
		const className = "overflow-hidden";
		const {body} = window.document;
		if (show) {
			body.classList.add(className);
			window.scrollTo(0, 0);
		} else if (body.classList.contains(className)) {
			body.classList.remove(className);
		}

		return () => {
			body.classList.remove(className);
		};
	}, [show]);
};

export const useSportRoute = () => {
	const {pathname} = useLocation();
	const [sportName, setSportName] = useState("");
	useEffect(() => {
		const sportNameInPath = keys(SPORTS).find((sport) => includes(pathname, sport));
		setSportName(sportNameInPath || "");
	}, [pathname]);

	return `/${sportName}`;
};

export const useComponentVisible = (initialIsVisible: boolean) => {
	const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
	const ref = useRef<HTMLDivElement>(null);

	const handleHideDropdown = (event: KeyboardEvent) => {
		if (event.key === "Escape") {
			setIsComponentVisible(false);
		}
	};

	const handleClickOutside = (event: Event) => {
		if (ref.current && !ref.current.contains(event.target as Node)) {
			setIsComponentVisible(false);
		}
	};

	useEffect(() => {
		document.addEventListener("keydown", handleHideDropdown, true);
		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("keydown", handleHideDropdown, true);
			document.removeEventListener("click", handleClickOutside, true);
		};
	});

	return {ref, isComponentVisible, setIsComponentVisible};
};

export const useQuery = () => new URLSearchParams(useLocation().search);
