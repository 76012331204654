import React, {useState} from "react";
import styled from "styled-components";

import {Navigation} from "components";
import {isWBBL} from "modules/utils";

const HeaderWrap = styled.header<{isWBBL: boolean}>`
	background: linear-gradient(
		90deg,
		rgba(50, 53, 216, 255) 0%,
		rgba(94, 39, 170, 255) 25%,
		rgba(158, 20, 106, 255) 75%,
		rgba(208, 4, 53, 255) 100%
	);
	${({isWBBL}) =>
		isWBBL &&
		`
		background: linear-gradient(
			90deg,
			rgba(165,25,190,255) 0%,
			rgba(185,45,160,255) 25%,
			rgba(220,77,109,255) 75%,
			rgba(247,102,67,255) 100%
		);
	`}

	background-size: cover;
	position: relative;
	@media screen and (max-width: 639px) {
		height: 60px;
		width: 100%;
		position: sticky;
		top: 0px;
		z-index: 10;
	}
`;

export const Header: React.FC = () => {
	const [showSub, setShowSub] = useState(false);
	const currentURL = window.location.href;
	const showWBBL = isWBBL(currentURL);

	const toggleSubMenuOn = () => {
		setShowSub(true);
	};
	const toggleSubMenuOff = () => {
		setShowSub(false);
	};
	return (
		<React.Fragment>
			<HeaderWrap isWBBL={showWBBL} onMouseLeave={toggleSubMenuOff}>
				<Navigation
					showSub={showSub}
					toggleSubMenuOn={toggleSubMenuOn}
					toggleSubMenuOff={toggleSubMenuOff}
				/>
			</HeaderWrap>
		</React.Fragment>
	);
};
