import {createReducer} from "redux-act";
import {
	resetForgotPasswordModal,
	showForgotPasswordModal,
	hideForgotPasswordModal,
	postPWDResetRequestSuccess,
	postPWDResetRequestFailed,
	showConfirmModal,
	hideConfirmModal,
	showShareModal,
	hideShareModal,
	showLoginModal,
	hideLoginModal,
	showRegistrationModal,
	hideRegistrationModal,
	userRegistrationSuccess,
	userLoginSuccess,
	showJoinModal,
	hideJoinModal,
	showBonusPointModal,
	hideBonusPointModal,
	// showJoinPopup,
	// closeJoinPopup,
} from "modules/actions";
// import {IShowJoinPopup} from "modules/types";
// import {EAuthModal} from "modules/utils/";

const defaultState = {
	forgotPasswordModal: {
		isShow: false,
	},
	loginModal: {
		isShow: false,
	},
	registrationModal: {
		isShow: false,
	},
	showConfirmModal: false,
	join_popup_data: {},
	shareModal: {
		isShow: false,
		text: "",
	},
	joinModal: {
		isShow: false,
		joinCode: 0,
	},
	bonusPointModal: {
		isShow: false,
	},
};

interface IDefaultModal {
	isShow: boolean;
}

interface IForgotPasswordModal extends IDefaultModal {
	error?: string;
	success?: boolean;
}

export interface IModalsReducer {
	forgotPasswordModal: IForgotPasswordModal;
	loginModal: IDefaultModal;
	registrationModal: IDefaultModal;
	showConfirmModal: boolean;
	shareModal: {
		isShow: boolean;
		text?: string;
	};
	joinModal: {
		isShow: boolean;
		joinCode: string | number;
	};
	bonusPointModal: {
		isShow: boolean;
	};
	// join_popup_data: IShowJoinPopup;
}

const changeModal = (state: IModalsReducer, payload: Partial<IModalsReducer>) => {
	return {
		...state,
		forgotPasswordModal: {
			...state.forgotPasswordModal,
			isShow: false,
		},
		loginModal: {
			...state.loginModal,
			isShow: false,
		},
		registrationModal: {
			...state.registrationModal,
			isShow: false,
		},
		...payload,
	};
};

export const modals = createReducer<IModalsReducer>({}, defaultState)
	.on(showForgotPasswordModal, (state) =>
		changeModal(state, {
			forgotPasswordModal: {
				isShow: true,
			},
		})
	)
	.on(hideForgotPasswordModal, (state) => ({
		...state,
		forgotPasswordModal: {
			...state.forgotPasswordModal,
			isShow: false,
			success: undefined,
			error: undefined,
		},
	}))
	.on(resetForgotPasswordModal, (state) => ({
		...state,
		forgotPasswordModal: {
			...state.forgotPasswordModal,
			success: undefined,
			error: undefined,
		},
	}))
	.on(postPWDResetRequestSuccess, (state) => ({
		...state,
		forgotPasswordModal: {
			...state.forgotPasswordModal,
			success: true,
			error: undefined,
		},
	}))
	.on(postPWDResetRequestFailed, (state, err) => ({
		...state,
		forgotPasswordModal: {
			...state.forgotPasswordModal,
			success: false,
			error: err.message,
		},
	}))
	.on(showConfirmModal, (state) => ({
		...state,
		showConfirmModal: true,
	}))
	.on(hideConfirmModal, (state) => ({
		...state,
		showConfirmModal: false,
	}))
	.on(showShareModal, (state, payload) => ({
		...state,
		shareModal: {
			...state.shareModal,
			isShow: true,
			...payload,
		},
	}))
	.on(hideShareModal, (state) => ({
		...state,
		shareModal: {
			...state.shareModal,
			isShow: false,
			text: "",
		},
	}))
	.on(showBonusPointModal, (state) => ({
		...state,
		bonusPointModal: {
			isShow: true,
		},
	}))
	.on(hideBonusPointModal, (state) => ({
		...state,
		bonusPointModal: {
			isShow: false,
		},
	}))
	.on(showLoginModal, (state) =>
		changeModal(state, {
			loginModal: {
				isShow: true,
			},
		})
	)
	.on(hideLoginModal, (state) => ({
		...state,
		loginModal: {
			...state.loginModal,
			isShow: false,
		},
	}))
	.on(showRegistrationModal, (state) =>
		changeModal(state, {
			registrationModal: {
				isShow: true,
			},
		})
	)
	.on(hideRegistrationModal, (state) => ({
		...state,
		registrationModal: {
			...state.registrationModal,
			isShow: false,
		},
	}))
	.on(userRegistrationSuccess, (state) => changeModal(state, {}))
	.on(userLoginSuccess, (state) => changeModal(state, {}))
	.on(showJoinModal, (state, payload) => ({
		...state,
		joinModal: {
			isShow: true,
			joinCode: payload.code,
		},
	}))
	.on(hideJoinModal, (state) => ({
		...state,
		joinModal: {
			isShow: false,
			joinCode: 0,
		},
	}));
