import * as React from "react";
import {HOCTimer} from "./HOCTimer";
import {Exist} from "components/Exist";
import {identity, padStart} from "lodash";
import styled from "styled-components";

const TimerContainer = styled.div`
	color: #2c2c2c;
	font-family: "InterFace Corp", sans-serif;
	font-size: 14px;
	font-weight: 700;
	letter-spacing: 0;
	line-height: 17px;
	display: flex;
	align-items: center;
	box-sizing: border-box;
	text-align: center;
	position: relative;
	padding: 5px 0;
`;

const Flex = styled.div`
	display: inline-flex;
`;

const TimeUnit = styled(Flex)`
	padding: 0 2px;
`;

const TimeUnitItem = styled.div``;

interface IProps {
	date?: string | number | Date;
	onComplete?: () => void;
	onTick?: (time_to_end: number) => void;
}

export const Timer: React.FC<IProps> = ({date, onComplete, onTick, ...rest}) =>
	date ? (
		<HOCTimer onTick={onTick} onComplete={onComplete} date={date}>
			{({days, hours, minutes, seconds}) => {
				const has_days_or_hours = [days, hours].some(identity);

				return (
					<TimerContainer {...rest}>
						<Exist when={Boolean(days)}>
							<TimeUnit>
								<TimeUnitItem>{padStart(String(days), 2, "0")}</TimeUnitItem>
							</TimeUnit>
							<Flex>:</Flex>
						</Exist>
						<Exist when={has_days_or_hours}>
							<TimeUnit>
								<TimeUnitItem>{padStart(String(hours), 2, "0")}</TimeUnitItem>
							</TimeUnit>
							<Flex>:</Flex>
						</Exist>
						<TimeUnit>
							<TimeUnitItem>{padStart(String(minutes), 2, "0")}</TimeUnitItem>
						</TimeUnit>
						<Flex>:</Flex>
						<TimeUnit>
							<TimeUnitItem>{padStart(String(seconds), 2, "0")}</TimeUnitItem>
						</TimeUnit>
					</TimerContainer>
				);
			}}
		</HOCTimer>
	) : null;
