import {createReducer} from "redux-act";
import {ISport} from "modules/types";
import {SPORTS, SportType} from "modules/utils";
import {setSportName} from "modules/actions";

const initialState = {
	sportName: SportType.WBBL,
	isFormGuide: true,
};

export const sport = createReducer<ISport>({}, initialState).on(
	setSportName,
	(state, payload: SportType) => {
		const isFormGuide = SPORTS[payload].IS_FROM_GUIDE;
		return {
			...state,
			sportName: payload,
			isFormGuide,
		};
	}
);
