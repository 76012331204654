import {IShare} from "modules/types";
import {getSportName} from "modules/utils/helpers";

const PUBLIC_URL = process.env.PUBLIC_URL || "//uat-cricket-au-tipping.fanhubmedia.com";
const FB_SHARE_URL = process.env.REACT_APP_FB_SHARE_URL || "";
const protocol = window.location.protocol;

interface IShareObject {
	sport?: string;
	t: string;
	sc?: string;
	league_id?: number;
	user_id?: number;
	code?: string;
}

/**
 * in order to reduce the size of the string for sharing
 */
const last_four_number = -4;
const getTimestamp = () => Date.now().toString().substr(last_four_number);
const getEncodedJson = (object: IShareObject) => btoa(JSON.stringify(object));
const getLinkForShare = (object: IShareObject, type: string) => {
	const link = `${protocol}${PUBLIC_URL}/${FB_SHARE_URL}${getSportName()}/${type}/${getEncodedJson(
		object
	)}`;
	const sections: {[key: string]: () => string} = {
		general: () => link,
		league: () => link,
	};
	return sections[type]();
};
const share_messages = {
	general: (data: {sportName: string}) => {
		return `Pick the team you think will win each match this ${data.sportName.toUpperCase()} season and you could win some epic prizes. It’s free and easy to join!`;
	},
	league: (data: {name: string; code: string; sportName: string}) => {
		return `You think you can beat me in tipping this ${data.sportName.toUpperCase()} season? I’ll prove you wrong! Come join my league now. The league pin is ${
			data.code
		}`;
	},
};

export const share = (data: IShare) => {
	if (data.sc === "tw") {
		shareTwitter(data);
	}

	if (data.sc === "fb") {
		shareFB(data);
	}
};

export const shareFB = (data: IShare) => {
	const {section, league_data} = data;

	const object: IShareObject = {
		t: getTimestamp(),
	};

	if (league_data) {
		object.league_id = league_data.id;
	}

	window.FB?.ui(
		{
			method: "share",
			display: "popup",
			href: getLinkForShare(object, section),
		},
		() => {
			// Do nothing
		}
	);
};

export const shareTwitter = (data: IShare) => {
	const {section, league_data, sportName} = data;
	const object: IShareObject = {
		t: getTimestamp(),
	};

	if (league_data) {
		object.league_id = league_data.id;
	}

	const href = getLinkForShare(object, section);
	const text = share_messages[section]({
		name: league_data ? league_data.name : "",
		code: league_data ? league_data.code : "",
		sportName: sportName,
	});

	window.open(
		"https://twitter.com/share?url=" +
			encodeURIComponent(href) +
			"&text=" +
			encodeURIComponent(text),
		"twitter-share-dialog",
		"width=626,height=436"
	);
};
