import React, {useEffect} from "react";
import {Preloader} from "components/Preloader";
import {useDispatch, useSelector} from "react-redux";
import {isSessionChecked} from "modules/selectors";
import {userFetch, userLoginSSO} from "modules/actions";
import {useQuery, Storage, isAppUser, isBigBashUser} from "modules/utils";
import {allTrue} from "modules/utils/helpers";

const setIsAppUser = (isAppUser: boolean) => {
	isAppUser && Storage.SET("is_app_user", true);
};

const setIsBigBashAppUser = (isBigBashUser: boolean) => {
	isBigBashUser && Storage.SET("is_big_bash_app_user", true);
};

export const HOCSession: React.FC = ({children}) => {
	const dispatch = useDispatch();
	const query = useQuery();
	const code = query.get("code") || "";
	const cbState = query.get("state") || "";
	const verifier = Storage.GET("code_verifier") || "";
	const storedCBState = Storage.GET("myState") || "";
	const nonce = Storage.GET("nonce") || "";
	const isSessionCheck = useSelector(isSessionChecked);

	setIsAppUser(isAppUser);
	setIsBigBashAppUser(isBigBashUser);

	useEffect(() => {
		if (allTrue([code !== "", cbState === storedCBState])) {
			dispatch(
				userLoginSSO({
					code,
					verifier,
					nonce,
					mobile_app: isAppUser ? 1 : 0,
					big_bash_app: isBigBashUser ? 1 : 0,
				})
			);
		}
	}, [dispatch, code, verifier, cbState, storedCBState, nonce]);

	useEffect(() => {
		dispatch(userFetch());
	}, [dispatch]);

	return isSessionCheck ? <React.Fragment>{children}</React.Fragment> : <Preloader />;
};
