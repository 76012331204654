import React, {useCallback, useEffect, useState} from "react";
import chevronUp from "assets/img/icons/chevronUp.svg";
import chevronDown from "assets/img/icons/chevronDown.svg";
import styled, {css} from "styled-components";
import {useMediaQuery} from "modules/utils/hooks";

interface IWidgetTitle {
	show_arrow?: boolean;
	is_open?: boolean;
}

const addArrowWidgetTitle = ({show_arrow, is_open}: IWidgetTitle) =>
	show_arrow &&
	css`
		@media (max-width: 639px) {
			background-image: url(${is_open ? chevronUp : chevronDown});
			background-position: right center;
			background-repeat: no-repeat;
		}
	`;

const WidgetTitle = styled.div<IWidgetTitle>`
	color: #000000;
	background: none;
	border: none;
	outline: none;
	font-family: "InterFace Corp", sans-serif;
	font-size: 22px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 36px;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	button {
		height: 40px;
		background: none;
		outline: none;
		border: none;
	}

	@media (max-width: 639px) {
		cursor: pointer;
		font-size: 16px;
		line-height: 16px;
		margin-bottom: 0;
	}

	${addArrowWidgetTitle};
`;

interface ITitleProps {
	onToggle?: (value: boolean) => void;
	show_arrow?: boolean;
}

export const HOCLeagueMobileTitle: React.FC<ITitleProps> = ({
	onToggle,
	show_arrow = true,
	children,
}) => {
	const isMobile = useMediaQuery("(max-width: 639px)");
	const [is_open, setIsOpen] = useState(false);
	const handleIsOpen = useCallback(() => {
		if (isMobile) {
			setIsOpen(!is_open);
		}
	}, [isMobile, is_open]);

	useEffect(() => {
		if (onToggle) {
			onToggle(is_open);
		}
	}, [is_open, onToggle]);

	return (
		<WidgetTitle onClick={handleIsOpen} show_arrow={show_arrow} is_open={is_open}>
			{children}
		</WidgetTitle>
	);
};
