import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {get} from "lodash";
import {HOCModal} from "components/Modal";
import {Storage} from "modules/utils";
import {media} from "assets/css/media";
import {Navigate} from "react-router-dom";
import {theme} from "assets/themes/bbl";
import {useDispatch, useSelector} from "react-redux";
import {PrimaryButton} from "components";
import {Api as CricketAPI} from "modules/utils/Api";
import {getJoinModal, isLoggedIn, getLeaguesForJoin, getRoundByIdSelector} from "modules/selectors";
import {Exist} from "components/Exist";
import {hideJoinModal, joinToLeague, showRegistrationModal, showJoinModal} from "modules/actions";
import {getSportName} from "modules/utils/helpers";

const LeagueJoinModalStyled = styled(HOCModal)`
	z-index: 1000;
	@media screen and (max-width: ${media.mobile}) {
		display: flex;
		flex-flow: column nowrap;
		justify-content: space-between;
		align-items: center;
	}

	.modal-logo {
		width: 200px;
		height: auto;
		align-self: center;
		margin-top: -80px;
	}
	@media screen and (max-width: ${media.mobile}) {
		display: flex;
		flex-flow: column nowrap;
		justify-content: space-between;
		align-items: center;
		padding: 10px;
		.modal-logo {
			margin-top: 20px;
		}
	}
`;
const Text = styled.div`
	margin: 40px 0;
	font-family: ${theme.font.montserrat};
	color: ${theme.colors.primary};
	font-size: 16px;
	letter-spacing: 0;
	line-height: 22px;
	text-align: center;
`;
const LeagueName = styled.div`
	color: ${theme.colors.primary};
	margin-bottom: 40px;
	font-family: ${theme.font.montserrat};
	font-size: 24px;
	letter-spacing: 0;
	line-height: 20px;
	text-align: center;
`;
const JoinButton = styled(PrimaryButton)`
	max-width: 380px;
	margin: 0 auto 40px;
`;
const LeagueContainer = styled.div`
	max-width: 400px;
	width: 100%;
	padding: 20px;
	border-radius: 5px;
	background: #f3f3f3;
	margin: 0 auto 40px;
	@media screen and (max-width: 639px) {
		border-radius: 0px;
	}
`;
const LeagueTitle = styled.div`
	color: ${theme.colors.primary};
	margin-bottom: 22px;
	font-family: ${theme.font.montserrat};
	font-size: 16px;
	letter-spacing: 0;
	font-weight: 600;
	line-height: 20px;
	display: flex;
	justify-content: center;
`;
const LeagueData = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const LeagueItem = styled.div``;
const LeagueLabel = styled.div`
	margin-bottom: 12px;
	color: ${theme.colors.primary};
	font-family: ${theme.font.montserrat};
	font-weight: 600;
`;
const LeagueValue = styled.div`
	text-transform: capitalize;
	font-family: ${theme.font.montserrat};
	color: ${theme.colors.primary};
`;

const defaultLeague = {
	id: 0,
	name: "",
	startRound: 0,
	privacy: "",
};

interface IProps {
	gameText: string;
	logo: JSX.Element;
}

// tslint:disable-next-line:max-func-body-length
export const LeagueJoinModal: React.FC<IProps> = ({logo, gameText}) => {
	const dispatch = useDispatch();
	const [league, setLeague] = useState(defaultLeague);
	const {joinCode, isShow} = useSelector(getJoinModal);
	// const isMobile = useMediaQuery('screen and (max-width: ' + media.mobile + ')');
	const join_store = useSelector(getLeaguesForJoin);
	const isJoined = join_store.joined_codes[joinCode];
	const leagueStartRound = get(league, "startRound", 1);
	const startingRound = useSelector(getRoundByIdSelector)(leagueStartRound);
	const isUserLoggedIn = useSelector(isLoggedIn);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const fetchLeagueAbout = async () => {
		try {
			const {success, errors} = await CricketAPI.tipping_league.show_for_join({
				search: String(joinCode),
				page: 1,
			});
			if (!errors.length) {
				setLeague(success.leagues[0]);
			}
		} catch (e) {
			console.log(e);
		}
	};
	useEffect(() => {
		if (joinCode && league.id === 0) {
			void fetchLeagueAbout();
		}
	}, [fetchLeagueAbout, joinCode, league.id]);
	const closeHandler = () => {
		dispatch(hideJoinModal());
	};
	const joinHandler = () => {
		if (!isUserLoggedIn) {
			window.document.body.className = "modal";
			Storage.SET("joinCode", joinCode);
			dispatch(
				showJoinModal({
					code: joinCode,
				})
			);
			dispatch(showRegistrationModal());
			return;
		}
		dispatch(
			joinToLeague({
				code: String(joinCode),
			})
		);
	};

	if (isShow && isJoined) {
		dispatch(hideJoinModal());
		<Navigate to={`/${getSportName()}/league/${league.id}/about`} replace />;
	}

	return (
		<Exist when={isShow}>
			<LeagueJoinModalStyled onClose={closeHandler} isVisible={isShow}>
				{logo}
				<Text>Congrats - You've been invited to join a {gameText}!</Text>
				<LeagueName>{league.name}</LeagueName>
				<JoinButton onClick={joinHandler}>Join now</JoinButton>
				<LeagueContainer>
					<LeagueTitle>League Settings</LeagueTitle>
					<LeagueData>
						<LeagueItem>
							<LeagueLabel>Starting Round</LeagueLabel>
							<LeagueValue>{startingRound?.number}</LeagueValue>
						</LeagueItem>
						<LeagueItem>
							<LeagueLabel>Privacy:</LeagueLabel>
							<LeagueValue>{league.privacy}</LeagueValue>
						</LeagueItem>
					</LeagueData>
				</LeagueContainer>
			</LeagueJoinModalStyled>
		</Exist>
	);
};
