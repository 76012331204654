import {IStore} from "modules/types";
import {createSelector} from "reselect";

export const getForgotPasswordModal = ({modals: {forgotPasswordModal}}: IStore) =>
	forgotPasswordModal;

export const getLoginModal = ({modals: {loginModal}}: IStore) => loginModal;

export const getRegistrationModal = ({modals: {registrationModal}}: IStore) => registrationModal;

export const getJoinModal = ({modals: {joinModal}}: IStore) => joinModal;

export const getBonusPointModal = ({modals: {bonusPointModal}}: IStore) => bonusPointModal;

export const isForgotPasswordModalVisible = createSelector(
	getForgotPasswordModal,
	({isShow}) => isShow
);

export const isForgotPasswordRequestedSuccess = createSelector(
	getForgotPasswordModal,
	({success}) => Boolean(success)
);

export const getForgotPasswordRequestedError = createSelector(
	getForgotPasswordModal,
	({error}) => error
);

export const getConfirmModalState = ({modals}: IStore) => modals.showConfirmModal;

export const getShareModalState = ({modals: {shareModal}}: IStore) => shareModal;

export const isLoginModalVisible = createSelector(getLoginModal, ({isShow}) => isShow);

export const isRegistrationModalVisible = createSelector(
	getRegistrationModal,
	({isShow}) => isShow
);

export const isBonusPointModalVisible = createSelector(getBonusPointModal, ({isShow}) => isShow);
