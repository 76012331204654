import styled from "styled-components";
import lock from "assets/img/icons/lock.svg";
import {theme} from "assets/themes/bbl";

export const Input = styled.input`
	border: 1px solid #2c2c2c;
	border-radius: 2px;
	height: 45px;
	padding: 13px;
	&[type="radio"] {
		width: 50px;
	}
	font-family: ${theme.font.montserrat};
	font-weight: 400;
	color: ${theme.colors.primary};
	&:disabled {
		color: #696969;
		background: url(${lock}) right 10px center no-repeat;
		padding: 0 30px 0 10px;
	}

	&::placeholder,
	&::-ms-input-placeholder,
	&:-ms-input-placeholder {
		color: #222 !important;
	}

	&.error {
		color: #dc1515;
		border: 2px solid #dc1515;
	}
`;

export const InputGroup = styled.div`
	width: 100%;
	position: relative;
	margin-bottom: 20px;

	> input {
		margin-bottom: 0;
	}
`;

interface IIcon {
	icon: string;
	right?: boolean;
}

export const InputGroupIcon = styled.div<IIcon>`
	background: url(${(props) => props.icon});
	position: absolute;
	width: 24px;
	height: 24px;
	top: 50%;
	transform: translateY(-50%);
	${(props) => (props.right ? "right: 11px;" : "left: 11px;")};
	border: 0;
	padding: 0;
	margin: 0;
`;
