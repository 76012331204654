import {SagaIterator} from "redux-saga";
import {call, put} from "typed-redux-saga/macro";
import {Api} from "modules/utils/Api";
import * as actions from "modules/actions";
import {ApiError} from "modules/utils";
import {ISagaAction, IFetchUserTipsPayload} from "modules/types";

export const fetchSnapshotSaga = function* ({payload}: ISagaAction<{round: number}>): SagaIterator {
	try {
		const response = yield* call(Api.tipping_stats.show_snapshot, payload);
		ApiError.CHECK(response);
		if (response.success) {
			yield* put(actions.fetchSnapshotSuccess(response.success.snapshot));
		}
	} catch (err) {
		yield* put(actions.globalError(err));
	}
};

export const fetchOtherUserTipsSaga = function* ({
	payload,
}: ISagaAction<IFetchUserTipsPayload>): SagaIterator {
	try {
		const response = yield* call(Api.tipping_stats.show_user_tips, payload);
		ApiError.CHECK(response);
		const successPayload = {
			round: payload.roundId,
			user: payload.userId,
			predictions: response.success.predictions,
		};

		return yield* put(actions.fetchOtherUserTipsSuccess(successPayload));
	} catch (err) {
		yield* put(actions.globalError(err));
	}
};
