export enum MatchStatus {
	Scheduled = "scheduled",
	Active = "active",
	Complete = "complete",
}

export enum RoundStatus {
	Scheduled = "scheduled",
	Active = "active",
	Complete = "complete",
}

export enum LeagueStatus {
	Scheduled = "scheduled",
	Playing = "playing",
	Complete = "complete",
}

export enum EnumLandingPages {
	Login = "login",
	Registration = "registration",
	ForgotPassword = "forgot-password",
	BBL = "bbl",
	WBBL = "wbbl",
}

export enum SportType {
	BBL = "bbl",
	WBBL = "wbbl",
}

export enum GameType {
	Tipping = "tipping",
}

export enum PrivacyStatus {
	Private = "private",
	Public = "public",
}

export enum LoadingStatus {
	Idle = "idle",
	Failed = "failed",
	Loading = "loading",
	Success = "success",
}
