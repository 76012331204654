import axios, {AxiosError} from "axios";
import {Storage} from "modules/utils";
import {API_URL, JSON_URL, SPORTS} from "modules/utils/constants";
import {isObject, keys, set, isEmpty, omit} from "lodash";
import {
	IApiResponse,
	IContactPayload,
	ICreateLeaguePayload,
	IDeleteLeaguePayload,
	IDeleteUserPayload,
	IDeleteUsersPayload,
	IDictionary,
	IEditUserPayload,
	IHelpState,
	IInviteEmails,
	IJoinedUsersPayload,
	ILadder,
	ILadderRequest,
	ILeague,
	ILeagueEntryActionsPayload,
	IPick,
	IPicksPayload,
	IPicksSavePayload,
	IPWDResetParams,
	IPWDResetRequestParams,
	IRankingsItem,
	IRankingsRequest,
	IRound,
	IRoundStats,
	ISendEmailsPayload,
	ISquad,
	IUpdateLeaguePayload,
	IUser,
	IUserCommunicationsUpdatePayload,
	IUserLoginPayload,
	IUserLoginSSOPayload,
	IUserPasswordUpdatePayload,
	IUserRegisterForGamePayload,
	IUserRegistrationPayload,
	IUserRegistrationSSOPayload,
	IUserUpdatePayload,
	IVenueElement,
	TState,
	IFormGuide,
	IUserPreregisterPayload,
	IUserRecoverForGamePayload,
	IDashboardItem,
	IMRECSuccess,
	INotificationBar,
	IJoinedUsersSuccess,
	ISnapshot,
	IUserPrediction,
} from "modules/types";
import {HTTPClient as HTTPClientCore, IRequestConfig} from "./httpClient";
import {SportType} from "modules/utils/enums";
import {ApiError} from "modules/utils/Api/ApiError";
import {CANCEL} from "redux-saga";
import {allTrue} from "modules/utils/helpers";

class HTTPClient extends HTTPClientCore {
	/**
	 * Overridden method adds CancelToken symbol, that allow redux-saga'
	 * "takeLatest" function to cancel any requests automatically.
	 * http://fe-common-utils.s3-website-eu-west-1.amazonaws.com/classes/httpclient.html
	 */
	public makeRequest<T>(config: IRequestConfig): Promise<T> {
		const source = axios.CancelToken.source();

		const request = super.makeRequest<T>({
			...config,
			cancelToken: source.token,
		});

		return set<Promise<T>>(request, CANCEL, () => source.cancel());
	}
}

const onCatchNetworkError = ({response, message = "Network error"}: AxiosError<ApiError>) => {
	const data = response?.data;
	const error = isObject(data)
		? data
		: {
				errors: [new ApiError(message)],
		  };

	return Promise.reject(error).catch((err) => ApiError.CHECK(err));
};

const defaultAPI = SPORTS.defaults;

const APIClient = new HTTPClient({
	baseURL: API_URL,
	withCredentials: true,
	onCatchNetworkError,
});

const JSONClient = new HTTPClient({
	baseURL: JSON_URL + defaultAPI.JSON,
});

const GlobalJSONClient = new HTTPClient({
	baseURL: JSON_URL,
});

const getSportName = () => {
	const storedSport = Storage.GET("sport_select") || "";
	const currentSport = window.location.pathname.split("/")[1];
	const sportNameInPath = keys(SPORTS).find((sport) => currentSport === sport);
	if (allTrue([sportNameInPath !== storedSport, !isEmpty(sportNameInPath)])) {
		const sportSelect = sportNameInPath || "";
		Storage.SET("sport_select", sportSelect);
	}
	if (storedSport.length > 0 && isEmpty(sportNameInPath)) {
		return storedSport;
	}
	return sportNameInPath || SportType.WBBL;
};

export const setSportURL = () => {
	const sport = getSportName();
	JSONClient.extendDefaults({
		baseURL: JSON_URL + sport,
	});
};

type TUserResponse = IApiResponse<{user: IUser}>;
type TUserPreregisterResponse = IApiResponse<{message: ""}>;
type TTipsResponse = IApiResponse<{predictions: IPick[]}>;
type TShowForJoinResponse = IApiResponse<{leagues: ILeague[]; next: boolean}>;
type TRankingsResponse = IApiResponse<{rankings: IRankingsItem[]; nextPage: boolean}>;

type IDashboardSuccessResponse = IApiResponse<{sports: IDashboardItem[]}>;
type IJoinLeagueResponse = IApiResponse<{league: ILeague}>;
type ICreateLeagueResponse = IJoinLeagueResponse;
type IShowLeagueResponse = IApiResponse<{
	isJoined: boolean;
	league: ILeague;
}>;
type IShowMyLeaguesResponse = IApiResponse<{leagues: ILeague[]}>;
type IShowJoinedUsersResponse = IApiResponse<IJoinedUsersSuccess>;
type IPickSaveResponse = IApiResponse<{predictions: IPick[]}>;
type ISnapshotResponse = IApiResponse<{snapshot: ISnapshot}>;
type IUserPredictionsResponse = IApiResponse<{predictions: IUserPrediction[]}>;
type ICountryResponse = IApiResponse<{isoCode: string}>;
export const Api = {
	JSON: {
		checksums: () => GlobalJSONClient.get<IDictionary<string>>("checksums.json"),
		faq_bbl: () => GlobalJSONClient.get<IHelpState["faq"]>(`bbl/faqs.json`),
		faq_wbbl: () => GlobalJSONClient.get<IHelpState["faq"]>(`wbbl/faqs.json`),
		help_pages_bbl: () => GlobalJSONClient.get<IHelpState>(`bbl/help_pages.json`),
		help_pages_wbbl: () => GlobalJSONClient.get<IHelpState>(`wbbl/help_pages.json`),
		rounds: () => JSONClient.get<IRound[]>("rounds.json"),
		states: () => GlobalJSONClient.get<IDictionary<TState>>("states.json"),
		squads: () => JSONClient.get<ISquad[]>("squads.json"),
		ladder: () => JSONClient.get<ILadder[]>("ladder.json"),
		mrec: () => JSONClient.get<IMRECSuccess[]>(`mrecs.json`),
		notification_bar: () =>
			GlobalJSONClient.get<{notifications: INotificationBar[]}>("notification_bar.json"),
		round_stats: ({round}: IPicksPayload) =>
			JSONClient.get<IRoundStats[]>(`roundstats/${round || 0}.json`),
		form_guide: () => JSONClient.get<IDictionary<IFormGuide>>(`formguide.json`),
		venues: () => JSONClient.get<IVenueElement[]>("venues.json"),
	},
	Contact: {
		contact: (params: IContactPayload) => APIClient.post(`contact`, params),
	},
	User: {
		show_my: () => APIClient.get<TUserResponse>(`${getSportName()}/user`),
		update: (
			params:
				| IUserUpdatePayload
				| IUserPasswordUpdatePayload
				| IUserCommunicationsUpdatePayload
		) => APIClient.post<TUserResponse>(`${getSportName()}/user`, params),
		deactivate: () => APIClient.post<IApiResponse>(`user/deactivate`),
	},
	Auth: {
		login: (params: IUserLoginPayload) => APIClient.post<TUserResponse>(`auth/login`, params),
		login_sso: (params: IUserLoginSSOPayload) =>
			APIClient.post<TUserResponse>(`${getSportName()}/auth/login`, params),
		logout: () => APIClient.post<IApiResponse>(`${getSportName()}/auth/logout`),
		register: (params: IUserRegistrationPayload) =>
			APIClient.post<TUserResponse>(`auth/register`, params),
		preregister: (params: IUserPreregisterPayload) =>
			APIClient.post<TUserPreregisterResponse>(`/preregistration`, params),
		register_sso: (params: IUserRegistrationSSOPayload) =>
			APIClient.post<TUserResponse>(`${getSportName()}/auth/register`, params),
		register_for_game: (params: IUserRegisterForGamePayload) =>
			APIClient.post<TUserResponse>(`${getSportName()}/auth/register_for_game`, params),
		recover_user: (params: IUserRecoverForGamePayload) =>
			APIClient.post<TUserResponse>(
				`${getSportName()}/${getSportName()}/user/recover`,
				params
			),
		password_reset_request: (params: IPWDResetRequestParams) =>
			APIClient.post<IApiResponse>(`auth/password_reset/request`, params),
		password_reset: (params: IPWDResetParams) =>
			APIClient.post<IApiResponse>(`auth/password_reset`, params),
	},
	tip: {
		show_my_list: ({round}: IPicksPayload) =>
			APIClient.get<TTipsResponse>(`${getSportName()}/predictions/${round || 0}`),
		autopick: (params: {predictions: {match: number}[]}) =>
			APIClient.post<IPickSaveResponse>(
				`${getSportName()}/predictions_list/autopick`,
				params
			),
		save: (params: IPicksSavePayload) => APIClient.post(`${getSportName()}/tips`, params),
		save_list: (params: {predictions: IPick[]}) =>
			APIClient.post<IPickSaveResponse>(`${getSportName()}/predictions_list`, params),
		clear_list: (params: {predictions: {match: number}[]}) =>
			APIClient.post<IPickSaveResponse>(`${getSportName()}/predictions_list/clear`, params),
	},
	tipping_league: {
		create: (params: ICreateLeaguePayload) =>
			APIClient.post<ICreateLeagueResponse>(`${getSportName()}/league`, params),
		show: (params: {id: number}) =>
			APIClient.get<IShowLeagueResponse>(`${getSportName()}/league/${params.id || 0}`),
		update: ({id, ...params}: IUpdateLeaguePayload) =>
			APIClient.post<IShowLeagueResponse>(`${getSportName()}/league/${id || 0}`, params),
		show_my: () => APIClient.get<IShowMyLeaguesResponse>(`${getSportName()}/leagues`),
		ladder: (params: ILadderRequest) => {
			const id = params.id;
			const newParams = omit(params, "id");
			return APIClient.get<TRankingsResponse>(
				`${getSportName()}/rankings/${id || 0}/rankings`,
				newParams
			);
		},
		delete: (params: IDeleteLeaguePayload) =>
			APIClient.post<IApiResponse>(`${getSportName()}/league/${params.id}/delete`),
		send_emails: (params: ISendEmailsPayload) =>
			APIClient.post<IApiResponse>(`${getSportName()}/league/send_emails`, params),
		show_for_join: (params: {search?: string; page: number | null}) =>
			APIClient.get<TShowForJoinResponse>(`${getSportName()}/league/show-for-join`, params),
		invite: (params: IInviteEmails) =>
			APIClient.post<IApiResponse>(
				`${getSportName()}/league/${params.league_id || 0}/invite`,
				{
					invites: params.invites,
				}
			),
		leave: (params: ILeagueEntryActionsPayload) =>
			APIClient.post<IApiResponse>(`${getSportName()}/league/${params.league_id || 0}/leave`),
	},
	tipping_join: {
		join: (params: {code: string}) =>
			APIClient.post<IJoinLeagueResponse>(`${getSportName()}/league/${params.code}/join`),
		show_joined_users: (params: IJoinedUsersPayload) =>
			APIClient.get<IShowJoinedUsersResponse>(
				`${getSportName()}/league/${params.league_id}/user/show-joined?page=${
					params.page || 1
				}`
			),
		remove: (params: IDeleteUserPayload) =>
			APIClient.post<IApiResponse>(
				`${getSportName()}/league/${params.league_id}/user/${params.user_id}`
			),
		remove_multi: (params: IDeleteUsersPayload) =>
			APIClient.post<IApiResponse>(`tipping_join/delete_multi`, params),
		edit_users: (params: IEditUserPayload) =>
			APIClient.post<IApiResponse>(`tipping_join/edit_users`, params),
	},
	tipping_stats: {
		show_snapshot: (params: {round: number}) =>
			APIClient.post<ISnapshotResponse>(`${getSportName()}/predictions/snapshot`, params),
		show_user_rankings: (params: IRankingsRequest) =>
			APIClient.get<TRankingsResponse>(`${getSportName()}/rankings/overall/rankings`, params),
		show_user_tips: (params: {roundId: number; userId: number}) =>
			APIClient.get<IUserPredictionsResponse>(
				`${getSportName()}/predictions/${params.roundId}/user/${params.userId}`
			),
	},
	dashboard: {
		dashboard: () => APIClient.get<IDashboardSuccessResponse>(`tipping/dashboard`),
	},
	geo_ip: {
		country: () => APIClient.get<ICountryResponse>(`country/canada_state`),
	},
};

export * from "./ApiError";
