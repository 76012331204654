import * as React from "react";
import {IIconProps} from "modules/types";

export const IconPlus: React.FC<IIconProps> = ({title, width, height, color, onClick}) => (
	<svg
		width={`${width || 0}px`}
		height={`${height || 0}px`}
		viewBox="0 0 14 14"
		onClick={onClick}>
		<title>{title}</title>
		<g transform="translate(-5.000000, -5.000000)" fill={color}>
			<polygon points="19 11 13 11 13 5 11 5 11 11 5 11 5 13 11 13 11 19 13 19 13 13 19 13" />
		</g>
	</svg>
);

IconPlus.defaultProps = {
	title: "Add",
	width: 14,
	height: 14,
	color: "#005A73",
};
