import React from "react";
import styled from "styled-components";

interface IProps {
	width?: number;
	height?: number;
	color?: string;
}

const SVG = styled.svg``;

export const IconWarning: React.FC<IProps> = ({color, ...rest}) => (
	<SVG viewBox="0 0 10 9" fill="none" {...rest} width="10" height="9">
		<path
			fill="#F63A25"
			fillRule="evenodd"
			d="M4.5 5.5h1V3h-1v2.5zm0 2h1v-1h-1v1zM5 0L0 9h10L5 0z"
			clipRule="evenodd"
		/>
	</SVG>
);
IconWarning.defaultProps = {
	width: 10,
	height: 9,
	color: "currentColor",
};
