import styled from "styled-components";

export const PageTitle = styled.h2`
	color: #000000;
	font-family: "InterFace Corp", sans-serif;
	font-size: 24px;
	font-weight: bold;
	line-height: 36px;
	margin-bottom: 20px;
`;
