import React from "react";
import styled from "styled-components";

interface IProps {
	width?: number;
	height?: number;
	color?: string;
}

const SVG = styled.svg``;

export const IconBKT: React.FC<IProps> = ({color, ...rest}) => (
	<SVG viewBox="0 0 170 59" fill="none" {...rest} width="170" height="59" className="bkt-sponsor">
		<path
			fill="#80C41C"
			d="M36.891 58.084c9.635 0 15.999-6.05 17.48-15.453L60.954 0H24.07C14.416 0 8.04 6.05 6.583 15.483L0 58.072l36.891.012z"></path>
		<path
			fill="#fff"
			d="M12.431 11.874h24.502c10.34 0 14.523 2.596 15.127 7.545.528 4.323-2.133 7.146-7.703 8.265h-.065v.095c4.492.673 7.662 3.698 8.047 6.872 1.001 8.26-7.804 11.529-18.215 11.529H6.714l5.717-34.306zm9.985 26.136h9.41c2.6 0 6.12-.673 5.782-3.46-.314-2.543-2.962-2.93-6.032-2.93h-8.07l-1.09 6.39zm2.115-12.78h8.254c2.335 0 5.197-.595 4.936-2.739-.26-2.143-2.234-2.447-4.835-2.447h-7.52l-.835 5.187z"></path>
		<path
			fill="#33A02C"
			d="M91.014 58.084c9.634 0 15.998-6.05 17.485-15.453L115.077 0H78.191c-9.64 0-15.998 6.056-17.486 15.483l-6.589 42.589 36.898.012z"></path>
		<path
			fill="#fff"
			d="M64.622 11.88h14.345L77.12 23.164l.136.096L91.82 11.88h18.653L89.912 26.005 105.75 46.18H89.639L79.513 32.87l-4.504 3.27-1.611 10.04H59.046l5.576-34.3z"></path>
		<path
			fill="#015F27"
			d="M145.136 58.084c9.635 0 15.999-6.05 17.486-15.453L169.199 0h-36.868c-9.64 0-16.028 6.056-17.485 15.483l-6.584 42.619 36.874-.018z"></path>
		<path
			fill="#fff"
			d="M132.142 20.67h-13.208l1.464-8.79h41.098l-1.464 8.79h-13.213l-4.231 25.51h-14.677l4.231-25.51z"></path>
	</SVG>
);
IconBKT.defaultProps = {
	width: 170,
	height: 59,
	color: "currentColor",
};
