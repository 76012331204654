import styled from "styled-components";
import React, {useEffect} from "react";
import {get, isEmpty} from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {fetchMREC} from "modules/actions";
import {getMREC, getLeaderboard} from "modules/selectors";
import {IMAGES_URL} from "modules/utils/constants";
import {media} from "assets/css/media";
import {useMediaQuery} from "modules/utils";

export const AdvertRectangleWrapper = styled.div`
	width: 320px;
	height: 50px;
	margin: 20px auto;
	position: relative;
	background-color: white;
	overflow: hidden;
	a,
	img {
		width: 100%;
		height: 100%;
	}

	@media screen and (min-width: ${media.mobile}) {
		width: 728px;
		height: 90px;
	}
`;

export const AdvertMRECWrapper = styled.div`
	width: 300px;
	height: 250px;
	margin: 20px auto;
	position: relative;
	background-color: white;
	overflow: hidden;
	a,
	img {
		width: 100%;
		height: 100%;
	}
	@media screen and (max-width: ${media.mobile}) {
		display: none;
	}
`;

interface IProps {
	id: string;
}

export const AdvertRectangle: React.FC<IProps> = ({id}) => {
	const dispatch = useDispatch();
	const isMobile = useMediaQuery("(max-width: 640px)");
	const leaderboard = useSelector(getLeaderboard);
	const leaderboardWeb: string = get(leaderboard, "desktopImage", "");
	const leaderboardMob: string = get(leaderboard, "mobileImage", "");
	const link: string = get(leaderboard, "url", "");
	const image = isMobile ? leaderboardMob : leaderboardWeb;
	const imageURL = `${IMAGES_URL}image/${image}`;

	useEffect(() => {
		dispatch(fetchMREC());
	}, [dispatch]);

	if (isEmpty(image)) {
		return null;
	}

	return (
		<AdvertRectangleWrapper id={id}>
			<a href={link} target="_blank" rel="noreferrer">
				<img src={imageURL} alt="MREC" />
			</a>
		</AdvertRectangleWrapper>
	);
};

export const AdvertMREC: React.FC<IProps> = ({id}) => {
	const dispatch = useDispatch();
	const mrec = useSelector(getMREC);
	const mrecImage: string = get(mrec, "desktopImage", "");
	const mrecLink: string = get(mrec, "url", "");
	const imageURL = `${IMAGES_URL}image/${mrecImage}`;

	useEffect(() => {
		dispatch(fetchMREC());
	}, [dispatch]);

	if (isEmpty(mrecImage)) {
		return null;
	}

	return (
		<AdvertMRECWrapper id={id}>
			<a href={mrecLink} target="_blank" rel="noreferrer">
				<img src={imageURL} alt="MREC" />
			</a>
		</AdvertMRECWrapper>
	);
};
