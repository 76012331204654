/* eslint-disable complexity */
/* eslint-disable no-empty */
import {SagaIterator} from "redux-saga";
import {call, put, select, take} from "typed-redux-saga/macro";
import {Api} from "modules/utils/Api";
import {ApiError} from "modules/utils";
import {getRankingsStore, getIsShowByOverall} from "modules/selectors";
import {IRankingsRequest, ISagaAction} from "modules/types";
import * as actions from "modules/actions";
import {concat} from "lodash";
import {IActionGetRankings, IRankingsItem} from "modules/reducers/rankings";

export const apiGetRankings = function* (options: IRankingsRequest): SagaIterator {
	const response = yield* call(Api.tipping_stats.show_user_rankings, options);
	ApiError.CHECK(response);
	return response.success;
};

export const getRankingsSaga = function* (action: ISagaAction<IActionGetRankings>): SagaIterator {
	try {
		const LIMIT = 20;

		const {round, q, offset} = action.payload;

		const options: IRankingsRequest = {
			search: q,
			page: offset,
			limit: LIMIT,
		};
		const isShowOvr = yield* select(getIsShowByOverall);

		if (!isShowOvr) {
			options.round = round;
		}

		const result = yield* call(apiGetRankings, options);

		const preLadder = yield* select(getRankingsStore);
		if (result && result.rankings) {
			// if it isnt offset of 1 it means we are loading extras in, so on search we need to assign page = 1
			// each time we type to ensure it will fetch fresh data
			// only combine previous store and incoming rankings if page !== 1 otherwise its a new req
			const isNewRankings = offset === 1;
			const newItems: IRankingsItem[] = isNewRankings
				? result.rankings
				: concat(preLadder.items, result.rankings);

			const next = result.nextPage;

			yield* put(
				actions.getRankingsSuccess({
					items: newItems,
					nextPage: next,
					q,
				})
			);

			if (next) {
				yield* take(actions.getRankingsMore);
			}
		}
	} catch (err) {
		yield* put(actions.getRankingsFailed());
		yield* put(actions.globalError(err));
	}
};
