import styled, {css} from "styled-components";
import iconChevronDown from "assets/img/icons/chevronDown.svg";
import iconDatepicker from "assets/img/icons/iconDatepicker.svg";
import {theme} from "assets/themes/bbl";

export const Label = styled.label`
	color: #000;
	font-family: "InterFace Corp", sans-serif;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 14px;
	margin-bottom: 10px;
	text-transform: uppercase;
	display: block;
`;

export const LabelRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
	width: 100%;

	${Label} {
		margin-bottom: 0;
	}
`;

export const Select = styled.select`
	box-sizing: border-box;
	padding: 0 30px 0 10px;
	display: inline-flex;
	font-size: 14px;
	height: 46px;
	outline: none;
	color: #000;
	border: 1px solid #4f5362;
	border-radius: 4px;
	appearance: none;
	width: 100%;
	margin-bottom: 20px;
	font-family: ${theme.font.montserrat};
	font-weight: 400;
	color: ${theme.colors.primary};
	background: url(${iconChevronDown}) no-repeat right 8px center #fff;
	&::-ms-expand {
		display: none;
	}
	&.capitalize {
		text-transform: capitalize;
	}

	&.error {
		color: #dc1515;
		border: 2px solid #dc1515;
	}
`;

export const FormRow = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
	position: relative;
	.MuiTextField-root {
		color: black;
	}
	.MuiOutlinedInput-notchedOutline {
		border-color: black;
	}
	.MuiInputLabel-formControl {
		top: -5px;
	}
	.MuiInputLabel-outlined.MuiInputLabel-shrink {
		top: 0px;
	}
`;

export const FormColumn = styled.div<{
	showBold: boolean;
}>`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 20px;
	position: relative;
	.MuiRadio-colorSecondary.Mui-checked {
		color: black;
	}
	.MuiRadio-colorPrimary {
		color: black;
	}
	span {
		font-family: ${theme.font.montserrat};
		font-size: 14px;
		font-weight: ${({showBold}) => (showBold ? "600" : "400")};
		color: #000;
	}
	.MuiFormControlLabel-root {
		height: 35px;
	}
`;
export const SearchLabel = styled.label`
	color: ${theme.colors.secondary};
	font-family: ${theme.font.montserrat};
	font-size: 14px;
	letter-spacing: 0;
	margin: 0 0 10px 0;
	text-align: left;
	height: 15px;
`;

export const FormCell = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
	flex-direction: column;
	position: relative;
	width: 100%;
	&:first-of-type {
		margin-right: 20px;
	}

	&.small {
		width: 130px;
		margin-left: 20px;
	}

	.react-datepicker-wrapper {
		width: 100%;

		input {
			padding: 0 10px;
			margin-bottom: 20px;
			border: 1px solid #4f5362;
			border-radius: 4px;
			background: url(${iconDatepicker}) no-repeat right 10px center #fff;
			width: 100%;
			height: 46px;
			box-sizing: border-box;
			font-family: Arial, sans-serif;

			&.error {
				color: #dc1515;
				border: 2px solid #dc1515;
			}
		}
	}
`;

interface IElementWrapperProps {
	is_disabled?: boolean;
}
const getDisabledElementWrapper = ({is_disabled}: IElementWrapperProps) =>
	is_disabled &&
	css`
		> * {
			opacity: 0.7;
			cursor: not-allowed;
		}
	`;

export const ElementsWrapper = styled.div<IElementWrapperProps>`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	flex-direction: column;
	> input,
	select {
		width: 100%;
	}
	${getDisabledElementWrapper};
	@media (max-width: 639px) {
		align-items: initial;
	}
`;

export * from "./Checkbox";
export * from "./SimpleCheckbox";
export * from "./Radio";
export * from "./Input";
export * from "./Textarea";
export * from "./FormErrorMessage";
export * from "./FormSubTitle";
export * from "./FormRadioButtons";
export * from "./FormTitle";
export * from "./Switcher";
export * from "./SwitchWrap";
export * from "./InputField";
export * from "./SwitchLeftLabel";
export * from "./SwitchRightLabel";
export * from "./ErrorLabel";
// SwitchLeftLabel,
// 	SwitchRightLabel,
// 	LoadingButton,
// 	ErrorLabel,
// 	IconCheck
