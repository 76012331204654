import React from "react";
import styled from "styled-components";
import {size, get} from "lodash";
import {useSelector, useDispatch} from "react-redux";
import {
	saveTip,
	postAutoPick,
	postClearPicks,
	showConfirmModal,
	hideConfirmModal,
} from "modules/actions";
import {
	getSelectedRoundID,
	getMergedTipsWithMatchesSelector,
	getRoundByIdSelector,
	getPicksState,
} from "modules/selectors";
import {StatBlock} from "components/GameBar/Snapshot";
import {WithSidebarContainer, HOCPromptModal, HOCConfirmModal} from "components";
import {IconWarning} from "components/Icons/IconWarning";
import {IconCheck} from "components/Icons/IconCheck";
import {useMediaQuery} from "modules/utils";
import {CURR_SPORT_GOLDEN_WEEK} from "modules/utils/helpers";
import {theme} from "assets/themes/bbl";

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	position: sticky;
	bottom: 0;
	width: 100vw;
	height: 80px;
	background: white;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
	z-index: 2;
`;

const WebWrapper = styled(Wrapper)`
	width: 100%;
	padding-left: 20px;
`;

const ContentWrapper = styled.div`
	width: 840px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

const EmptyAside = styled.aside`
	&& {
		width: 100%;
		margin-left: 0;

		@media (min-width: 840px) {
			width: 300px;
			margin-left: 60px;
		}
	}
`;

const ControlButton = styled.button<{
	isGoldenWeek: boolean;
}>`
	width: 120px;
	height: 40px;
	color: ${theme.colors.primary};
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: ${theme.font.montserrat};
	font-size: 14px;
	line-height: 16px;
	font-weight: 600;
	border: 1px solid ${theme.colors.primary};
	outline: none;
	border-radius: 2px;
	margin: 0 20px;
	background: transparent;
	${({isGoldenWeek}) =>
		isGoldenWeek &&
		`
		border: 1px solid ${theme.colors.gold};
		color: ${theme.colors.gold};
	`}
	&:disabled {
		background-color: #f1f1f1;
	}
	@media (max-width: 640px) {
		width: 80px;
	}
`;

const SaveButton = styled(ControlButton)`
	background: ${theme.colors.tertiary};
	color: #000;
	border: none;
	margin-left: 0;
	${({isGoldenWeek}) =>
		isGoldenWeek &&
		`
		color: white;
		background-color: ${theme.colors.gold};
	`}
`;

const ButtonWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const getSaveButtonText = (saving: boolean, saved: boolean) => {
	return saving ? "Saving" : saved ? "Saved" : "Save";
};

export const ClearPicksModal: React.FC = () => {
	const dispatch = useDispatch();
	const clear = () => {
		dispatch(postClearPicks());
		dispatch(hideConfirmModal());
	};
	return (
		<HOCConfirmModal onConfirm={clear}>
			Are you sure you want to clear all picks made?
		</HOCConfirmModal>
	);
};

interface IProps {
	isWeb?: boolean;
}

// interface IConfirmCancel {
// 	onConfirm: () => void;
// 	onCancel: () => null | void;
// }

export const TippingControlsBottom: React.FC<IProps> = ({isWeb}) => {
	const dispatch = useDispatch();
	const isMobile = useMediaQuery("(max-width: 639px)");
	const selectedRoundId = useSelector(getSelectedRoundID);
	const selectedRound = useSelector(getRoundByIdSelector)(selectedRoundId);
	const selectedRoundNumber = get(selectedRound, "number", 1);
	const picks = useSelector(getMergedTipsWithMatchesSelector)(selectedRound);
	const pickedSize = picks.filter(({squad}) => squad).length;
	const isOnePicked = pickedSize >= 1;
	const matches = get(selectedRound, "matches");
	const isGoldenWeek = selectedRoundNumber === Number(CURR_SPORT_GOLDEN_WEEK);
	const picksState = useSelector(getPicksState);

	const {can_save, saving, saved} = picksState;
	const check_round_complete_save = can_save && selectedRound?.status !== "complete";
	const saveButtonText = getSaveButtonText(saving, saved);

	const selectedPicksLabel = "Selected Picks";

	const selectedValueMobile = `${pickedSize}/${size(matches)}`;

	const isFullyPicked = pickedSize === size(matches);

	const showIcon = !isFullyPicked ? <IconWarning /> : <IconCheck />;

	const onSave = () => {
		dispatch(saveTip());
	};
	const onAutoPick = () => {
		if (!isFullyPicked) {
			dispatch(postAutoPick());
		}
	};
	const onClearPicks = () => {
		dispatch(showConfirmModal());
	};

	const saveDisabled = !check_round_complete_save || !isOnePicked;

	const promptMessage =
		`You have only entered a tip for` +
		` ${selectedValueMobile} Games! Please select all your` +
		` tips before leaving the site`;
	const PromptComponent = () => (
		<HOCPromptModal isBlocking={!isFullyPicked}>
			<React.Fragment>{promptMessage}</React.Fragment>
		</HOCPromptModal>
	);
	if (isWeb) {
		return (
			<WebWrapper>
				<ContentWrapper>
					<StatBlock
						label={selectedPicksLabel}
						value={pickedSize}
						maxValue={size(matches)}
						icon={showIcon}
						selectedMobile={isMobile}
					/>
					<ButtonWrapper>
						{!isFullyPicked ? (
							<ControlButton
								isGoldenWeek={isGoldenWeek}
								onClick={onAutoPick}
								disabled={isFullyPicked}>
								Autopick
							</ControlButton>
						) : (
							<ControlButton isGoldenWeek={isGoldenWeek} onClick={onClearPicks}>
								Clear All
							</ControlButton>
						)}
						<SaveButton
							isGoldenWeek={isGoldenWeek}
							onClick={onSave}
							disabled={saveDisabled}>
							{saveButtonText}
						</SaveButton>
					</ButtonWrapper>
				</ContentWrapper>
				<PromptComponent />
			</WebWrapper>
		);
	}

	return (
		<Wrapper>
			<WithSidebarContainer>
				<ContentWrapper>
					<StatBlock
						label={selectedPicksLabel}
						value={selectedValueMobile}
						maxValue={size(matches)}
						icon={showIcon}
						selectedMobile={isMobile}
					/>
					<ButtonWrapper>
						{!isFullyPicked ? (
							<ControlButton
								isGoldenWeek={isGoldenWeek}
								onClick={onAutoPick}
								disabled={isFullyPicked}>
								Autopick
							</ControlButton>
						) : (
							<ControlButton isGoldenWeek={isGoldenWeek} onClick={onClearPicks}>
								Clear All
							</ControlButton>
						)}
						<SaveButton
							isGoldenWeek={isGoldenWeek}
							onClick={onSave}
							disabled={saveDisabled}>
							{saveButtonText}
						</SaveButton>
					</ButtonWrapper>
				</ContentWrapper>
				<EmptyAside />
			</WithSidebarContainer>
		</Wrapper>
	);
};
