import {IDictionary, IMergedPick, IRound, IStore, IPick} from "modules/types";
import {createSelector} from "reselect";
import {keyBy, find, memoize} from "lodash";

export const getState = (state: IStore) => state;

export const getPicksState = (state: IStore) => state.picks;

export const getTips = createSelector(getPicksState, (picks) => picks.tips);
export const getTipsById = createSelector(getTips, (tips) => keyBy(tips, "match"));
export const getMergedTipsWithMatchesSelector = createSelector(
	getTipsById,
	(tips: IDictionary<IPick>) =>
		memoize((round?: IRound) => {
			if (!round) {
				return [];
			}

			return round.matches.map((match) => {
				const tip = tips[match.id];
				const margin = tip ? tip.margin : 0;
				return {
					...match,
					...tip,
					margin,
				};
			}) as IMergedPick[];
		})
);

export const getMergedTipsMatchesSelectedRound = (selectedRound: IRound | undefined) =>
	createSelector(getTipsById, (tips) => {
		if (!selectedRound) {
			return [];
		}
		// eslint-disable-next-line sonarjs/no-identical-functions
		return selectedRound.matches.map((match) => {
			const tip = tips[match.id];
			const margin = tip ? tip.margin : 0;
			return {
				...match,
				...tip,
				margin,
			};
		}) as IMergedPick[];
	});

export const isForgotToTip = () =>
	createSelector(
		getTipsById,
		(state: IStore, selected_round?: IRound) => selected_round,
		(tips: IDictionary<IPick>, round?: IRound) => {
			if (!round) {
				return false;
			}

			const selected_tips = find(tips, (tip: IPick) => tip.squad !== 0);
			return round.status === "complete" && !selected_tips;
		}
	);

export const getPicksHelp = ({picks: {help}}: IStore) => help;
export const isShowErrorForEmpty = createSelector(
	getPicksState,
	(state) => state.showErrorForEmpty
);

export const getViewingUserTips = createSelector(getPicksState, (state) => state.viewUserPicks);
