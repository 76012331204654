import React, {Fragment} from "react";
import styled, {css} from "styled-components";
import {MatchStatus} from "modules/utils/enums";

const RadioStyled = styled.input`
	display: none;
`;

const getFlexDirection = ({reverse}: {reverse?: boolean}) => {
	const direction = reverse ? "row-reverse" : "row";
	return css`
		flex-direction: ${direction};
	`;
};

const RadioButtonStyled = styled.label`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex: 1 1 auto;
	cursor: pointer;
	color: #222222;
	font-family: "InterFace Corp", sans-serif;
	font-size: 12px;
	line-height: 14px;
	padding: 10px;
	${getFlexDirection};

	@media (min-width: 640px) {
		padding: 20px;
	}
`;

const backgroundColorGetter = (reverse: boolean | undefined) => {
	return reverse
		? "linear-gradient(270deg, rgba(184,184,184,0.15) 0%, rgba(255,255,255,1) 100%)"
		: "linear-gradient(90deg, rgba(184,184,184,0.15) 0%, rgba(255,255,255,1) 100%)";
};

const correctBackgroundGetter = (reverse: boolean | undefined) => {
	return reverse
		? "linear-gradient(270deg, rgba(70,180,19,0.15) 0%, rgba(255,255,255,1) 100%)"
		: "linear-gradient(90deg, rgba(70,180,19,0.15) 0%, rgba(255,255,255,1) 100%)";
};

const incorrectBackgroundGetter = (reverse: boolean | undefined) => {
	return reverse
		? "linear-gradient(270deg, rgba(246,58,37,0.15) 0%, rgba(255,255,255,1) 100%)"
		: "linear-gradient(90deg, rgba(246,58,37,0.15) 0%, rgba(255,255,255,1) 100%)";
};

const RadioButtonWrapper = styled.div<{
	reverse: boolean | undefined;
}>`
	position: relative;
	display: flex;
	width: 100%;
	height: 80px;
	&:hover,
	&:focus {
		&:not(.disabled) {
			background: ${({reverse}) => backgroundColorGetter(reverse)};
		}
	}

	&.checked {
		background: ${({reverse}) => backgroundColorGetter(reverse)};

		&.correct {
			background: ${({reverse}) => correctBackgroundGetter(reverse)};
		}

		&.incorrect {
			background: ${({reverse}) => incorrectBackgroundGetter(reverse)};
		}
	}

	&.disabled {
		label {
			cursor: default;
		}
	}
	@media screen and (max-width: 639px) {
		height: 60px;
	}
`;

interface IProps {
	readonly name: string;
	readonly disabled?: boolean;
	form_value: string | number;
	value: string | number;
	onChange: (e: React.SyntheticEvent<HTMLInputElement>) => void;
	reverse?: boolean;
	status: MatchStatus;
	pickState: string;
	dataAttr?: {[key: string]: string};
}
const compare = (value: string | number, to_compare: string | number) => value === to_compare;

// eslint-disable-next-line complexity
const getClassName = (
	isDisabled: boolean,
	isChecked: boolean,
	isReversed: boolean = false,
	status: MatchStatus,
	pickState: string
) => {
	let names = pickState;
	if (isDisabled) {
		names += " disabled";
	}

	if (isChecked) {
		names += " checked";
	}

	if (isReversed) {
		names += " reversed";
	}

	if (!isChecked && MatchStatus.Active) {
		names += " not-checked-active";
	}

	if (status === MatchStatus.Active) {
		names += " active";
	}

	if (status === MatchStatus.Complete && !isChecked) {
		names += " hidden";
	}

	return names;
};

export const RadioPick: React.FC<IProps> = ({
	name,
	disabled,
	form_value,
	onChange,
	value,
	children,
	reverse,
	status,
	pickState,
	dataAttr,
}) => (
	<RadioButtonWrapper
		tabIndex={0}
		{...dataAttr}
		reverse={reverse}
		className={getClassName(
			Boolean(disabled),
			compare(value, form_value),
			reverse,
			status,
			pickState
		)}>
		<Fragment>
			<RadioStyled
				type="radio"
				id={`${name}_${value}`}
				name={name}
				value={value}
				checked={compare(value, form_value)}
				onChange={onChange}
				disabled={disabled}
			/>
			<RadioButtonStyled reverse={reverse} htmlFor={`${name}_${value}`}>
				{children}
			</RadioButtonStyled>
		</Fragment>
	</RadioButtonWrapper>
);
