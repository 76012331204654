import styled from "styled-components";
import {theme} from "assets/themes/bbl";

export const FacebookButton = styled.button`
	border: 1px solid #4b6da7;
	border-radius: 4px;
	background-color: #3c5998;
	color: #fff;
	font-family: ${theme.font.montserrat}, sans-serif;
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 16px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	width: 220px;

	padding: 0 20px;
	width: 30%;
	margin-bottom: 10px;
	svg {
		margin-left: 10px;
	}
`;

export const TwitterButton = styled(FacebookButton)`
	border: 1px solid #7ebce2;
	background-color: #7ebce2;
`;

export const CopyLinkButton = styled(FacebookButton)`
	background: #4b4b4b;
`;
