import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Storage} from "modules/utils";
import {get} from "lodash";
import {useSelector, useDispatch} from "react-redux";
import {Exist} from "components";
import {getWBBLNotificationBar, getBBLNotificationBar} from "modules/selectors";
import {fetchNotificationBar} from "modules/actions";
import {getSportName} from "modules/utils/helpers";
import {theme} from "assets/themes/bbl";
import {IconCross} from "components/Icons/IconCross";

const Wrapper = styled.div`
	display: flex;
	width: 80%;
	position: fixed;
	top: 140px;
	left: 10%;
	border-radius: 5px;
	background: #fff;
	padding: 10px;
	z-index: 5;
	height: 100px;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
	@media screen and (max-width: 639px) {
		top: 65px;
	}
`;

const InnerWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: center;
	font-size: 20px;
	height: 100%;
	color: ${theme.colors.primary};
	font-family: ${theme.font.halisa};
	font-weight: 600;
	@media screen and (max-width: 639px) {
		font-size: 14px;
	}
`;

const CloseButton = styled.button`
	width: 25px;
	height: 25px;
	position: absolute;
	top: 5px;
	right: 5px;
	border: none;
	outline: none;
	background: none;
	padding: 0;
	svg {
		height: 25px;
		width: 25px;
		path {
			fill: black;
		}
	}
	@media screen and (max-width: 639px) {
		width: 20px;
		top: 0;
		right: 0;
		height: 20px;
		svg {
			height: 20px;
			width: 20px;
		}
	}
`;

export const NotificationBar: React.FC = () => {
	const dispatch = useDispatch();
	const [localShow, setLocalShow] = useState(true);
	const wbblNotification = useSelector(getWBBLNotificationBar);
	const bblNotification = useSelector(getBBLNotificationBar);
	const sport = getSportName();
	const notificationBar = sport === "bbl" ? bblNotification : wbblNotification;
	const notificationBarEnabled = get(notificationBar, "isEnabled", false) && localShow;
	const showBarStatus = Storage.GET(`${sport}_notification_bar`);
	const barInactive = showBarStatus === "false";

	const handleClose = () => {
		Storage.SET(`${sport}_notification_bar`, "false");
		setLocalShow(false);
	};
	useEffect(() => {
		dispatch(fetchNotificationBar());
	}, [dispatch]);

	if (barInactive) {
		return null;
	}
	return (
		<Exist when={notificationBarEnabled}>
			<Wrapper>
				<InnerWrapper>
					<CloseButton onClick={handleClose}>
						<IconCross />
					</CloseButton>
					{notificationBar?.message}
				</InnerWrapper>
			</Wrapper>
		</Exist>
	);
};
