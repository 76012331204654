import React, {useEffect, useState} from "react";
import {PrimaryButton, FormCell} from "components";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {TextField} from "@material-ui/core";
import {getLeague} from "modules/selectors";
import {postInvite} from "modules/actions";
import {IStore} from "modules/types";
import plus from "assets/img/icons/plus.svg";
import minus from "assets/img/icons/minus.svg";
import {theme} from "assets/themes/bbl";

const InputWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 20px;
`;
const Title = styled.h4`
	font-size: 20px;
	font-weight: bold;
	letter-spacing: -0.1px;
	line-height: 24px;
	color: #010101;
`;
const Inputs = styled.div`
	margin: 0;
	input {
		width: 240px;
	}
	@media screen and (max-width: 639px) {
		input {
			width: 100%;
		}
		.name-input {
			width: 120px;
		}
	}
`;
const ActionSecondaryButtonStyled = styled(PrimaryButton)`
	width: 200px;
	@media (max-width: 640px) {
		width: 100%;
		font-size: 14px;
	}
`;

const InviteButton = styled.button`
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: ${theme.colors.primary};
	flex-shrink: 0;
	border: 0;
	padding: 0;
	margin-left: 10px;
	img {
		height: 20px;
		width: 20px;
	}
`;

interface IProps {
	addLine: () => void;
	removeLine: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	last_index: number;
	index: number;
	fields_limit: number;
}
const Buttons: React.FC<IProps> = ({addLine, removeLine, last_index, index, fields_limit}) =>
	last_index === index && index < fields_limit ? (
		<InviteButton
			onClick={(e: React.SyntheticEvent<HTMLButtonElement>) => {
				e.preventDefault();
				addLine();
			}}>
			<img src={plus} alt={"Add"} />
		</InviteButton>
	) : (
		<InviteButton onClick={removeLine} data-index={index}>
			<img src={minus} alt={"Remove"} />
		</InviteButton>
	);

export const InvitesViaEmail = () => {
	const dispatch = useDispatch();
	const league = useSelector(getLeague);
	const [is_send_disabled, handleSendState] = useState(true);
	const invites_sent = useSelector((state: IStore) => state.leagues.invites_sent);
	const [data, updateData] = useState([{name: "", email: ""}]);
	useEffect(() => {
		const filtered = data.filter(({name, email}) => name !== "" && email !== "");

		handleSendState(filtered.length === 0);
	}, [data]);

	const addLine = () => updateData([...data, {name: "", email: ""}]);
	const removeLine = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		e.preventDefault();
		const index = parseInt(e.currentTarget.dataset.index || "0", 10);
		const new_data = [...data];
		new_data.splice(index, 1);
		return updateData(new_data);
	};
	const sendInvites = () => {
		dispatch(
			postInvite({
				league_id: league.id,
				invites: data,
			})
		);
	};
	const updateValues = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		e.preventDefault();
		const index = parseInt(e.currentTarget.dataset.index || "0", 10);
		const {value, name} = e.currentTarget;
		updateData(
			data.map((values, i) => {
				if (index === i) {
					return {
						...values,
						[name]: value,
					};
				}
				return values;
			})
		);
	};
	const last_index = data.length - 1;
	const fields_limit = 19;

	return (
		<React.Fragment>
			<Inputs>
				{data.map(({name, email}, index) => {
					return (
						<InputWrapper key={index}>
							<FormCell>
								<TextField
									label={"First Name"}
									variant={"outlined"}
									onChange={updateValues}
									data-index={index}
									value={name}
									className="name-input"
									placeholder={"First Name"}
									name={"name"}
								/>
							</FormCell>
							<FormCell>
								<TextField
									label={"Email"}
									variant={"outlined"}
									onChange={updateValues}
									data-index={index}
									value={email}
									placeholder={"Email Address"}
									name={"email"}
								/>
							</FormCell>

							<Buttons
								addLine={addLine}
								removeLine={removeLine}
								last_index={last_index}
								index={index}
								fields_limit={fields_limit}
							/>
						</InputWrapper>
					);
				})}
			</Inputs>
			<ActionSecondaryButtonStyled
				disabled={is_send_disabled}
				onClick={sendInvites}
				data-category="leagues"
				data-action="button click"
				data-label="send invites">
				Invite
			</ActionSecondaryButtonStyled>
			{invites_sent ? <Title>Invites sent</Title> : null}
		</React.Fragment>
	);
};
