const primary = "#010101";
const secondary = "#FFFFFF";
const tertiary = "#E6FF00";
const sunset =
	"linear-gradient(90deg,rgba(254, 87, 34, 1) 0%,rgba(228, 0, 43, 1) 25%,rgba(131, 33, 225, 1) 75%,rgba(28, 67, 255, 1) 100%);";
const white = "#FFFFFF";
const gold = "#D4AF37";
const greyLight = "#B8B8B8";
const greySecondary = "#4b4b4b";
const darkGrey = "#4b4b4b";
const red = "#F83F2D";

export const theme = {
	colors: {
		primary,
		secondary,
		tertiary,
		sunset,
		white,
		gold,
		greyLight,
		greySecondary,
		darkGrey,
		red,
	},
	font: {
		halisa: "Halisa",
		btBrik: "BTBrik",
		montserrat: "Montserrat",
		btBrikObl: "BTBrikOblique",
	},
	fontWeight: {
		regular: 400,
		semiBold: 600,
		bold: 700,
	},
	buttons: {
		primary: "#E6FF00",
		disabled: "#E3DADA",
	},
	form: {
		error: "red",
	},
	results: {
		correct: "#46B413",
		incorrect: "#F63A25",
	},
};
