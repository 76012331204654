import styled from "styled-components";
import {Field} from "formik";
import {theme} from "assets/themes/bbl";
import Down from "assets/img/icons/arrowDown.svg";

export const InputField = styled(Field)`
	border: 1px solid #2c2c2c;
	border-radius: 2px;
	height: 45px;
	width: 360px;
	padding: 13px;
	&[type="radio"] {
		width: 50px;
	}
	font-family: ${theme.font.montserrat};
	font-weight: 400;
	color: ${theme.colors.primary};

	&.select-field {
		appearance: none;
		background-image: url(${Down});
		background-repeat: no-repeat;
		background-position: right 10px center;
		background-color: white;
	}
	@media screen and (max-width: 639px) {
		width: 100%;
	}
`;
