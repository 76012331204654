import styled from "styled-components";

export const StatsTitle = styled.div`
	text-align: center;
	font-weight: 700;
	font-size: 14px;
	line-height: 20px;
	color: #333333;
	background: #f8f8f8;
	padding: 2px 0;
	border-bottom: 1px solid #f8f8f8;
`;
