import React, {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {get} from "lodash";
import {showShareModal, fetchSnapshot, showBonusPointModal} from "modules/actions";
import {
	getActiveOrCompleteRound,
	getSnapshot,
	getSelectedRoundID,
	getRoundByIdSelector,
} from "modules/selectors";
import styled from "styled-components";
import {useMediaQuery} from "modules/utils/hooks";
import {IconShare} from "components/Icons/IconShare";
import {IconBlackInfo} from "components/Icons/IconBlackInfo";
import {theme} from "assets/themes/bbl";

const Stats = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-family: "InterFace Corp", sans-serif;
	text-align: center;
	color: #fff;
	background-color: white;
	width: 100%;
	overflow-x: auto;
	border-radius: 4px;
	box-shadow: 0px 0px 20px -15px #333;
	padding: 35px;
	@media (max-width: 640px) {
		padding: 15px;
		width: 95%;
	}
`;

const StatBlockWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	position: relative;
`;

export const Value = styled.p`
	font-size: 16px;
	line-height: 1;
	font-weight: 700;
	white-space: nowrap;

	@media (min-width: 640px) {
		font-size: 18px;
	}
`;

const StatWrapper = styled.div<{
	selectedMobile: boolean | undefined;
}>`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	height: 50px;
	justify-content: space-between;
	margin-right: 20px;
	@media (max-width: 640px) {
		align-items: center;
		height: 35px;
		${({selectedMobile}) =>
			selectedMobile &&
			`
			height: 35px;
		`}
	}
`;

const StatLabel = styled.div`
	font-family: ${theme.font.montserrat};
	font-size: 12px;
	line-height: 14px;
	color: ${theme.colors.primary};
	font-weight: 400;
`;

const StatValue = styled.div`
	font-size: 20px;
	font-family: ${theme.font.montserrat};
	color: ${theme.colors.primary};
	font-weight: 600;
	@media (max-width: 640px) {
		font-size: 12px;
		font-weight: 700;
	}
`;

const StatMaxValue = styled.div`
	font-family: ${theme.font.montserrat};
	font-size: 10px;
	line-height: 14px;
	color: ${theme.colors.greySecondary};
	font-weight: 400;
	@media (max-width: 640px) {
		display: none;
	}
`;

const ShareButton = styled.button`
	height: 40px;
	width: 90px;
	background-color: transparent;
	color: ${theme.colors.primary};
	border-radius: 2px;
	border: none;
	font-family: ${theme.font.montserrat};
	font-size: 14px;
	font-weight: 600;
	outline: none;
	display: flex;
	align-items: center;
	justify-content: space-around;
	svg {
		height: 18px;
		width: 17px;
	}
	@media (max-width: 639px) {
		height: 35px;
	}
`;

const TooltipButton = styled.button`
	position: absolute;
	right: -20px;
	width: 30px;
	height: 30px;
	outline: none;
	border: none;
	background: none;
	padding: 0;
	svg {
		height: 30px;
		width: 30px;
	}
	@media (max-width: 639px) {
		right: -40px;
	}
`;

interface IStatBlock {
	label: string;
	value: number | string;
	maxValue: number | string;
	icon?: JSX.Element;
	selectedMobile?: boolean;
}

export const StatBlock: React.FC<IStatBlock> = ({label, value, maxValue, icon, selectedMobile}) => {
	return (
		<StatWrapper selectedMobile={selectedMobile}>
			<StatLabel>{label}</StatLabel>
			<StatValue>
				{value} {icon}
			</StatValue>
			<StatMaxValue>/{maxValue}</StatMaxValue>
		</StatWrapper>
	);
};

const getPreseasonValue = (isPreseason: boolean, value: number) => (isPreseason ? "0" : value);

export const Snapshot: React.FC = () => {
	const isMobile = useMediaQuery("(max-width: 639px)");
	const dispatch = useDispatch();
	const selectedRoundId = useSelector(getSelectedRoundID);
	const selectedRound = useSelector(getRoundByIdSelector)(selectedRoundId);
	const snapshotRound = useSelector(getActiveOrCompleteRound);
	const snapshot = useSelector(getSnapshot);
	const displayWeek: number = get(selectedRound, "number", 1);

	const selectedRoundLabel = `Week ${displayWeek}` + (!isMobile ? " Score" : "");

	useEffect(() => {
		dispatch(fetchSnapshot({round: selectedRoundId}));
	}, [dispatch, selectedRoundId]);

	if (!selectedRound || !snapshotRound) {
		return null;
	}

	const {
		round_points,
		overall_rank,
		overall_points,
		max_round_points,
		max_total_points,
		total_users,
	} = snapshot;
	const isPreseason = overall_rank === 0;

	const overallTitle = "Total " + (!isMobile ? "Score" : "");

	const rankTitle = "Overall " + (!isMobile ? "Rank" : "");

	const handleShareOpen = () => {
		dispatch(showShareModal({text: "Where would you like to share?"}));
	};

	const handleBonusPointModal = () => {
		dispatch(showBonusPointModal());
	};

	return (
		<Stats>
			<StatBlockWrapper>
				<StatBlock
					label={selectedRoundLabel}
					value={round_points}
					maxValue={max_round_points}
				/>
				<StatBlock
					label={overallTitle}
					value={getPreseasonValue(isPreseason, overall_points)}
					maxValue={max_total_points}
				/>
				<StatBlock label={rankTitle} value={overall_rank} maxValue={total_users} />
				<TooltipButton aria-label="Information Modal" onClick={handleBonusPointModal}>
					<IconBlackInfo />
				</TooltipButton>
			</StatBlockWrapper>
			<ShareButton aria-label="Share my Tips" onClick={handleShareOpen}>
				{!isMobile && "Share"}
				<IconShare />
			</ShareButton>
		</Stats>
	);
};
