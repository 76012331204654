import {Flex} from "components/Flex";
import styled from "styled-components";
import {theme} from "assets/themes/bbl";

interface ISwitchWrapProps {
	isDisabled?: boolean;
}
// import {theme} from "assets/theme";
export const SwitchWrap = styled(Flex)<ISwitchWrapProps>`
	& label.toggle {
		display: block;
		font-family: ${theme.font.montserrat};
		font-size: 14px;
		font-weight: ${theme.fontWeight.bold};
		letter-spacing: 0;
		line-height: 40px;
		text-align: center;
		border: 1px solid ${theme.colors.primary};
		height: 40px;
		width: 174px;
		border-radius: 20px;
		cursor: pointer;
		text-transform: uppercase;
	}
	& label.active {
		background-color: ${theme.colors.primary};
		color: ${theme.colors.white};
	}
	& label.inactive {
		background-color: ${theme.colors.white};
		color: ${theme.colors.primary};
	}
	opacity: ${({isDisabled}) => (isDisabled ? "0.4" : "1")};
`;
