import {IUser} from "modules/types";

export enum ConnextraType {
	HOME = "f2p_bbt_homepage",
	BBL_REGISTRATION_START = "f2p_bbt_bbl_regstart",
	BBL_REGISTRATION_CONFIRM = "f2p_bbt_bbl_regconfirm",
	WBBL_REGISTRATION_START = "f2p_bbt_wbbl_regstart",
	WBBL_REGISTRATION_CONFIRM = "f2p_bbt_wbbl_regconfirm",
	PLAY_BBL = "f2p_bbt_playbbl_buttonclick",
	PLAY_WBBL = "f2p_bbt_playwbbl_buttonclick",
}

const CONNEXTRA_URL = `https://us.connextra.com/dcs/tagController/tag/de53039c0043/`;

export const createConnextraScriptTag = (type: ConnextraType, user?: IUser) => {
	const elementName = "script";
	const SCRIPT_ID = "connextra-tagging";
	document.getElementById(SCRIPT_ID)?.remove();
	const src = `${CONNEXTRA_URL}${type}`;

	const url = new URL(src);

	if (user?.id) {
		url.searchParams.append("userID", `${user.id}`);
	}

	const script = document.createElement(elementName);
	script.id = SCRIPT_ID;
	script.src = url.toString();
	script.async = true;
	script.defer = true;

	const firstJS = document.getElementsByTagName(elementName)[0];
	firstJS.parentNode!.insertBefore(script, firstJS);

	return script;
};
