import React, {useCallback} from "react";
import {PrimaryButton, HOCModal} from "components";
import {useDispatch, useSelector} from "react-redux";
import {getErrorMessage} from "modules/selectors";
import {clearGlobalError} from "modules/actions";
import styled from "styled-components";
import {theme} from "assets/themes/bbl";
import {IconWarning} from "components/Icons/IconWarning";

const ErrorModalContainer = styled.div`
	padding: 20px;
	background: #fff;
	color: ${theme.colors.primary};
	text-align: center;
	svg {
		height: 40px;
		width: 40px;
		margin: 20px 0;
	}

	h2 {
		font-size: 20px;
		font-family: ${theme.font.montserrat}, sans-serif;
		font-weight: 600;
		letter-spacing: -0.1px;
		color: ${theme.colors.primary};
		margin-bottom: 5px;
	}

	p {
		font-family: ${theme.font.montserrat};
		font-size: 14px;
		line-height: 1.3;
		font-weight: 400;
		color: ${theme.colors.darkGrey};
		margin-bottom: 20px;
	}

	button {
		display: block;
		margin: 10px auto 0;
	}
`;

export const ErrorModal: React.FC = () => {
	const dispatch = useDispatch();
	const errors = useSelector(getErrorMessage);

	const onClose = useCallback(() => {
		dispatch(clearGlobalError());
	}, [dispatch]);

	if (!errors) {
		return null;
	}

	return (
		<HOCModal onClose={onClose} isVisible={Boolean(errors)}>
			<ErrorModalContainer>
				<IconWarning />
				<h2>Error</h2>
				<p>{errors}</p>
				<PrimaryButton onClick={onClose}>Understood</PrimaryButton>
			</ErrorModalContainer>
		</HOCModal>
	);
};
