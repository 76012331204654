import {createAction} from "redux-act";

export const showForgotPasswordModal = createAction();
export const hideForgotPasswordModal = createAction();
export const resetForgotPasswordModal = createAction();

export const showConfirmModal = createAction();
export const hideConfirmModal = createAction();

interface IShareModalParams {
	text?: string;
}

export const showShareModal = createAction<IShareModalParams>();
export const hideShareModal = createAction();

export const showLoginModal = createAction();
export const hideLoginModal = createAction();

export const showRegistrationModal = createAction();
export const hideRegistrationModal = createAction();

export const showJoinModal = createAction<{code: number | string}>();
export const hideJoinModal = createAction();

export const showBonusPointModal = createAction();
export const hideBonusPointModal = createAction();
