/* eslint-disable complexity */
import {SagaIterator} from "redux-saga";
import {call, put, take, select} from "typed-redux-saga/macro";
import * as actions from "modules/actions";
import {Api, ApiError} from "modules/utils";
import {IActionSearch, ILoadMore, ISagaAction} from "modules/types";
import {getLeaguesForJoin} from "modules/selectors";
import {assign, get, isEmpty, uniqBy} from "lodash";
import {captureException} from "@sentry/react";

const LIMIT = 10;
export const fetchLeaguesToJoin = function* (
	query: string | undefined,
	offset: number
): SagaIterator {
	const result = yield* call(Api.tipping_league.show_for_join, {
		page: offset ? offset / LIMIT : null,
		search: query,
	});

	ApiError.CHECK(result);

	return result.success;
};

export const getJoinListLeaguesSaga = function* (
	action: ISagaAction<IActionSearch & ILoadMore>
): SagaIterator {
	try {
		const ZERO_OFFSET = 0;
		const query = get(action, "payload.value");
		const onClickOffset = get(action, "payload.offset");
		const load_more_query = get(action, "query", "");
		const load_more_offset = Number(get(action, "offset"));
		const offset = load_more_offset
			? load_more_offset
			: onClickOffset
			? onClickOffset
			: ZERO_OFFSET;

		const result = yield* call(fetchLeaguesToJoin, query, offset);

		const {leagues, nextPage} = result;
		const join_store = yield* select(getLeaguesForJoin);

		const new_items = isEmpty(query) ? [...join_store.items, ...leagues] : [...leagues];
		const filterCheckedItems = uniqBy(new_items, "id");

		yield* put(
			actions.fetchLeaguesForJoinSuccess({
				items: filterCheckedItems || [],
				nextPage: nextPage,
			})
		);

		/**
		 * Load more actions
		 */
		if (nextPage) {
			const isLoadMore = query === load_more_query || !query;
			const new_offset = isLoadMore ? load_more_offset + LIMIT : ZERO_OFFSET;

			assign(action, {
				offset: new_offset || LIMIT,
				query,
			});

			yield* take(actions.fetchLeaguesForJoinMore);
			yield* call(getJoinListLeaguesSaga, action);
		}
	} catch (err) {
		yield* put(actions.fetchLeaguesForJoinFailed());
		yield* put(actions.globalError(err));
		captureException(err);
	}
};

export const apiPostJoinToLeague = function* (code: string): SagaIterator {
	const response = yield* call(Api.tipping_join.join, {
		code,
	});

	ApiError.CHECK(response);

	return response.success;
};

export const apiGetSearchForJoinByCode = function* (query: string): SagaIterator {
	const response = yield* call(Api.tipping_league.show_for_join, {
		search: query,
		page: null,
	});

	ApiError.CHECK(response);

	return response.success;
};

export const postJoinToLeagueSaga = function* (
	action: ISagaAction<{
		code: string;
	}>
): SagaIterator {
	const code: string = action.payload.code;

	try {
		const {league} = yield* call(apiPostJoinToLeague, code);
		yield* put(actions.joinToLeagueSuccess({code, id: league.id}));
	} catch (err) {
		yield* put(actions.joinToLeagueFailed(code));
		yield* put(actions.globalError(err));
	}
};

export const searchAndJoinSaga = function* (
	action: ISagaAction<{
		code: string;
	}>
): SagaIterator {
	try {
		const query = get(action, "payload.code");

		const result = yield* call(apiGetSearchForJoinByCode, query);

		yield* put(
			actions.fetchLeaguesForJoinSuccess({
				items: result.leagues,
				nextPage: false,
			})
		);

		yield* call(postJoinToLeagueSaga, action);
	} catch (err) {
		yield* put(actions.fetchLeaguesForJoinFailed());
		yield* put(actions.globalError(err));
	}
};
