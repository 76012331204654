import React from "react";
import styled from "styled-components";
import {map, first, last} from "lodash";
import {IProgressBarItem} from "modules/types";

export const ProgressBarWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: "InterFace Corp", sans-serif;
	color: #333333;
`;

const ProgressBarHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`;

const ProgressBarContent = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
`;

const ProgressBarValue = styled.div`
	font-size: 20px;
	line-height: 26px;
	font-weight: 800;
`;

const ProgressBarValueFirst = styled(ProgressBarValue)``;
const ProgressBarValueLast = styled(ProgressBarValue)``;

const ProgressBarTitle = styled.div`
	font-size: 14px;
	line-height: 20px;
	text-align: center;
`;

const ProgressBarPercentValue = styled.div`
	font-size: 14px;
	line-height: 20px;
	font-weight: 700;
`;

const ProgressBarPercentValueFirst = styled(ProgressBarPercentValue)`
	margin-right: 12px;
`;

const ProgressBarPercentValueLast = styled(ProgressBarPercentValue)`
	margin-left: 12px;
`;

const ProgressBarItems = styled.div`
	height: 8px;
	width: 100%;
	border-radius: 5px;
	overflow: hidden;
	display: flex;
`;

const ProgressBarItem = styled.div<IProgressBarItem>`
	height: 8px;
	width: ${(props) => props.percent}%;
	background-color: ${(props) => props.color};
	flex-grow: 1;
`;

interface IProgressBar {
	items: IProgressBarItem[];
	show_percents?: boolean;
	title?: string;
}

export const ProgressBar: React.FC<IProgressBar> = ({items, show_percents = false, title}) => {
	const first_item = first(items);
	const last_item = last(items);

	const show_first_item_percents_value = show_percents && first_item;
	const show_last_item_percents_value = show_percents && last_item;

	return (
		<ProgressBarWrapper>
			<ProgressBarHeader>
				<ProgressBarValueFirst>{first_item?.value}</ProgressBarValueFirst>
				{title && <ProgressBarTitle>{title}</ProgressBarTitle>}
				<ProgressBarValueLast>{last_item?.value}</ProgressBarValueLast>
			</ProgressBarHeader>
			<ProgressBarContent>
				{show_first_item_percents_value && (
					<ProgressBarPercentValueFirst>
						{first_item?.percent}%
					</ProgressBarPercentValueFirst>
				)}
				<ProgressBarItems>
					{map(items, (item, index) => (
						<ProgressBarItem key={index} percent={item.percent} color={item.color} />
					))}
				</ProgressBarItems>
				{show_last_item_percents_value && (
					<ProgressBarPercentValueLast>{last_item?.percent}%</ProgressBarPercentValueLast>
				)}
			</ProgressBarContent>
		</ProgressBarWrapper>
	);
};
