import React, {useCallback, useState, useRef, useEffect} from "react";
import styled from "styled-components";
import {get} from "lodash";
import {IconArrowRight} from "components/Icons/IconArrowRight";
// import {Exist} from "components";
import {useDispatch, useSelector} from "react-redux";
import {changeSelectedRound, setShowByOverall, clearShowByOverall} from "modules/actions";
import {getSportName, CURR_SPORT_GOLDEN_WEEK} from "modules/utils/helpers";
import {
	getIsShowByOverall,
	getSelectedRoundID,
	getRoundByIdSelector,
	getRounds,
	getStartEndCompDate,
	getLeague,
} from "modules/selectors";
import {IRound} from "modules/types";
import {DateTime} from "luxon";

const RoundsSelectorWrapper = styled.div`
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	position: relative;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;

	min-width: 240px;
	.rotate {
		transform: rotate(180deg);
	}
	&:focus {
		border: 1px solid black;
	}
	@media (max-width: 640px) {
		min-width: unset;
		width: 100%;
		svg {
			float: right;
			width: 90px;
		}
		.bkt-sponsor {
			margin-top: -15px;
		}
		max-height: 40px;
	}
`;

const RoundsSelectorButton = styled.button`
	width: 40px;
	height: 40px;
	background: white;
	outline: none;
	border: 1px solid #eaeaea;
	border-radius: 2px;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	&:disabled {
		background: transparent;
		cursor: no-drop;
		polygon {
			fill: #b8b8b8;
		}
	}

	polygon {
		fill: #000000;
	}
`;

const Value = styled.button`
	font-size: 14px;
	line-height: 14px;
	cursor: pointer;
	font-weight: 600;
	position: relative;
	white-space: nowrap;
	font-family: "Montserrat", sans-serif;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 35px;
	justify-content: center;
	border: none;
	outline: none;
	background: transparent;
	color: white;
	&:hover {
		.round-options {
			display: flex;
			z-index: 1;
		}
	}
	span {
		font-size: 10px;
		color: #b8b8b8;
		margin-top: 4px;
		text-align: center;
	}

	@media (min-width: 640px) {
		font-size: 18px;
	}
	@media (max-width: 640px) {
		font-size: 12px;
	}
`;

const RoundOptionsWrapper = styled.div<{
	isShow: boolean;
	isBBL: boolean;
}>`
	display: none;
	position: absolute;
	top: 41px;
	left: -70px;
	box-shadow: 0px 0px 20px 0 rgba(1, 1, 1, 0.1);
	padding: 10px 15px;
	flex-wrap: wrap;
	flex-direction: row;
	min-width: 260px;
	align-items: center;
	z-index: 10;
	background: white;
	width: 320px;
	&:hover {
		display: flex;
	}
	&:before {
		content: "";
		width: 0;
		position: absolute;
		height: 0;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		left: calc(50% - 2.5px);
		top: -5px;
		border-bottom: 5px solid white;
	}
	@media screen and (max-width: 639px) {
		width: 320px;
		height: 145px;
	}
	${({isShow}) =>
		isShow &&
		`
		display: flex;
	`}
	${({isBBL}) =>
		isBBL &&
		`
		min-width: 300px;
	`}
`;

const RoundButton = styled.button<{
	isActive: boolean;
	isGoldenWeek: boolean;
}>`
	width: 48px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	border: 1px solid black;
	color: black;
	font-size: 14px;
	font-family: "Halisa";
	font-weight: 700;
	background: white;
	margin: 4px;
	${({isGoldenWeek}) =>
		isGoldenWeek &&
		`
		border: 1px solid #D4AF37;

	`}
	${({isActive}) =>
		isActive &&
		`
		background: black;
		color: #e6ff00;
	`}
	${({isGoldenWeek, isActive}) =>
		isGoldenWeek &&
		isActive &&
		`
		background: #D4AF37;
		border: 1px solid #D4AF37;
		color: black;
	`}
	@media screen and (max-width: 639px) {
		width: 48px;
		height: 48px;
	}
`;

interface IRoundWeek {
	startDate: Date;
	endDate: Date;
}

// const getWeekFormatSpan = (round: IRoundWeek) => {
// 	const startDate = get(round, "startDate");
// 	const endDate = get(round, "endDate");
// 	const roundStart = DateTime.fromISO(String(startDate));
// 	const roundEnd = DateTime.fromISO(String(endDate));

// 	const yearStart = roundStart.toFormat("YYYY");

// 	const monthStart = roundStart.toFormat("MMMM");
// 	const monthStartShort = roundStart.toFormat("MMM");
// 	const dayStart = roundStart.toFormat("D");
// 	const monthEnd = roundEnd.toFormat("MMMM");
// 	const monthEndShort = roundEnd.toFormat("MMM");
// 	const dayEnd = roundEnd.toFormat("D");

// 	if (monthStart === monthEnd) {
// 		return `${dayStart} - ${dayEnd} ${monthStart} ${yearStart}`;
// 	}
// 	return `${dayStart} ${monthStartShort} - ${dayEnd} ${monthEndShort}`;
// };
const getWeekFormatSpan = (round: IRoundWeek) => {
	const startDate = get(round, "startDate");
	const endDate = get(round, "endDate");
	const roundStart = DateTime.fromISO(String(startDate), {zone: "utc"});
	const roundEnd = DateTime.fromISO(String(endDate), {zone: "utc"});
	return `${roundStart.toFormat("ccc d LLL")} – ${roundEnd.toFormat("ccc d LLL")}`;
};

const filterOneMatchCompleteRounds = (rounds: IRound[]) => {
	return rounds.filter((round) => {
		const {matches} = round;
		// if (round.number === 1) {
		// 	return true;
		// }
		return matches.filter((match) => match.status === "complete").length > 0;
	});
};

const getStardedRounds = (rounds: IRound[], leagueStartRound: number, lastRoundHandled: number) => {
	if (leagueStartRound > lastRoundHandled) return [];
	return rounds.filter((round) => round.id >= leagueStartRound && round.id <= lastRoundHandled);
};

export const RankingsRoundSelector: React.FC = () => {
	const ref = useRef<HTMLDivElement>(null);
	const dispatch = useDispatch();
	const league = useSelector(getLeague);
	const leagueStartRound = league.startRound;
	const [localRound, setLocalRound] = useState(0);
	const [showRounds, setShowRounds] = useState(false);
	const selectedRoundId = useSelector(getSelectedRoundID);
	const compStartEnd = useSelector(getStartEndCompDate);
	const sport = getSportName();
	const isBBL = sport === "bbl";
	const isShowByOvr = useSelector(getIsShowByOverall);

	const roundById = useSelector(getRoundByIdSelector)(selectedRoundId);
	const roundsArr = useSelector(getRounds);

	const displayWeek = get(roundById, "number", 1);

	const roundsHandled = filterOneMatchCompleteRounds(roundsArr);

	const roundsLength = roundsHandled.length;

	// const firstAllowedRound = get(roundsHandled, "[0].id");
	const maxIndex = roundsLength - 1;
	// const maxAllowedRound = get(roundsHandled, `[${maxIndex}]?.id`);
	const maxAllowedRound = roundsHandled[maxIndex]?.id;
	const lastRoundHandled: number = maxAllowedRound;
	const StartedRounds = getStardedRounds(roundsHandled, leagueStartRound, lastRoundHandled);

	useEffect(() => {
		if (localRound === 0 && !isShowByOvr) {
			dispatch(setShowByOverall());
		}
		if (localRound !== 0) {
			const roundToChange =
				selectedRoundId <= lastRoundHandled ? selectedRoundId : lastRoundHandled;
			dispatch(changeSelectedRound({round: roundToChange}));
		}

		const checkIfClickedOutside = (e: Event) => {
			// If the menu is open and the clicked target is not within the menu,
			// then close the menu
			if (showRounds && ref.current && !ref.current.contains(e.target as Node)) {
				setShowRounds(false);
			}
		};
		document.addEventListener("mousedown", checkIfClickedOutside);
		return () => {
			// Cleanup the event listener
			document.removeEventListener("mousedown", checkIfClickedOutside);
		};
	}, [
		showRounds,
		dispatch,
		roundsHandled,
		roundsLength,
		selectedRoundId,
		maxAllowedRound,
		localRound,
		isShowByOvr,
		lastRoundHandled,
	]);

	const changeRound = useCallback(
		(e: React.SyntheticEvent<HTMLButtonElement>) => {
			const roundId = Number(e.currentTarget.value);
			if (roundId === 0) {
				dispatch(setShowByOverall());
				setLocalRound(0);
				return;
			}
			dispatch(changeSelectedRound({round: roundId}));
			setShowRounds(false);
			dispatch(clearShowByOverall());
			setLocalRound(roundId);
		},
		[dispatch]
	);

	const handleShowRounds = () => {
		setShowRounds(!showRounds);
	};

	if (!roundById) {
		return null;
	}

	const roundFormat = isShowByOvr
		? getWeekFormatSpan(compStartEnd)
		: getWeekFormatSpan(roundById);

	const prevId = isShowByOvr ? lastRoundHandled : selectedRoundId - 1;
	const nextId = selectedRoundId === lastRoundHandled ? 0 : selectedRoundId + 1;
	const prevDisabled = prevId === StartedRounds[0]?.id - 1;
	const nextDisabled = nextId === lastRoundHandled + 1 || isShowByOvr;

	return (
		<React.Fragment>
			<RoundsSelectorWrapper ref={ref}>
				<RoundsSelectorButton
					onClick={changeRound}
					value={prevId}
					disabled={prevDisabled}
					className={"rotate"}>
					<IconArrowRight color={"#fff"} />
				</RoundsSelectorButton>
				<Value onClick={handleShowRounds} className="round-value">
					{isShowByOvr ? "Overall" : `Week ${displayWeek}`}
					<span>{roundFormat}</span>
					<RoundOptionsWrapper
						className="round-options"
						isShow={showRounds}
						isBBL={isBBL}>
						{StartedRounds.map((round, index) => {
							const isActive = selectedRoundId === round.id && !isShowByOvr;
							const isGoldenWeek = round.number === Number(CURR_SPORT_GOLDEN_WEEK);

							return (
								<RoundButton
									key={`${index}-round`}
									value={round.id}
									onClick={changeRound}
									isGoldenWeek={isGoldenWeek}
									isActive={isActive}>
									{round.number}
								</RoundButton>
							);
						})}
						<RoundButton
							key={`${0}-round`}
							value={0}
							onClick={changeRound}
							isGoldenWeek={false}
							isActive={isShowByOvr}>
							OVR
						</RoundButton>
					</RoundOptionsWrapper>
				</Value>

				<RoundsSelectorButton onClick={changeRound} value={nextId} disabled={nextDisabled}>
					<IconArrowRight color={"#fff"} />
				</RoundsSelectorButton>
			</RoundsSelectorWrapper>
		</React.Fragment>
	);
};
