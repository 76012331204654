import {createSelector} from "reselect";
import {IStore, IError} from "modules/types";
import {ROLE_USER_BBL, ROLE_USER_WBBL} from "modules/utils";

export const getUserState = ({user}: IStore) => user;
export const getUser = createSelector(getUserState, (user) => user.user);

export const getUserLoginStatus = createSelector(getUserState, (user) => user.userLoginStatus);
export const isLoggedIn = createSelector(getUserState, (user) => user.isAuthorized);
export const isBBLUser = createSelector(getUserState, (user) =>
	user.user.roles.includes(ROLE_USER_BBL)
);
export const isWBBLUser = createSelector(getUserState, (user) =>
	user.user.roles.includes(ROLE_USER_WBBL)
);
export const getPreregisterMessage = createSelector(
	getUserState,
	(user) => user.preregister.message
);

export const isSessionChecked = createSelector(getUserState, (user) => user.isSessionChecked);

export const getUserError = createSelector(getUserState, (user) => user.error);
export const getUserCodeError = createSelector(getUserState, (user) => user.code_error);
export const getUserRawError = createSelector(getUserState, (user): IError | undefined => {
	if (!user.error) {
		return;
	}

	return {
		code: user.code_error,
		message: user.error,
	};
});

export const getShowGlobalUserError = createSelector(getUserRawError, (error) => {
	if (!error) {
		return false;
	}

	return /*!error.param &&*/ Boolean(error.message) && error.code !== 409;
});

export const getResetPasswordValue = createSelector(getUserState, (user) => user.resetPassword);

export const isResetPasswordSuccess = createSelector(getResetPasswordValue, ({success}) =>
	Boolean(success)
);

export const getResetPasswordError = createSelector(getResetPasswordValue, ({error}) => error);

export const getUserAlert = createSelector(getUserState, (user) => user.alert);

export const getPageHeader = createSelector(getUserState, (user) => user.pageHeader);

export const isSecretEnabled = createSelector(getUserState, (user) => user.isSecretEnabled);

export const getUserCountry = (state: IStore) => state.user.state;
export const getUserUpdateStatus = createSelector(getUserState, (user) => user.userUpdateStatus);
