import {createReducer} from "redux-act";
import {showGlobalError, clearGlobalError} from "modules/actions";
import {IError} from "modules/types";
import {get} from "lodash";

interface IErrorReducer {
	message?: string;
}

const getMessage = (payload: IError | unknown) => get(payload, "message", "");

export const error = createReducer<IErrorReducer>({}, {})
	.on(showGlobalError, (state: IErrorReducer, payload: IError | unknown) => ({
		message: getMessage(payload),
	}))
	.on(clearGlobalError, () => ({}));
