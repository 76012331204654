import {createAction} from "redux-act";
import {
	IActionSearch,
	IActionSuccessLadder,
	IFetchLeagueResponse,
	IJoinedUser,
	IJoinToLeagueSuccess,
	ILeague,
	ILeagueActionGetLadder,
	ILeaguesReducer,
	INextAction,
} from "modules/types";

export interface ICreateLeaguePayload {
	name: string;
	privacy: string;
	startRound: number;
}

export interface IInvitePayload {
	email: string;
	name: string;
}

export interface IInviteEmails {
	league_id: number;
	invites: IInvitePayload[];
}

export interface IUpdateLeaguePayload {
	id: number;
	name?: string;
	privacy?: string;
	start_round?: number;
	description?: string;
}

export interface ILeagueEntryActionsPayload {
	league_id: number;
}

export const postCreateLeague = createAction<ICreateLeaguePayload>();
export const postCreateLeagueSuccess = createAction<ILeague>();
export const postCreateLeagueFailed = createAction<ILeaguesReducer["errors"]>();

export const fetchLeague = createAction<{id: number}>();
export const fetchLeagueSuccess = createAction<IFetchLeagueResponse>();
export const fetchLeagueFailed = createAction<ILeaguesReducer["errors"]>();

export const postInvite = createAction<IInviteEmails>();
export const postInviteSuccess = createAction();
export const postInviteFailed = createAction<ILeaguesReducer["errors"]>();
export const postInviteReset = createAction();

export const postUpdateLeague = createAction<IUpdateLeaguePayload>();
export const postUpdateLeagueSuccess = createAction<ILeague>();
export const postUpdateLeagueFailed = createAction<ILeaguesReducer["errors"]>();
export const postUpdateLeagueReset = createAction();

export interface IRemoveLeaguePayload {
	leagueID: number;
}

export const postLeaveLeague = createAction<ILeagueEntryActionsPayload>();
export const postLeaveLeagueSuccess = createAction<IRemoveLeaguePayload>();
export const postLeaveLeagueFailed = createAction<ILeaguesReducer["errors"]>();

export const clearIsUserLeftLeague = createAction();

export interface IDeleteLeaguePayload {
	id: number;
}

export const postDeleteLeague = createAction<IDeleteLeaguePayload>();
export const postDeleteLeagueSuccess = createAction<IRemoveLeaguePayload>();
export const postDeleteLeagueFailed = createAction<ILeaguesReducer["errors"]>();

export const fetchShowMyLeagues = createAction();
export const fetchShowMyLeaguesSuccess = createAction<ILeague[]>();
export const fetchShowMyLeaguesFailed = createAction<ILeaguesReducer["errors"]>();

export const fetchLeaguesForJoin = createAction<IActionSearch>();
export const fetchLeaguesForJoinSuccess = createAction<INextAction<ILeague>>();
export const fetchLeaguesForJoinFailed = createAction();
export const fetchLeaguesForJoinMore = createAction();
export const clearLeaguesForJoin = createAction();

export const setLeagueStatusIdle = createAction();

export const joinToLeague = createAction<{
	code: string;
}>();
export const searchAndJoin = createAction<{
	code: string;
}>();
export const joinToLeagueSuccess = createAction<IJoinToLeagueSuccess>();
export const joinToLeagueFailed = createAction<string>();

export const clearLeague = createAction();

export interface IJoinedUsersPayload {
	league_id: number | string;
	q?: string;
	page: number;
	limit?: number;
}

export interface IJoinedUsersSuccess {
	users: IJoinedUser[];
	items: IJoinedUser[];
	nextPage: boolean;
}

export const fetchJoinedUsers = createAction<IJoinedUsersPayload>();
export const fetchJoinedUsersSuccess = createAction<IJoinedUsersSuccess>();
export const fetchJoinedUsersMoreSuccess = createAction<IJoinedUsersSuccess>();

export interface IDeleteUserPayload {
	league_id: number;
	user_id: number;
}

export const postDeleteJoinedUser = createAction<IDeleteUserPayload>();
export const postDeleteJoinedUserSuccess = createAction<IDeleteUserPayload>();

export interface IDeleteUsersPayload {
	league_id: number;
	users: number[];
}
export interface IFetchLadderPayload {
	id: number;
	round?: number;
	offset: number;
}

export const postDeleteJoinedUsers = createAction<IDeleteUsersPayload>();
export const postDeleteJoinedUsersSuccess = createAction<IDeleteUsersPayload>();

export const getLeagueLadder = createAction<ILeagueActionGetLadder>();
export const getLeagueLadderData = createAction<IFetchLadderPayload>();
export const getLeagueLadderDataSuccess = createAction<IActionSuccessLadder>();
export const getLeagueLadderDataFailed = createAction();
export const getLeagueLadderDataMore = createAction();
export const setLeagueLadderQuery = createAction<string>();

export const clearLeagueLadder = createAction();

interface IEditUser {
	user_id: number;
	status: string;
	paid: number;
}

export interface IEditUserPayload {
	league_id: number;
	users: IEditUser[];
}
export const postEditUser = createAction<IEditUserPayload>();

export interface ISendEmailsPayload {
	id: number;
	subject: string;
	text: string;
	all: 1;
}

export const postSendEmails = createAction<ISendEmailsPayload>();
export const postSendEmailsSuccess = createAction();

export const toggleBlurPage = createAction();
export const setBlurPage = createAction<boolean>();
