import {createReducer} from "redux-act";
import * as actions from "modules/actions";
import {IHelpState} from "modules/types";

const defaultHelpItem = {
	title: "",
	text: "",
};

const initialState: IHelpState = {
	guidelines: {...defaultHelpItem},
	game_rules: {...defaultHelpItem},
	prizes: {...defaultHelpItem},
	terms: {...defaultHelpItem},
	privacy_policy: {...defaultHelpItem},
	faq: [],
};

export const help = createReducer<IHelpState>({}, initialState).on(
	actions.setHelpContent,
	(state, payload: IHelpState) => {
		return {
			...state,
			...payload,
		};
	}
);
