import styled from "styled-components";

export const SecondaryButton = styled.button`
	cursor: pointer;
	border-radius: 4px;
	background-color: #fff;
	border: 1px solid #07a827;
	color: #07a827;
	font-family: "InterFace Corp";
	font-size: 18px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 20px;
	text-align: center;
	height: 50px;
	transition: all 0.2s ease-in-out;
	&:hover {
		background: #07a827;
		color: #fff;
	}
	&:disabled {
		cursor: default;
		background: #b3b3b3;
		color: #939393;
	}
`;
