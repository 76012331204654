import styled from "styled-components";

export const FormErrorMessage = styled.p`
	font-family: Roboto, Arial, sans-serif;
	color: #dc1515;
	font-size: 12px;
	line-height: 16px;
	margin-bottom: 5px;

	button {
		background: transparent;
		font-weight: bold;
		color: #dc1515;
		font-size: 12px;
		border: none;
		text-decoration: underline;
		cursor: pointer;
		font-family: "InterFace Corp", sans-serif;
		padding: 0;
	}

	a {
		text-decoration: underline;
	}
`;
