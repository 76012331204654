import {IUser} from "modules/types";
import {Storage} from "modules/utils";

export abstract class User {
	public static SAVE(user: IUser): void {
		Storage.SET("user", user);
		Storage.SET("is_authenticated", "true");
	}

	public static CLEAR(): void {
		// TODO: fix issue
		// eslint-disable-next-line @typescript-eslint/unbound-method
		["user", "is_authenticated", "sid"].forEach(Storage.REMOVE);
	}
}
